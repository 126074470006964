var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { isEmpty, useDuration, useIntl } from "@boomq/utils";
import { GridItem, GridRowWithLink } from "@boomq/uikit";
import { NO_DATA_MESSAGE, TEST_PROJECTS_HEADER_DATE, TEST_PROJECTS_HEADER_DURATION, TEST_PROJECTS_HEADER_NAME, TEST_PROJECTS_HEADER_REQUEST_COUNT, TEST_PROJECTS_HEADER_TYPE } from "./intl";
import { tableRowColTheme } from "./styled";
import { ActionButtons } from "./ActionButtons";
import { StyledLabels } from "../AllTestProjectSettingsSetsItem/styled";
import { sizes } from "../../styled/response";
import { ActionList, TableRowCol, TableRowColText } from "../../styled/Table";
import { convertSecToMs, getDateOnly } from "../../../helpers/date";
import { TestProjectsItemAction } from "../../../helpers/types";
import { TABLE_HEADER_LABEL } from "../../../data/content/testProjectSettingsSets";
export const AllTestProjectsItem = (_a) => {
    var { emptyLabelsText, labels, data: { id, totalDuration, lastModified, projectName, requestCount, searchedLabels, testType }, windowWidth } = _a, restProps = __rest(_a, ["emptyLabelsText", "labels", "data", "windowWidth"]);
    const duration = !isEmpty(totalDuration) ? convertSecToMs(totalDuration) : 0;
    const durationText = useDuration(duration);
    const { formatMessage } = useIntl();
    return (React.createElement(GridItem, null,
        React.createElement(GridRowWithLink, { to: { pathname: `/account/tests/${id}`, state: { step: 1 } }, windowWidth: windowWidth },
            React.createElement(TableRowCol, { long: true, theme: tableRowColTheme },
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, { blue: true }, formatMessage(TEST_PROJECTS_HEADER_NAME))),
                React.createElement(TableRowColText, { blue: true }, projectName)),
            React.createElement(TableRowCol, null,
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, null, formatMessage(TABLE_HEADER_LABEL))),
                React.createElement(StyledLabels, { labels: labels, addLabelMethod: restProps[TestProjectsItemAction.AddLabel], addLabelWithCreatingMethod: restProps[TestProjectsItemAction.AddLabelWithCreating], emptyLabelsText: emptyLabelsText, minLength: 3, onDelete: restProps[TestProjectsItemAction.DeleteLabel], searchLabelsMethod: restProps[TestProjectsItemAction.SearchLabels], searchedItems: searchedLabels })),
            React.createElement(TableRowCol, null,
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, { blue: true }, formatMessage(TEST_PROJECTS_HEADER_DATE))),
                React.createElement(TableRowColText, null, lastModified
                    ?
                        getDateOnly(lastModified)
                    : formatMessage(NO_DATA_MESSAGE))),
            React.createElement(TableRowCol, null,
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, { blue: true }, formatMessage(TEST_PROJECTS_HEADER_TYPE))),
                React.createElement(TableRowColText, null, testType
                    ? formatMessage({
                        defaultMessage: testType,
                        id: `account.all.test.project.test.type.${testType.toUpperCase()}`
                    })
                    : formatMessage(NO_DATA_MESSAGE))),
            React.createElement(TableRowCol, null,
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, { blue: true }, formatMessage(TEST_PROJECTS_HEADER_REQUEST_COUNT))),
                React.createElement(TableRowColText, null, requestCount || formatMessage(NO_DATA_MESSAGE))),
            React.createElement(TableRowCol, null,
                windowWidth <= sizes.tablet && (React.createElement(TableRowColText, { blue: true }, formatMessage(TEST_PROJECTS_HEADER_DURATION))),
                React.createElement(TableRowColText, null, duration ? durationText : formatMessage(NO_DATA_MESSAGE))),
            React.createElement(ActionList, null,
                React.createElement(ActionButtons, Object.assign({ projectId: id }, restProps))))));
};
