import React, { FC } from "react";
import { useIntl } from "@boomq/utils";
import { getHostUrl, getTeamMemberPermissionMessageByValue } from "@boomq/common";
import {
    COPY_INVITE_LINK_TOOLTIP,
    DELETE_TEAM_MEMBER_TOOLTIP,
    EDIT_TEAM_MEMBER_TOOLTIP,
    SEND_INVITE_LINK_TOOLTIP
} from "./intl";
import {
    TeamMembersItemTemplateView,
    TeamMembersItemTemplateViewProps,
    TeamMembersItemTemplateViewHandlers
} from "../../../../components/account/Team/TeamMembersItemTemplateView";

const WrappedTeamMembersItemTemplateView: FC<TeamMembersItemTemplateViewProps & TeamMembersItemTemplateViewHandlers> = (
    props: TeamMembersItemTemplateViewProps & TeamMembersItemTemplateViewHandlers
): JSX.Element => {
    const { formatMessage } = useIntl();

    const inviteUrl = props.inviteUrl ? `${getHostUrl()}${props.inviteUrl}` : props.inviteUrl;

    const permissionsStr = props.permissionList
        .map((permission) => formatMessage(getTeamMemberPermissionMessageByValue(permission)))
        .join(", ");

    const copyInviteLinkTooltip = formatMessage(COPY_INVITE_LINK_TOOLTIP);
    const deleteTeamMemberTooltip = formatMessage(DELETE_TEAM_MEMBER_TOOLTIP);
    const editTeamMemberTooltip = formatMessage(EDIT_TEAM_MEMBER_TOOLTIP);
    const sendInviteLinkTooltip = formatMessage(SEND_INVITE_LINK_TOOLTIP);

    return (
        <TeamMembersItemTemplateView
            {...props}
            copyInviteLinkTooltip={copyInviteLinkTooltip}
            deleteTeamMemberTooltip={deleteTeamMemberTooltip}
            editTeamMemberTooltip={editTeamMemberTooltip}
            sendInviteLinkTooltip={sendInviteLinkTooltip}
            inviteUrl={inviteUrl}
            permissionsStr={permissionsStr}
        />
    );
};

export default WrappedTeamMembersItemTemplateView;
