var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormattedMessage } from "react-intl";
import { ItemList, ItemListItem, ItemListTitle } from "./styled";
import { MenuListItem } from "../MenuListItem";
const renderTitle = (title) => title && title.id ? (React.createElement(ItemListTitle, null,
    React.createElement(FormattedMessage, Object.assign({}, title)))) : (React.createElement(React.Fragment, null));
export const MenuList = (_a) => {
    var { id, items = [], title } = _a, restProps = __rest(_a, ["id", "items", "title"]);
    return (React.createElement(ItemList, Object.assign({}, restProps),
        renderTitle(title),
        items.map((_a) => {
            var { id } = _a, restParams = __rest(_a, ["id"]);
            return (React.createElement(ItemListItem, { key: id },
                React.createElement(MenuListItem, Object.assign({}, restParams))));
        })));
};
