import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import PFLBTableContainer from "./PFLBTable";
import { dictionaryRu, dictionaryEn } from "../../../../data/dictionaries";
import { getCurrentLanguage } from "../../../../redux/reducers/languagePlate";
const dictionaries = { ru: dictionaryRu, en: dictionaryEn };
const connector = connect((state) => ({
    currentLanguage: getCurrentLanguage(state)
}));
export const PFLBTableWithIntl = connector(({ currentLanguage, id, isShowParamsSidebar, onRefresh, onToggleTableParamsSidebar }) => (React.createElement(IntlProvider, { locale: currentLanguage, messages: dictionaries[currentLanguage] },
    React.createElement(PFLBTableContainer, { id: id, isShowParamsSidebar: isShowParamsSidebar, onRefresh: onRefresh, onToggleTableParamsSidebar: onToggleTableParamsSidebar }))));
