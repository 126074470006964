import equals from "validator/es/lib/equals";
import isEmail from "validator/es/lib/isEmail";
import isLength from "validator/es/lib/isLength";
import { isEmpty, passwordValidator } from "@boomq/utils";
import { EMPTY_NAME, EMPTY_TEXT } from "./intl";
import { PASS_MAX_LENGTH, PASS_MIN_DIGIT_COUNT, PASS_MIN_LENGTH, PASS_MIN_LOWERCASE_COUNT, PASS_MIN_SYMBOL_COUNT, PASS_MIN_UPPERCASE_COUNT, getPassRulesErrorsMapByParam } from "../../data/content/errorMessages";
export const loginValidator = ({ login, password }) => {
    const errors = {};
    !isEmail(login) && (errors.login = { id: "validation.login.invalid", defaultMessage: "Wrong login" });
    isEmpty(login) && (errors.login = { id: "validation.login.require", defaultMessage: "Required field" });
    !isLength(password, { min: PASS_MIN_LENGTH, max: PASS_MAX_LENGTH }) &&
        (errors.password = { id: "validation.password.invalid", defaultMessage: "Wrong password" });
    isEmpty(password) && (errors.password = { id: "validation.password.require", defaultMessage: "Required field" });
    return { isValid: isEmpty(errors), errors };
};
export const emailValidator = (email) => {
    const emailError = !isEmail(email)
        ? { id: "validation.email.invalid", defaultMessage: "Not valid Email field" }
        : undefined;
    const error = isEmpty(email) ? { id: "validation.email.require", defaultMessage: "Required field" } : emailError;
    return { isValid: isEmpty(error), error };
};
export const nameValidator = (name) => isEmpty(name) ? { isValid: false, error: EMPTY_NAME } : { isValid: true, error: EMPTY_TEXT };
export const registrationLoginValidator = (login) => {
    const { isValid, error } = emailValidator(login);
    const errors = isValid ? {} : { login: error };
    return { isValid, errors };
};
const passwordValidatorWithErrorIntlMessage = (password) => {
    const { isValid, length, lowercaseCount, digitCount, symbolCount, uppercaseCount } = passwordValidator(password, {
        maxLength: PASS_MAX_LENGTH,
        minLength: PASS_MIN_LENGTH,
        minLowercase: PASS_MIN_LOWERCASE_COUNT,
        minDigits: PASS_MIN_DIGIT_COUNT,
        minSymbols: PASS_MIN_SYMBOL_COUNT,
        minUppercase: PASS_MIN_UPPERCASE_COUNT
    });
    return isValid
        ? {}
        : getPassRulesErrorsMapByParam({
            isValidMaxLength: length <= PASS_MAX_LENGTH,
            isValidMinLength: length >= PASS_MIN_LENGTH,
            isValidLowercase: lowercaseCount >= PASS_MIN_LOWERCASE_COUNT,
            isValidDigit: digitCount >= PASS_MIN_DIGIT_COUNT,
            isValidSymbol: symbolCount >= PASS_MIN_SYMBOL_COUNT,
            isValidUppercase: uppercaseCount >= PASS_MIN_UPPERCASE_COUNT
        });
};
export const passwordWithConfirmValidator = ({ password, confirmPassword }) => {
    const errors = passwordValidatorWithErrorIntlMessage(password);
    isEmpty(password) && (errors.password = { id: "validation.password.require", defaultMessage: "Required field" });
    !equals(password, confirmPassword) &&
        (errors.confirmPassword = { id: "validation.password.equal", defaultMessage: "Passwords must match" });
    isEmpty(confirmPassword) &&
        (errors.confirmPassword = { id: "validation.password.require", defaultMessage: "Required field" });
    return { isValid: isEmpty(errors), errors };
};
export const registrationValidator = ({ login, password, confirmPassword }) => {
    const { errors: emailErrors } = registrationLoginValidator(login);
    const { errors: passConfirmErrors } = passwordWithConfirmValidator({ password, confirmPassword });
    const errors = Object.assign(Object.assign({}, emailErrors), passConfirmErrors);
    return { isValid: isEmpty(errors), errors };
};
