import styled from "styled-components";
import { AuthModalFormSignUpLink, SectionTitle } from "@boomq/common";

export const AuthModalFormCenterSignUpLink = styled(AuthModalFormSignUpLink)`
    text-align: center;
`;

export const AuthSectionTitle = styled(SectionTitle)`
    color: #022349;
    font-size: 40px;
    font-weight: 700;
`;
