import React from "react";
import Helmet from "react-helmet";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Switch, withRouter } from "react-router-dom";
import {
    authorizeActions,
    getBaseUrl,
    getCurrentLanguage,
    env,
    EnvPlate,
    PrivateRoute,
    PublicRoute,
    Loader
} from "@boomq/common";
import { linkTags } from "../data/content/link";
import { metaTags } from "../data/content/meta";
import dictionaries from "../data/dictionaries/index";
import { AccountPage } from "../containers/routes/AccountPage";
import { ActivationPage } from "./routes/ActivationPage";
import { AdminPage } from "./routes/AdminPage";
import { RootForm } from "./routes/RootForm";

const { checkAuthorization } = authorizeActions;

const connector = connect(
    (state) => ({
        currentLanguage: getCurrentLanguage(state)
    }),
    { checkAuthorization }
);

const App = ({ checkAuthorization, currentLanguage }) => {
    React.useEffect(() => {
        checkAuthorization();
    }, []);

    return (
        <IntlProvider locale={currentLanguage} messages={dictionaries[currentLanguage]}>
            <React.Fragment>
                <Helmet
                    defaultTitle="Boomq"
                    titleTemplate="%s Boomq"
                    link={linkTags}
                    meta={metaTags[currentLanguage]}
                    base={{ href: getBaseUrl() }}
                />

                <Switch>
                    <PrivateRoute path="/admin" component={AdminPage} to="/authorize" />

                    <PrivateRoute path="/account" component={AccountPage} to="/authorize" />

                    <PublicRoute path="/activation/:code" component={ActivationPage} to="/account" />

                    <PublicRoute path="/change-password" component={RootForm} to="/account" />

                    <PublicRoute path="/registration" component={RootForm} to="/account" />

                    <PublicRoute path="/authorize" component={RootForm} to="/account" />

                    <Redirect from="/" to="/authorize" />
                </Switch>

                <Loader />

                {["aws", "production"].indexOf(env) === -1 && <EnvPlate env={env} />}
            </React.Fragment>
        </IntlProvider>
    );
};

export default withRouter(connector(App));
