import styled from "styled-components";
import { Color, FontBodySmallMedium, IconLogout } from "@boomq/uikit";
import { scaleActive } from "../../styled/global";
import { HeaderText } from "../../styled/Header";
export const StyledIconLogout = styled(IconLogout) `
    fill: ${Color.darkGrey35};
    height: 16px;
    margin-right: 4px;
    width: 16px;
`;
export const LogoutText = styled(HeaderText) `
    ${FontBodySmallMedium}
`;
export const LogoutWrapper = styled.button `
    ${scaleActive}
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    margin-left: 16px;
    outline: none;
    &:hover {
        ${LogoutText} {
            color: ${Color.blue};
        }
        ${StyledIconLogout} {
            fill: ${Color.blue};
        }
    }
`;
