import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import { Fade } from "../../animation";
import { supportedJMeterModules } from "../../../data/content/supportedJMeterModules";
function SupportedJMeterModules() {
    return (React.createElement(Fade, { animation: 'inUp' },
        React.createElement(PlateWrapper, null,
            React.createElement(Plate, null,
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { id: "account.user.supported.plugins.title", defaultMessage: "Supported JMeter's packages and plugins" })),
                React.createElement(Separator, null),
                React.createElement(InfoWrapper, null,
                    React.createElement(FormattedMessage, { id: "account.user.supported.plugins.text", defaultMessage: "Currently BOOMQ supports JMeter test plans utilizing elements from the following JMeter base packages and plugins:" }),
                    React.createElement(NumberList, null, supportedJMeterModules.map(module => React.createElement("li", { key: module.id },
                        module.text1,
                        React.createElement("a", { href: module.link, rel: "noopener noreferrer", target: "_blank" }, module.link),
                        module.text2))))))));
}
const InfoWrapper = styled.div `
    color: #677897;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    padding: 25px 34px 41px;
`;
const NumberList = styled.ol `
    list-style-position: inside;
`;
const Plate = styled.div `
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    border-radius: 5px;
    &:last-child {
      margin-bottom: 34px;
    }
`;
const PlateWrapper = styled.div `
    padding: 34px 34px 0;
`;
const Separator = styled.i `
    display: flex;
    width: 100%;
    height: 1px;
    background: #F2F2F2;
`;
const Title = styled.h3 `
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    color: #396496;
    padding: 25px 32px 19px;
`;
export default SupportedJMeterModules;
