import React from "react";
import { Area, AreaChart as Chart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CHART_DEFAULT_MARGIN_LEFT, CHART_DEFAULT_MARGIN_RIGHT, MULTIPLICATION_FACTOR } from "./constants";
import { withResize, sizes } from "../../styled/response";
const getChartMarginLeft = ({ leftYAxisValMaxLength }) => leftYAxisValMaxLength ? MULTIPLICATION_FACTOR * (leftYAxisValMaxLength - 1) : CHART_DEFAULT_MARGIN_LEFT;
const getChartMarginRight = ({ rightYAxisValMaxLength }) => rightYAxisValMaxLength ? MULTIPLICATION_FACTOR * (rightYAxisValMaxLength - 1) : CHART_DEFAULT_MARGIN_RIGHT;
const AreaChart = (props) => (React.createElement(ResponsiveContainer, Object.assign({}, props.size),
    React.createElement(Chart, { data: props.data, margin: props.windowSize.width > sizes.tablet
            ? { top: 30, right: getChartMarginRight(props), left: getChartMarginLeft(props), bottom: 20 }
            : { top: 30, right: 0, left: 0, bottom: 20 } },
        React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
        React.createElement(XAxis, { dataKey: props.axis.x }),
        props.axis.y.map((axis) => (React.createElement(YAxis, Object.assign({ key: axis.dataKey }, axis, { label: props.windowSize.width > sizes.tablet && axis.label })))),
        React.createElement(Tooltip, null),
        React.createElement(Legend, { iconSize: 12, iconType: "circle" }),
        props.areas.map((area) => (React.createElement(Area, Object.assign({ key: area.dataKey }, area, { connectNulls: true })))))));
AreaChart.defaultProps = {
    size: { width: "100%", height: 350 }
};
export default withResize(AreaChart);
