var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getPassRulesErrorsMapByParam } from "../../data/content/errorMessages";
const formatValue = ({ br, defaultMessage, id, values }, key, formatMessage) => id
    ? {
        [key]: formatMessage({ defaultMessage, id }, Object.assign(Object.assign({}, values), { br }))
    }
    : {};
export const formatPassRulesInErrors = (errors, formatMessage, Br) => {
    var _a;
    const _b = Object.assign(Object.assign({}, (((_a = errors === null || errors === void 0 ? void 0 : errors.passwordRules) === null || _a === void 0 ? void 0 : _a.values) || {})), { br: Br }), { br } = _b, passRulesValues = __rest(_b, ["br"]);
    const values = Object.keys(passRulesValues).reduce((res, key) => (Object.assign(Object.assign({}, res), { [key]: "" })), {});
    return Object.assign(Object.assign({}, (errors || {})), { passwordRules: Object.assign(Object.assign({}, ((errors === null || errors === void 0 ? void 0 : errors.passwordRules) || {})), { values: Object.keys(passRulesValues).reduce((res, valueKey) => (Object.assign(Object.assign({}, res), formatValue(Object.assign(Object.assign({}, passRulesValues[valueKey]), { br }), valueKey, formatMessage))), values) }) });
};
export const generatePasswordTooltip = (formatMessage, Br) => {
    const passRulesErrorsMessage = getPassRulesErrorsMapByParam({
        isValidMaxLength: true,
        isValidMinLength: false,
        isValidLowercase: false,
        isValidDigit: false,
        isValidSymbol: false,
        isValidUppercase: false
    });
    const { passwordRules } = formatPassRulesInErrors(passRulesErrorsMessage || {}, formatMessage, Br);
    return (passwordRules === null || passwordRules === void 0 ? void 0 : passwordRules.id) ? formatMessage(passwordRules, passwordRules.values) : "";
};
