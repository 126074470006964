import React from "react";
import { PseudoInput, StyledCheckbox } from "./styled";
export var SwitcherDirection;
(function (SwitcherDirection) {
    SwitcherDirection["Horizontal"] = "HORIZONTAL";
    SwitcherDirection["Vertical"] = "VERTICAL";
})(SwitcherDirection || (SwitcherDirection = {}));
export const Switcher = ({ direction = SwitcherDirection.Horizontal, disabled, name, onChange, onClick, defaultChecked, theme }) => (React.createElement("label", { onClick: onClick },
    React.createElement(PseudoInput, { direction: direction, type: "checkbox", checked: defaultChecked, disabled: disabled, name: name || "checkbox", onChange: onChange }),
    React.createElement(StyledCheckbox, { direction: direction, theme: theme })));
