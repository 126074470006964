var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getValueByPath, isEmpty, useIntl } from "@boomq/utils";
import { LOGIN_INPUT_PLACEHOLDER, PASSWORD_INPUT_PLACEHOLDER } from "./intl";
import { AuthorizeFormInputs } from "../../../components/website/AuthorizeFormInputs";
import { AuthForm } from "../../../components/styled/Auth";
import { formatIntlMessage } from "../../../helpers/intl";
import { formatEmail } from "../../../helpers/utils";
import { loginValidator } from "../../../helpers/validators";
import { resetError } from "../../../redux/actions/error";
import { loginRequest } from "../../../redux/actions/login";
const connector = connect(null, {
    onSubmit: loginRequest,
    onUnmount: resetError
});
const getErrorsFromProps = ({ errors }) => errors || {};
const getEventValue = (event) => getValueByPath(event, "target.value", "");
const AuthorizeFormContainer = (_a) => {
    var { children, onSubmit, onUnmount, type } = _a, restProps = __rest(_a, ["children", "onSubmit", "onUnmount", "type"]);
    const [errors, setErrors] = useState(getErrorsFromProps(restProps));
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const { formatMessage } = useIntl();
    useEffect(() => {
        return () => onUnmount();
    }, []);
    useEffect(() => {
        const checkErrors = (restProps) => {
            const errorsFromProps = getErrorsFromProps(restProps);
            return isEmpty(errorsFromProps) ? undefined : setErrors(errorsFromProps);
        };
        checkErrors(restProps);
    }, [restProps.errors]);
    const blurLoginHandler = () => setLogin(formatEmail(login));
    const changeLoginHandler = (event) => {
        setErrors(Object.assign(Object.assign({}, errors), { login: null }));
        setLogin(getEventValue(event));
    };
    const changePasswordHandler = (event) => {
        setErrors(Object.assign(Object.assign({}, errors), { password: null }));
        setPassword(getEventValue(event));
    };
    const submitHandler = (event) => {
        event.preventDefault();
        const { isValid, errors } = loginValidator({ login, password });
        setErrors(errors);
        return isValid ? onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit({ formType: type, login, password }) : undefined;
    };
    const loginInputPlaceholder = formatIntlMessage(LOGIN_INPUT_PLACEHOLDER, formatMessage);
    const passwordInputPlaceholder = formatIntlMessage(PASSWORD_INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(AuthForm, Object.assign({}, restProps, { onSubmit: submitHandler }),
        React.createElement(AuthorizeFormInputs, { errors: errors, login: login, loginInputPlaceholder: loginInputPlaceholder, password: password, passwordInputPlaceholder: passwordInputPlaceholder, onBlurLogin: blurLoginHandler, onChangeLogin: changeLoginHandler, onChangePassword: changePasswordHandler }),
        children));
};
export default connector(AuthorizeFormContainer);
