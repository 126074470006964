export var TestDetailsAction;
(function (TestDetailsAction) {
    TestDetailsAction["ChangeSla"] = "onChangeSla";
    TestDetailsAction["CreateTrend"] = "onCreateTrend";
    TestDetailsAction["Stop"] = "onStop";
})(TestDetailsAction || (TestDetailsAction = {}));
export var TestResultSlaState;
(function (TestResultSlaState) {
    TestResultSlaState["Processing"] = "PROCESSING";
    TestResultSlaState["Undefined"] = "UNDEFINED";
    TestResultSlaState["Finished"] = "FINISHED";
    TestResultSlaState["Failed"] = "FAILED";
    TestResultSlaState["NoData"] = "NO_DATA_TEST_NOT_STARTED";
})(TestResultSlaState || (TestResultSlaState = {}));
export var TestDisplayStatus;
(function (TestDisplayStatus) {
    TestDisplayStatus["Canceled"] = "CANCELED";
    TestDisplayStatus["Failed"] = "FAILED";
    TestDisplayStatus["Finished"] = "FINISHED";
    TestDisplayStatus["Initialization"] = "INITIALIZATION";
    TestDisplayStatus["Running"] = "RUNNING";
    TestDisplayStatus["TestStopping"] = "TEST_STOPPING";
    TestDisplayStatus["Waiting"] = "WAITING";
})(TestDisplayStatus || (TestDisplayStatus = {}));
export var TestStatus;
(function (TestStatus) {
    TestStatus["Canceled"] = "CANCELED";
    TestStatus["Created"] = "CREATED";
    TestStatus["Failed"] = "FAILED";
    TestStatus["Finished"] = "FINISHED";
    TestStatus["RetryingWaiting"] = "RETRYING_WAITING";
    TestStatus["ResourcesAllocated"] = "RESOURCES_ALLOCATED";
    TestStatus["Running"] = "RUNNING";
    TestStatus["TestPlansCreated"] = "TEST_PLANS_CREATED";
    TestStatus["TestProjectSettingsSelected"] = "TEST_PROJECT_SETTINGS_SELECTED";
    TestStatus["TestResourcesEstimated"] = "TEST_RESOURCES_ESTIMATED";
    TestStatus["TestStopping"] = "TEST_STOPPING";
    TestStatus["Waiting"] = "WAITING";
})(TestStatus || (TestStatus = {}));
