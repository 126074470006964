export const RESPONSE_TIME_TITLE = {
    defaultMessage: "Response Time",
    id: "account.test.details.chart.title.response"
};
export const RESPONSE_TIME_TOOLTIP = {
    defaultMessage: "Response Time",
    id: "account.test.details.chart.title.response.tooltip"
};
export const TEST_CURRENT_STATS_TITLE = {
    defaultMessage: "Current stats",
    id: "account.test.details.current.stats"
};
export const TEST_DETAILS_CHART_THROUGHPUT_TITLE = {
    defaultMessage: "Throughput",
    id: "account.test.details.chart.title.throughput"
};
export const TEST_DETAILS_CHART_THROUGHPUT_TOOLTIP = {
    defaultMessage: "Throughput",
    id: "account.test.details.chart.title.throughput.tooltip"
};
export const TEST_DETAILS_SETTINGS_TITLE = {
    defaultMessage: "Test run settings",
    id: "account.test.details.settings"
};
export const TEST_LOGS_TITLE = {
    defaultMessage: "Test run logs",
    id: "account.test.details.logs"
};
