export var RequestType;
(function (RequestType) {
    RequestType["Grpc"] = "GRPC";
    RequestType["Http"] = "HTTP";
})(RequestType || (RequestType = {}));
export var GrpcMethod;
(function (GrpcMethod) {
    GrpcMethod["Unary"] = "UNARY";
})(GrpcMethod || (GrpcMethod = {}));
export var GrpcMethodColor;
(function (GrpcMethodColor) {
    GrpcMethodColor["Unary"] = "#91FBA8";
})(GrpcMethodColor || (GrpcMethodColor = {}));
export var HttpMethod;
(function (HttpMethod) {
    HttpMethod["Get"] = "GET";
    HttpMethod["Post"] = "POST";
    HttpMethod["Put"] = "PUT";
    HttpMethod["Patch"] = "PATCH";
    HttpMethod["Delete"] = "DELETE";
    HttpMethod["Options"] = "OPTIONS";
    HttpMethod["Head"] = "HEAD";
})(HttpMethod || (HttpMethod = {}));
export var HttpMethodColor;
(function (HttpMethodColor) {
    HttpMethodColor["Get"] = "#91FBA8";
    HttpMethodColor["Post"] = "#B6D3FF";
    HttpMethodColor["Put"] = "#E8B8FF";
    HttpMethodColor["Patch"] = "#C1FFF4";
    HttpMethodColor["Delete"] = "#FF9797";
    HttpMethodColor["Options"] = "#D2FF98";
    HttpMethodColor["Head"] = "#FFC998";
})(HttpMethodColor || (HttpMethodColor = {}));
