import styled from "styled-components";
import { Color, FontBodyBig } from "@boomq/uikit";
import { AddingIcon, StyledButton, StyledButtonWithLink, StyledButtonWithRoute } from "../../styled/Buttons";
import { media } from "../../styled/response";
const DetailedButtonStyles = `
    margin-top: 10px;
`;
export const StyledAddingIcon = styled(AddingIcon) `
    margin-right: 4px;
    &:before,
    &:after {
        background: ${Color.blue};
    }
`;
const StyledDetailsButton = styled(StyledButton) `
    ${DetailedButtonStyles}
`;
export const StyledDetailsButtonWithLink = styled(StyledButtonWithLink) `
    ${DetailedButtonStyles}
`;
export const StyledDetailsButtonWithRoute = styled(StyledButtonWithRoute) `
    ${DetailedButtonStyles}
`;
export const StyledDetailsTrendButton = styled(StyledButton) `
    ${DetailedButtonStyles}
    align-items: center;
    display: flex;
    justify-content: center;
    width: auto;
    &:hover ${StyledAddingIcon} {
        &:before,
        &:after {
            background: #fff;
        }
    }
`;
export const StyledDetailsButtons = styled.div `
    display: flex;
    padding: 24px 32px 0;
    ${StyledDetailsButtonWithLink}, ${StyledDetailsButtonWithRoute}, ${StyledDetailsTrendButton}, ${StyledDetailsButton} {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 25px;
        &:last-child {
            margin-right: 0;
        }
        ${media.giant} {
            margin-right: 5px;
        }
        ${media.desktop} {
            margin: 5px 0;
            width: 100%;
        }
    }
    ${media.desktop} {
        flex-direction: column;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    ${media.tablet} {
        padding: 34px 12px 0;
    }
`;
export const MainButtons = styled.div `
    display: flex;
    flex-wrap: wrap;
    ${StyledDetailsButton} {
        position: relative;
    }
`;
export const StopTestButton = styled(StyledDetailsButton) `
    color: ${Color.red};
    border-color: ${Color.red};
    &:hover {
        background: ${Color.red};
    }
`;
export const RightBlock = styled.div `
    display: flex;
    flex: auto;
    justify-content: flex-end;
    margin-left: 25px;
    ${media.giant} {
        margin-left: 5px;
    }
    ${media.desktop} {
        margin: 5px 0;
        ${StopTestButton} {
            width: 100%;
        }
    }
`;
export const StoppingText = styled.p `
    ${FontBodyBig}
    ${DetailedButtonStyles}
    align-items: center;
    color: ${Color.blue};
    display: flex;
    margin-left: 25px;
`;
