// import {routerReducer as router} from 'react-router-redux';
import { combineReducers, CombinedState } from 'redux';
import { connectRouter } from "connected-react-router";
import { commonRootReducer } from "@boomq/common";
import runnerResources from "./reducers/runnerResources";

// const rootReducer: CombinedState<any | INewRequestState> = combineReducers({
const rootReducer: CombinedState<any> = (history) => combineReducers({
    ...commonRootReducer,
    router: connectRouter(history),
    runnerResources
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
