import { copyElementDataToClipboard } from "@boomq/utils";
import { EMBED_CODE_VARIABLE_NAME } from "./constants";
import { getHostUrl } from "../location";
const copyEmbedCodeUsingElement = (element, embedCode) => {
    element.innerText = embedCode;
    copyElementDataToClipboard(element);
    element.innerText = "";
};
export const copyEmbedCodeToClipboard = (element, embedCode) => element && embedCode ? copyEmbedCodeUsingElement(element, embedCode) : undefined;
const getSlaReportItemEmbedCodeApiByType = (type) => type === "table" ? "getSlaReportTable" : "getSlaReportChart";
export const generateSlaReportItemEmbedCode = (profileId, type, id, locale, token) => `
    <div id="${type}-${id}">
        <script src="${getHostUrl()}/trending.js"></script>
        <script>
            ${EMBED_CODE_VARIABLE_NAME} &&
                typeof ${EMBED_CODE_VARIABLE_NAME}.${getSlaReportItemEmbedCodeApiByType(type)} === "function" &&
                ${EMBED_CODE_VARIABLE_NAME}.${getSlaReportItemEmbedCodeApiByType(type)}("${getHostUrl()}", "${profileId}", "${id}", "${locale}", "${token}")
        </script>
    </div>
`;
