import styled from "styled-components";
import { Color, IconCheckHalfCircle, IconFolder, IconList, IconTrending } from "@boomq/uikit";
export const StyledIconCheckCircle = styled(IconCheckHalfCircle) `
    fill: ${Color.grey65};
`;
export const StyledIconList = styled(IconList) `
    fill: ${Color.grey65};
`;
export const StyledIconFolder = StyledIconCheckCircle.withComponent(IconFolder);
export const StyledIconTrending = StyledIconCheckCircle.withComponent(IconTrending);
