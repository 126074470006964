var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import { useIntl } from "@boomq/utils";
import { InputText } from "@boomq/uikit";
import { HOST_NAME_INPUT_LABEL, HOST_NAME_INPUT_PLACEHOLDER } from "./intl";
import { hostnameInputTheme } from "./styled";
import { ChartSerieCommonParamsContainer } from "../ChartSerieCommonParams";
import { formatIntlMessage } from "../../../../helpers/intl";
import { generateReportChartSystemMetricTitle } from "../../../../helpers/reportEditor";
export const ChartSerieSystemMetricParamsContainer = (_a) => {
    var { hostName, onClickApply } = _a, restProps = __rest(_a, ["hostName", "onClickApply"]);
    const [hostnameValue, setHostnameValue] = useState(hostName);
    const { formatMessage } = useIntl();
    const blurHostnameHandler = (value) => setHostnameValue(value);
    const clickApplyHandler = (data) => onClickApply(Object.assign(Object.assign({}, data), { hostName: hostnameValue, chartTitle: generateReportChartSystemMetricTitle(Object.assign(Object.assign({}, data), { hostName: hostnameValue }), formatMessage) }));
    const hostNameInputLabel = formatIntlMessage(HOST_NAME_INPUT_LABEL, formatMessage);
    const hostNameInputPlaceholder = formatIntlMessage(HOST_NAME_INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(ChartSerieCommonParamsContainer, Object.assign({}, restProps, { isDisabledApplyButton: !hostnameValue, onClickApply: clickApplyHandler }),
        React.createElement(InputText, { onBlur: blurHostnameHandler, label: hostNameInputLabel, placeholder: hostNameInputPlaceholder, theme: hostnameInputTheme, value: hostnameValue })));
};
