import { parseQueryParamsFromSearchParams } from "../queryParamsFromSearchParams";
import { unEscapeComponent } from "../unEscapingComponent";
const generateSearchFromQueryParams = (queryParams) => `?${Object.keys(queryParams || {})
    .map((queryParamKey) => `${queryParamKey}=${queryParams[queryParamKey]}`)
    .join("&")}`;
export const parseRequestUrlToParameters = (requestUrl = "") => {
    try {
        const url = new URL(requestUrl);
        const queryParams = parseQueryParamsFromSearchParams(url.searchParams);
        return {
            path: unEscapeComponent(url.pathname),
            port: unEscapeComponent(url.port),
            protocol: unEscapeComponent(url.protocol).replace(":", ""),
            queryParams,
            search: generateSearchFromQueryParams(queryParams),
            serverName: unEscapeComponent(url.hostname)
        };
    }
    catch (e) {
        return {
            path: "",
            port: "",
            protocol: "",
            queryParams: [],
            search: "",
            serverName: ""
        };
    }
};
