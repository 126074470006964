import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { env, history } from "@boomq/common";
import createRootReducer from "./reducer";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();
const enhancer = ["aws", "production"].includes(env)
    ? applyMiddleware(sagaMiddleware, routerMiddleware(history))
    : applyMiddleware(sagaMiddleware, routerMiddleware(history), logger);
const store = createStore(createRootReducer(history), enhancer);

sagaMiddleware.run(rootSaga);

export default store;
