var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { withRouter } from "react-router-dom";
import { AllTestProjectsItem } from "../../../components/account/AllTestProjectsItem";
import { addTestProjectLabelRequest, addTestProjectLabelWithCreatingRequest, deleteTestProjectLabelRequest } from "../../../redux/actions/testProject";
import { copyTestProjectRequest, deleteTestProjectRequest, editTestProject, searchTestProjectsLabelsByName } from "../../../redux/actions/testProjects";
import { showTestProjectsSettingsSetSidebar } from "../../../redux/actions/testProjectsSettingsSetSidebar";
import { getActionItemsByTeamMemberPermissions } from "../../../helpers/testProjects";
import { getClientPermissionList } from "../../../redux/reducers/authorizePlate";
import { EMPTY_LABELS_TEXT, prepareLabels } from "../../../helpers/labels";
import { TestProjectsItemAction } from "../../../helpers/types";
const connector = connect((state) => ({
    permissionList: getClientPermissionList(state)
}), {
    [TestProjectsItemAction.AddLabel]: addTestProjectLabelRequest,
    [TestProjectsItemAction.AddLabelWithCreating]: addTestProjectLabelWithCreatingRequest,
    [TestProjectsItemAction.Copy]: copyTestProjectRequest,
    onDelete: deleteTestProjectRequest,
    [TestProjectsItemAction.DeleteLabel]: deleteTestProjectLabelRequest,
    [TestProjectsItemAction.Edit]: editTestProject,
    [TestProjectsItemAction.RedirectToLoadProfile]: editTestProject,
    [TestProjectsItemAction.SearchLabels]: searchTestProjectsLabelsByName,
    [TestProjectsItemAction.ShowSettingsSetSidebar]: showTestProjectsSettingsSetSidebar
});
const AllTestProjectsItemContainer = (_a) => {
    var { onAddLabel, onAddLabelWithCreating, onDeleteLabel, onSearchLabels } = _a, restProps = __rest(_a, ["onAddLabel", "onAddLabelWithCreating", "onDeleteLabel", "onSearchLabels"]);
    const { data: { id, labelSet = [] } } = restProps;
    const { formatMessage } = useIntl();
    const actionItems = getActionItemsByTeamMemberPermissions(restProps.permissionList);
    const addLabelHandler = (label) => onAddLabel({ id, label });
    const addLabelWithCreatingHandler = (name) => onAddLabelWithCreating({ id, name });
    const deleteLabelHandler = (label) => onDeleteLabel({ id, label: label.name });
    const searchLabels = (query) => onSearchLabels({ id, query });
    const emptyLabelsText = formatMessage(EMPTY_LABELS_TEXT);
    const labels = prepareLabels(labelSet);
    return (React.createElement(AllTestProjectsItem, Object.assign({ actionItems: actionItems, emptyLabelsText: emptyLabelsText, labels: labels, onAddLabel: addLabelHandler, onAddLabelWithCreating: addLabelWithCreatingHandler, onDeleteLabel: deleteLabelHandler, onSearchLabels: searchLabels }, restProps)));
};
export default withRouter(connector(AllTestProjectsItemContainer));
