export var ThreadGroupController;
(function (ThreadGroupController) {
    ThreadGroupController["Controller"] = "CONTROLLER";
    ThreadGroupController["Sampler"] = "SAMPLER";
})(ThreadGroupController || (ThreadGroupController = {}));
export var ThreadGroupImportDataController;
(function (ThreadGroupImportDataController) {
    ThreadGroupImportDataController["ImportDataFromFile"] = "IMPORT_DATA_FROM_FILE";
})(ThreadGroupImportDataController || (ThreadGroupImportDataController = {}));
export var ControllerFlowControlType;
(function (ControllerFlowControlType) {
    ControllerFlowControlType["ForEach"] = "FOR_EACH";
    ControllerFlowControlType["If"] = "IF";
    ControllerFlowControlType["Loop"] = "LOOP";
    ControllerFlowControlType["While"] = "WHILE";
})(ControllerFlowControlType || (ControllerFlowControlType = {}));
export var ControllerType;
(function (ControllerType) {
    ControllerType["Transaction"] = "TRANSACTION";
})(ControllerType || (ControllerType = {}));
export var SamplerType;
(function (SamplerType) {
    SamplerType["Grpc"] = "GRPC";
    SamplerType["Http"] = "HTTP";
})(SamplerType || (SamplerType = {}));
export var RequestGroupEditorElement;
(function (RequestGroupEditorElement) {
    RequestGroupEditorElement["Param"] = "PARAM";
    RequestGroupEditorElement["TestFragment"] = "TEST_FRAGMENT";
    RequestGroupEditorElement["Timer"] = "TIMER";
})(RequestGroupEditorElement || (RequestGroupEditorElement = {}));
