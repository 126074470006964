import { createActions } from "redux-actions";
const { analyticsRequest, analyticsSuccess, analyticsFailure, changeClientTasksFiltersFailure, changeClientTasksFiltersRequest, changeParamsRequest, changeParamsFailure, changeParamsSuccess, changePasswordFailure, changePasswordRequest, clientTasksRequest, clientTasksSuccess, clientTasksFailure, clientWebsitesRequest, getTestRunsData, getTestRunsDataWithSocketRequestStop, selectClientTasks, setCurrentPage, toggleClientTask, addClientTask, updateClientTask, removeClientTask, websitesSuccess, websitesFailure } = createActions("ANALYTICS_REQUEST", "ANALYTICS_SUCCESS", "ANALYTICS_FAILURE", "CHANGE_CLIENT_TASKS_FILTERS_FAILURE", "CHANGE_CLIENT_TASKS_FILTERS_REQUEST", "CHANGE_PARAMS_REQUEST", "CHANGE_PARAMS_FAILURE", "CHANGE_PARAMS_SUCCESS", "CHANGE_PASSWORD_FAILURE", "CHANGE_PASSWORD_REQUEST", "CHANGE_PASSWORD_SUCCESS", "CLIENT_TASKS_REQUEST", "CLIENT_TASKS_SUCCESS", "CLIENT_TASKS_FAILURE", "CLIENT_WEBSITES_REQUEST", "GET_TEST_RUNS_DATA", "GET_TEST_RUNS_DATA_WITH_SOCKET_REQUEST_STOP", "SELECT_CLIENT_TASKS", "SET_CURRENT_PAGE", "TOGGLE_CLIENT_TASK", "ADD_CLIENT_TASK", "UPDATE_CLIENT_TASK", "REMOVE_CLIENT_TASK", "WEBSITES_SUCCESS", "WEBSITES_FAILURE");
export default {
    analyticsRequest,
    analyticsSuccess,
    analyticsFailure,
    changeClientTasksFiltersFailure,
    changeClientTasksFiltersRequest,
    changeParamsRequest,
    changeParamsFailure,
    changeParamsSuccess,
    changePasswordFailure,
    changePasswordRequest,
    clientTasksRequest,
    clientTasksSuccess,
    clientTasksFailure,
    clientWebsitesRequest,
    getTestRunsData,
    getTestRunsDataWithSocketRequestStop,
    selectClientTasks,
    setCurrentPage,
    toggleClientTask,
    addClientTask,
    updateClientTask,
    removeClientTask,
    websitesSuccess,
    websitesFailure
};
