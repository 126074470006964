import { ALL_TESTS_MENU_ITEM_LABEL, NEW_TEST_MENU_ITEM_LABEL, PROJECTS_MENU_ITEM_LABEL, REPORTS_MENU_ITEM_LABEL, TRENDING_MENU_ITEM_LABEL } from "./intl";
import { StyledIconCheckCircle, StyledIconFolder, StyledIconList, StyledIconTrending } from "./styled";
import { getAllTestsRoute, getNewTestRoute, getReportsRoute, getSlaReportProfilesRoute, getTestProjectsRoute } from "../../helpers/routes";
import { MenuLinkType } from "../../helpers/types";
export const menuOptions = [
    {
        id: 0,
        icon: StyledIconCheckCircle,
        label: NEW_TEST_MENU_ITEM_LABEL,
        linkType: MenuLinkType.Nav,
        to: getNewTestRoute()
    },
    {
        id: 1,
        icon: StyledIconList,
        label: ALL_TESTS_MENU_ITEM_LABEL,
        linkType: MenuLinkType.Nav,
        to: getAllTestsRoute()
    },
    {
        id: 2,
        icon: StyledIconFolder,
        label: PROJECTS_MENU_ITEM_LABEL,
        linkType: MenuLinkType.Nav,
        to: getTestProjectsRoute()
    },
    {
        id: 3,
        icon: StyledIconTrending,
        label: TRENDING_MENU_ITEM_LABEL,
        linkType: MenuLinkType.Nav,
        to: getSlaReportProfilesRoute()
    },
    {
        id: 4,
        icon: StyledIconFolder,
        label: REPORTS_MENU_ITEM_LABEL,
        linkType: MenuLinkType.Nav,
        to: getReportsRoute()
    }
];
