import styled from "styled-components";
import { Button, Color, H2Styles, IconClose, IconSettings, Select } from "@boomq/uikit";
import { getZIndex } from "../../../styled/global";
export const getClassNameByParams = (isShowParamsSidebar) => {
    const className = "pflb-sidebar-settings";
    return isShowParamsSidebar ? `${className} show` : className;
};
export const ApplyButton = styled(Button) `
    margin-top: 16px;
`;
export const StyledIconClose = styled(IconClose) `
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
`;
export const StyledIconSettings = styled(IconSettings) `
    color: ${Color.blue};
    cursor: pointer;
`;
export const StyledSidebar = styled.div `
    right: 0;
    visibility: hidden;
`;
export const TableHeader = styled.div `
    align-items: center;
    display: flex;
    position: absolute;
    z-index: ${getZIndex("Sidebar")};
    width: 100%;
    .editable-title {
        align-items: center;
        margin-left: 4px;
        visibility: visible;
        width: 100%;
        ${H2Styles}
    }
`;
export const TableTypeSelect = styled(Select) `
    margin-top: 16px;
    width: 100%;
`;
export const TestIdSelect = styled(Select) `
    margin-top: 12px;
    width: 100%;
`;
export const Title = styled.h2 `
    ${H2Styles}
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;
