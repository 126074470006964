import styled, { css } from "styled-components";
import { Block, Help } from "@boomq/uikit";
import { media } from "../../styled/response";
import { StyledTitle3 } from "../../styled/Titles";
export const chartLeftAxisLabel = {
    textAnchor: "middle",
    transform: "rotate(-90)",
    x: "-150",
    y: "15"
};
export const chartLeftAxisLabelOrientation = {
    orientation: "left",
    yAxisId: "left"
};
export const chartRightAxisLabel = {
    textAnchor: "middle",
    x: "155",
    y: "0"
};
export const chartRightAxisLabelOrientation = {
    orientation: "right",
    yAxisId: "right"
};
export const BlockWrapper = styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 -11px;
    padding: 15px 0 34px;
    ${media.desktop} {
        grid-template-columns: 100%;
    }
`;
export const StyledBlock = styled.div `
    ${Block}
    margin: 11px;
    .recharts-responsive-container {
        margin-left: 2.5%;
        width: 95% !important;
    }
`;
export const StyledHelp = styled(Help) `
    margin-left: 8px;
`;
export const TestDetailsInfoBlock = styled.div `
    ${Block}
    display: flex;
    margin: 11px;
    padding-bottom: 20px;
    ${(props) => !props.testDuringExecution
    ? css `
                  grid-column: 1/3;
                  ${media.desktop} {
                      grid-column: 1/2;
                  }
              `
    : css ``}
`;
export const TestDetailsColumnInfoBlock = styled(TestDetailsInfoBlock) `
    flex-direction: column;
`;
export const TestDetailsInfoSection = styled.div `
    flex: 1;
`;
export const Title = styled(StyledTitle3) `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #f2f2f2;
    > button {
        margin-left: 4px;
    }
`;
export const VUsersLabel = styled.text `
    transform: translateX(calc(100% - 12px)) rotate(90deg);
`;
