import { MouseEvent, ChangeEvent, ComponentType, SVGAttributes } from "react";
import { FlattenSimpleInterpolation } from "styled-components";
import { TestType } from "./test";

export enum FormType {
    Add = "add",
    Edit = "edit",
    View = "view"
}

export enum GlobalParameter {
    Csv = "CSV",
    Literal = "LITERAL"
}

export enum Mode {
    Edit = "EDIT",
    EditResult = "EDIT_RESULT",
    View = "VIEW"
}

export enum RequestGroupType {
    BoomqStableScalability = "BOOMQ_STABLE_SCALABILITY",
    SimpleThreadGroup = "SIMPLE_THREAD_GROUP",
    Ultimate = "ultimate",
    Ultimate2 = "ULTIMATE"
}

export enum SelectionType {
    Combine = "COMBINE",
    Multiple = "MULTIPLE",
    Single = "SINGLE"
}

export enum TestProjectAction {
    Save = "onSave",
    RunTest = "onRunTest"
}

export enum TypeSliderInputType {
    Input = "INPUT",
    Slider = "SLIDER"
}

export type AutoPercentages = {
    isAccepted: boolean;
    value: boolean;
};

export interface ClickEvent<T = Element> extends MouseEvent<T> {
    target: EventTarget & T;
}

export type ConfirmActionPayload<T> = {
    isAccepted: boolean;
} & T;

export interface TestProjectStoreData {
    autoPercentages: boolean;
    chartLocation: any;
    comment: string;
    currentVersion: string;
    files: [];
    // geo: "NONE";
    JMXProfiles: HashMap<HashMap<number>>;
    isModified: boolean;
    isSaved: boolean;
    isSavedAs: boolean;
    loadType: SettingMessage;
    previewJMXChart: {
        isMergedJMXChartSteps: boolean;
        isMergedJMXChartGroups: boolean;
        timeLine: {
            min: number;
            max: number;
            value: number[];
        };
    };
    previewThreadGroupsChart: {
        isMergedChartGroups: boolean;
        timeLine: {
            min: number;
            max: number;
            value: number[];
        };
    };
    SCALABILITY: TestProjectLoadParams;
    STABLE: TestProjectLoadParams;
    chartData: { coords: PreviewDataPoint[] }[] | PreviewDataPoint[][];
    groups: HashMap<string>[];
    projectName: string;
    projectId: string | null;
    requests: [];
    requestParameters: {
        literalParameters: [];
        csvParameters: [];
        others: [];
    };
    runnerResources: string[];
    savedProjectId: string | null;
    serializedData: string;
    step: number;
    testType: string;
    versions: [];
}

export type CustomChangeEvent<T = Element> = ChangeEvent<T> & { target: EventTarget & T & { checked: boolean } };

export type CustomCSS = FlattenSimpleInterpolation | string;

export type CustomSiteAnalytics = {
    endDay: string;
    siteId: number;
    startDay: string;
    top: number;
    type: string;
    userId: string;
};

export type EndedTestTitle = {
    isLong?: boolean;
    text: IntlMessage;
};

export type ExpressionValueRegExp = {
    [index: string]: string | number;
    groupNumber: number;
    re: string;
};

export type ExpressionValueJsonPath = {
    [index: string]: string;
    jsonpath: string;
};

export type ExpressionValueXPath = {
    [index: string]: string;
    xpath: string;
};

export type ExpressionValueBorder = {
    [index: string]: string;
    leftBorder: string;
    rightBorder: string;
};

export type ExpressionValue =
    | ExpressionValueRegExp
    | ExpressionValueJsonPath
    | ExpressionValueXPath
    | ExpressionValueBorder;

export type Extractor = {
    type: string;
    extractorParameters: any;
};

export type ExtractResponse = {
    [index: string]: any;
    id: string;
    variable: string;
    expression: ExpressionValue;
    type: string;
};

export type ExtractorParametersRegExp = {
    group: string | number;
    re: string;
};

export type ExtractorParametersJsonPath = {
    jsonpath: string | number;
};

export type ExtractorParametersBorder = {
    left: string | number;
    right: string | number;
};

export type ExtractorParametersXPath = {
    xpath: string | number;
};

export type ExtractorParameters =
    | ExtractorParametersRegExp
    | ExtractorParametersJsonPath
    | ExtractorParametersBorder
    | ExtractorParametersXPath;

export type ExtractType = {
    id: number;
    expressionValue: ExpressionValue;
    placeholder: HashMap<IntlMessage>;
    text: IntlMessage | string;
    value: string;
};

export type HashMap<T> = {
    [key: string]: T;
};

export type IconAction = {
    onClick: () => void;
    step?: string;
};

export type IntlLink = {
    defaultMessage: string;
    id: string;
    to: string;
};

export type IntlMap = {
    center: number[];
    zoom: number;
};

export type IntlMessage = {
    defaultMessage: string;
    id: string;
    values?: HashMap<any>;
};

export type KeyValueParam = {
    id?: string;
    key: string;
    value: string;
};

export type LandingSectionItem = {
    description: IntlMessage | IntlMessage[];
    id: number;
    img: any;
    title: IntlMessage;
};

export type LandingBannerSection = {
    button: RouteButton;
    separate: IntlMessage;
    linkText: IntlMessage;
    img: any;
    subtitle: IntlMessage;
    title: IntlMessage[];
};

export type Link = {
    to: string;
    text: string;
};

export type ListItem<T = string, U = {}> = {
    id: string;
    text: string;
    value: T;
} & U;

export type LoadType = {
    name: string;
    defaultChecked: boolean;
    description: IntlMessage;
    title: IntlMessage;
};

export type MassActionButton = {
    actionName: string;
    actionPayload?: any;
    id: string | number;
    testType?: TestType;
    text: IntlMessage;
};

export type MassActionElementButton = {
    id: string;
    element: (props: any) => JSX.Element;
    elementProps: any;
    testType?: TestType;
};

export type Media = { [index: string]: FlattenSimpleInterpolation };

export type MenuOption = {
    defaultMessage: string;
    id: string;
    img: any;
    to: string;
};

export type MetaTag = {
    content: string;
    name: string;
};

export type Method = {
    bgColor?: string;
    id: number;
    text: string;
    value: string;
    excludeParams?: string[];
};

export type TestProjectParamsField = {
    field: string;
    value: string | number | boolean;
    isModified?: boolean;
    isSaved?: boolean;
};

export type NewRequestParamsField = {
    field: string;
    value: string | number | boolean;
};

export type PreliminaryTask = {
    percent: number;
    title: IntlMessage;
    img: React.FunctionComponent<SVGAttributes<SVGElement>>;
};

export type ProcessEnvParam = string | undefined;

export type PreviewDataPoint = { time: number; users: number };

export type PreviewDataPointTimeLine<T = {}> = T & {
    start: number;
    finish: number;
};

export type PreviewDataPointWithTimeLine = PreviewDataPointTimeLine<{ usersFunc: (time?: number) => number }>;

export type PreviewDataProps = {
    loadType?: string;
    timeLine?: number[];
} & TestProjectLoadParams;

export type RequestParamBody = {
    TEXT: string;
};

export type RequestGroupAction = {
    action: string;
    id: number;
    name: IntlMessage;
};

export type Route = {
    exact: boolean;
    path: string;
    component: any;
};

export type RouteButton = {
    defaultMessage: string;
    id: string;
    route: string;
};

export type SettingMessage = TestTypeDescription & {
    defaultChecked: boolean;
    description: IntlMessage;
    name: string;
};

export type TestTypeDescription = {
    title: IntlMessage;
    tooltip?: IntlMessage;
};

export type SiteAnalytics = {
    top: number;
    id: number;
    url: string;
};

export type TabItem = {
    id: number;
    component: {
        elementName: string;
        props: HashMap<any>;
    };
    icon?: React.FunctionComponent<SVGAttributes<SVGElement>>;
    name: string;
    title: IntlMessage;
};

export type TestProfileTopUrl = {
    id: number;
    text: IntlMessage;
    value: string;
};

export type TestProject = {
    id: string;
    comment: string;
    lastModified: string;
    projectName: string;
    requestCount: number;
    testType: TestType;
    totalDuration: number;
};

export type TestProjectLoadParams = {
    durationAddedOnLastStep?: number;
    initialDelay?: number;
    rampDown: number;
    rampUp: number;
    stepLength: number;
    stepCount: number;
    usersPerStep: number;
};

export type TestProjectRequestAction = {
    actionName: string;
    icon: ComponentType;
};

export type TestProjectRequestParams = {
    groups: TestProjectRequestGroup[];
    loadProfiles: HashMap<TestProjectLoadProfile>;
    requests: TestProjectRequest[];
    requestParameters: TestProjectRequestsParameters;
};

export type TestProjectParams = TestProjectLoadParams &
TestProjectRequestParams &
TestProjectCommonParams & {
    loadType: TestType;
    requestCount?: number;
    totalDuration?: number;
};

export type TestSetting = {
    id: number;
    label: IntlMessage;
    name: string;
    max: number;
    min: number;
    step: number;
    tooltipText: IntlMessage;
    type?: string;
    units?: IntlMessage;
};

export type Theme = HashMap<number | string>;

/** Test project */

export type TestProjectCommonParams = {
    comment: string;
    currentVersion: string;
    projectName: string;
    projectId: string;
    runnerResources: number[];
    testType: TestType;
    versions: HashMap<string>[];
};

type TestProjectParamsByType<T, U = {}> = U & TestProjectParamsGroupsRequestsByType<T>;

type TestProjectParamsGroupsRequestsByType<T> = {
    groups: T;
    requests: TestProjectRequest[];
};

export type TestProjectCsvParameter = TestProjectDtoCsvParameterValue & {
    fileContent: string[][];
    files: HashMap<string>[];
    id: string;
    isOpened: boolean;
};

export type TestProjectData = {
    comment: string;
    currentVersion: string;
    projectName: string;
    projectId: string;
    versions: HashMap<string>[];
    yaml: string;
    testType: TestType;
};

type TestProjectRequestGroupByType<T> = T & TestProjectRequestGroupCommonParams;

export type TestProjectRequestGroupCommonParams = {
    id: string;
    index: number;
    isOpened: boolean;
    name: string;
};

export type TestProjectRequestGroup = TestProjectJMXRequestGroup & TestProjectRequestsRequestGroup;

export type TestProjectRequestParameter = {
    type: string;
    value: TestProjectDtoRequestLiteralParameter | (TestProjectDtoCsvParameter & { files: HashMap<string>[] });
};

/** Test project JMX */

export type TestProjectJMXData = TestProjectCommonParams &
TestProjectJMXParams & { requestParameters: TestProjectRequestsParameters };

export type TestProjectJMXParams = TestProjectParamsByType<
TestProjectJMXRequestGroup[],
{ JMXProfiles: HashMap<TestProjectLoadProfile> }
>;

export type TestProjectJMXRequestGroup = TestProjectRequestGroupByType<{ enabled?: boolean }>;

export type TestProjectStep<T = {}> = T &
TestProjectStepParams & {
    changed: boolean;
    id: string;
    index: number;
    name: string;
    stepName: string;
};

export type TimeLine = number[];

export type TestProjectStepParams = {
    initialDelay: number;
    rampUp: number;
    stepLength: number;
    rampDown: number;
    usersPerStep: number;
};

export type TestProjectStepWithTimeLine = TestProjectStep<{ timeLine: TimeLine }>;

/** Test project Requests */

export type TestProjectParamsGroupsRequests = TestProjectParamsGroupsRequestsByType<TestProjectRequestsRequestGroup[]>;

export type TestProjectRequestsData = TestProjectCommonParams &
TestProjectRequestsParams & { requestParameters: TestProjectRequestsParameters };

export type TestProjectRequestsParams = TestProjectParamsByType<
TestProjectRequestsRequestGroup[],
TestProjectLoadParams
>;

export type TestProjectRequestsParameters = {
    literalParameters: KeyValueParam[];
    csvParameters: TestProjectCsvParameter[];
    others: any[];
};

export type TestProjectRequestsRequestGroup = TestProjectRequestGroupByType<{ perc: number }>;

export type TestProjectLoadProfile = {
    chartData: {
        params: HashMap<string>;
        coords: PreviewDataPoint[];
    }[];
    isMergedChart: boolean;
    steps: TestProjectStep[];
    timeLine: {
        min: number;
        max: number;
        value: number[];
    };
};

export type TestProjectRequest = {
    id: string;
    groupId: string;
    requestUrl: string;
    method: string;
    body: RequestParamBody;
    headers: HashMap<string>[];
    extractResponse: ExtractResponse[];
    queryParams: HashMap<string>[];
};

/** Test project DTO */

export type TestProjectDtoCommonParams = {
    comment: string;
    contentFormat: string;
    requestCount: number;
    testType: TestType;
    // totalDuration: number;
};

type TestProjectDtoGen<T> = T & TestProjectDtoCommonParams;

export type TestProjectDto = TestProjectDtoGen<{
    content: string;
    id: number;
    lastModified: string;
    projectName: string;
    version: TestProjectVersionDto[];
}>;

export type TestProjectDtoOut = TestProjectDtoGen<{ content: string; projectName: string; totalDuration: number }>;

export type TestProjectResourceConfiguration = {
    resourceIds: number[];
};

export type TestProjectDtoContentCommonParams<T = {}, U = TestProjectDtoRequestParameterOut> = T & {
    parameters: U[];
    resourceConfiguration?: TestProjectResourceConfiguration;
    testType: TestType;
    version: string;
};

export type TestProjectDtoContentRequestGroupsJMX = { threadGroups: TestProjectDtoRequestGroupJMX[] };

export type TestProjectDtoContentJMX = TestProjectDtoContentCommonParams<
TestProjectDtoContentRequestGroupsJMX,
TestProjectDtoRequestParameter
>;

export type TestProjectDtoContentRequests = TestProjectDtoContentCommonParams<
TestProjectLoadParams & { groups: TestProjectDtoRequestGroupRequests[] }
>;

export type TestProjectDtoContent = TestProjectDtoContentJMX & TestProjectDtoContentRequests;

export type TestProjectDtoRequestGroupJMX = {
    enabled: boolean;
    id: string | number;
    requests: TestProjectDtoRequest[];
    steps: TestProjectDtoStep[];
    type: string;
};

export type TestProjectDtoRequestGroupRequests = {
    name: string;
    perc: number;
    requests: TestProjectDtoRequest[];
    runnerResources: (string | number)[];
};

export type TestProjectDtoRequestGroup = TestProjectDtoRequestGroupRequests & TestProjectDtoRequestGroupJMX;

export type TestProjectDtoRequest<T = {}> = T & {
    body: string;
    extractors: HashMap<Extractor>;
    headers: HashMap<string>;
    method: string;
    params: HashMap<string>;
    url: string;
};

type TestProjectDtoRequestParameterByType<T> = {
    type: string;
    value: T;
};

export type TestProjectDtoCsvParameterValue = {
    location: string;
    delimiter: string;
    files: HashMap<string>[];
    hasHeaders: boolean;
    independentListPerThread: boolean;
    parameterNames: string[];
    preview: string[];
    recycle: boolean;
};

type TestProjectDtoLiteralParameterValue = { name: string; content: string };

export type TestProjectDtoRequestLiteralParameter =
    TestProjectDtoRequestParameterByType<TestProjectDtoLiteralParameterValue>;

export type TestProjectDtoCsvParameter = TestProjectDtoRequestParameterByType<TestProjectDtoCsvParameterValue>;

export type TestProjectDtoRequestParameterOut = TestProjectDtoCsvParameter | TestProjectDtoRequestLiteralParameter;

export type TestProjectDtoRequestParameter = TestProjectDtoCsvParameter & TestProjectDtoRequestLiteralParameter;

export type TestProjectDtoStepSettings = {
    initDelaySec: number;
    startupTimeSec: number;
    holdLoadTimeSec: number;
    shutdownTimeSec: number;
    startThreadsCount: number;
};

export type TestProjectDtoStep = TestProjectDtoStepSettings & {
    changed?: boolean;
    name: string;
};

export type TestProjectVersionDto = TestProjectDtoGen<{
    createdOn: string;
    downloadLink: string;
    id: number;
    location: string;
}>;

/** Routing */

export type NavigationLink = {
    to: string;
} & IntlMessage;

export type AdminLink = {
    text: string;
    to: string;
};

export type NavigationRoute = {
    exact: boolean;
    path: string;
    component: ComponentType<any>;
};
