import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { media } from "../../styled/response";
const CustomRadio = ({ checked, defaultChecked, desc, name, onClick, theme, title }) => {
    const changeHandler = () => undefined;
    const clickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(title.defaultMessage);
    };
    return (React.createElement(StyledLabel, { onClick: clickHandler, theme: theme },
        React.createElement(Radio, { type: "radio", name: name, checked: checked, defaultChecked: defaultChecked, onChange: changeHandler }),
        React.createElement(StyledRadio, null),
        React.createElement(DescBlock, null,
            title && title.id ? (React.createElement(LabelTitle, null,
                React.createElement(FormattedMessage, Object.assign({}, title)))) : (title),
            desc && (React.createElement(LabelDesc, null,
                React.createElement(FormattedMessage, Object.assign({}, desc)))))));
};
const StyledLabel = styled.label `
    cursor: pointer;
    display: flex;
    ${({ theme }) => (theme ? css(theme) : css ``)}
`;
const Radio = styled.input `
    display: none;
    &:checked + i {
        &:after {
            background: #4989d6;
        }
    }
`;
const StyledRadio = styled.i `
    flex: 0 0 auto;
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid #e0e0e0;
    border-radius: 30px;
    &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
    }
`;
const DescBlock = styled.div `
    padding-left: 15px;
    user-select: none;
`;
const LabelTitle = styled.h4 `
    font-weight: 600;
    font-size: 16px;

    color: #396496;
`;
const LabelDesc = styled.p `
    font-weight: 600;
    font-size: 14px;
    color: #bdbdbd;
    max-width: 200px;
    padding-top: 5px;
    ${media.tablet} {
        max-width: 100%;
    }
`;
export default CustomRadio;
