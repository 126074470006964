import styled from "styled-components";
import { IconLoader } from "../Icons/Loader";
import { Color } from "../styled/colorPallete";
export const AutoCompleteBody = styled.div `
    position: relative;
`;
export const Loader = styled.div `
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 3px;
    top: 0;
`;
export const StyledIconLoader = styled(IconLoader) `
    fill: ${Color.blue};
    height: 16px;
    weight: 16px;
`;
