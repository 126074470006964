import { round } from "@boomq/utils";
import { AVG_RESP_TIME, ERRORS, PCT_95_RESP_TIME, THROUGHPUT, TIMELINE_PCT_95_RESP_TIME, TIMELINE_TIME, USERS } from "./constants";
import { getFullTime, getTimeOnly } from "../date";
const getRoundedValue = (value) => (value ? round(value) : 0);
const getStatsValue = (data, paramName) => (data && data[paramName] ? data[paramName] : 0);
export const getAvgRespTime = (data) => getStatsValue(data, AVG_RESP_TIME);
export const getErrors = (data) => getStatsValue(data, ERRORS);
export const getPct95RespTime = (data) => getStatsValue(data, PCT_95_RESP_TIME);
export const getThroughput = (data) => getStatsValue(data, THROUGHPUT);
export const getUsers = (data) => getStatsValue(data, USERS);
export const formatStatsResult = (result) => ({
    [AVG_RESP_TIME]: getRoundedValue(getAvgRespTime(result)),
    [ERRORS]: getRoundedValue(getErrors(result)),
    [USERS]: getRoundedValue(getUsers(result)),
    [PCT_95_RESP_TIME]: getRoundedValue(getPct95RespTime(result) / 1000),
    [THROUGHPUT]: getRoundedValue(getThroughput(result))
});
const getTimeLinePct95RespTime = (data) => round(getStatsValue(data, TIMELINE_PCT_95_RESP_TIME), 1000);
const getTimeLineTime = (data) => getStatsValue(data, TIMELINE_TIME);
export const formatStatsTime = (coordinates) => {
    if (coordinates && coordinates.length) {
        const startTime = getTimeLineTime(coordinates[0]);
        return coordinates.map((item) => {
            const time = getTimeLineTime(item);
            return Object.assign(Object.assign({}, item), { [TIMELINE_PCT_95_RESP_TIME]: getTimeLinePct95RespTime(item), [TIMELINE_TIME]: time - startTime < 3600000 ? getTimeOnly(time - startTime) : getFullTime(time - startTime) });
        });
    }
    return coordinates ? coordinates : [];
};
export const getDefaultStatsResult = () => [AVG_RESP_TIME, ERRORS, USERS, PCT_95_RESP_TIME, THROUGHPUT].reduce((res, paramName) => (Object.assign(Object.assign({}, res), { [paramName]: "-" })), {});
