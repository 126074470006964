import React from "react";
import { Color } from "@boomq/uikit";
import { chartLeftAxisLabel, chartLeftAxisLabelOrientation, chartRightAxisLabel, chartRightAxisLabelOrientation, VUsersLabel } from "./styled";
import AreaChart from "../../common/AreaChart";
import { TIMELINE_PCT_95_RESP_TIME, TIMELINE_THREADS } from "../../../helpers/testDetailsStats";
export const ResponseTimeChart = ({ chartData, chartResponseLabel, chartVUsersLabel, leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength }) => (React.createElement(AreaChart, { data: chartData, leftYAxisValMaxLength: leftYAxisResponseTimeMaxLength, rightYAxisValMaxLength: rightYAxisValMaxLength, axis: {
        x: "time",
        y: [
            Object.assign({ dataKey: TIMELINE_PCT_95_RESP_TIME, label: React.createElement("text", Object.assign({}, chartLeftAxisLabel), chartResponseLabel), unit: "s" }, chartLeftAxisLabelOrientation),
            Object.assign({ dataKey: TIMELINE_THREADS, label: React.createElement(VUsersLabel, Object.assign({}, chartRightAxisLabel), chartVUsersLabel) }, chartRightAxisLabelOrientation)
        ]
    }, areas: [
        {
            dataKey: TIMELINE_PCT_95_RESP_TIME,
            fill: "#B6D3FF",
            fillOpacity: 0.6,
            name: chartResponseLabel,
            stroke: "#B6D3FF",
            type: "monotone",
            yAxisId: "left"
        },
        {
            dataKey: TIMELINE_THREADS,
            fillOpacity: 0,
            name: chartVUsersLabel,
            stroke: Color.blue,
            strokeWidth: 3,
            type: "linear",
            yAxisId: "right"
        }
    ] }));
