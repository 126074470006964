import styled, { css } from "styled-components";
export const getZIndex = (value) => (zIndex[value] ? zIndex[value] : 1);
export const Link = styled.span `
    color: #4989d6;
    cursor: pointer;
    ${(props) => css(props.theme)}
`;
export const withDisable = css `
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;
export const withShadow = css `
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
`;
export const withTruncatedText = css `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const hoverWithShadow = css `
    &:active {
        background: #f6f6f6;
        transform: scale(0.98);
    }
    &:hover {
        box-shadow: 0 0 10px rgba(0, 64, 140, 0.35);
    }
`;
export const scaleActive = css `
    transition: transform 0.3s ease-in-out;
    &:active {
        transform: scale(0.9);
    }
`;
const zIndex = {
    ActionsPanel: 90,
    CustomSelectBody: 101,
    CustomSelectClosedIcon: 99,
    CustomSelectOpenedIcon: 102,
    CustomSelectUl: 100,
    Loader: 999999,
    RequestGrpcFormTlsToggler: 2,
    Sidebar: 99,
    TestRunProgressBar: 1,
    TestRunStatus: 2
};
