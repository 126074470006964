import { call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
    getSelectedTeamIdFromLocalStorageByUserId,
    getTeam,
    getTeamsRoute,
    getUserTeams,
    hideLoader,
    isEmpty,
    redirectToTeamsRoute,
    showLoader,
    teamSelectionFlow
} from "@boomq/common";

function* teamSelection(action) {
    yield* teamSelectionFlow(action);

    yield put(hideLoader(5));
}

function* checkTeamsByCount() {
    try {
        const {
            data: { content }
        } = yield call(getUserTeams, { size: 2 });

        return content.length === 1 ? yield* teamSelection({ payload: content[0] }) : yield* redirectToTeamsRoute();
    } catch (e) {
        yield put(push(getTeamsRoute()));
    }
}

function* selectTeamById(teamId) {
    try {
        const { data } = yield call(getTeam, teamId);

        return yield* teamSelection({ payload: data });
    } catch (e) {
        yield* checkTeamsByCount();
    }
}

export function* checkTeams(userId) {
    try {
        yield put(showLoader(5));

        const localStorageSelectedTeamId = getSelectedTeamIdFromLocalStorageByUserId(userId);

        return isEmpty(localStorageSelectedTeamId)
            ? yield* checkTeamsByCount()
            : yield* selectTeamById(localStorageSelectedTeamId);
    } catch (e) {
        yield* checkTeamsByCount();
    }
}
