var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import authorizeActions from "../actions/authorize";
import { hideLoader } from "../actions/loader";
import { clearTeamData, teamFailure, teamRequest, teamSuccess, teamSavingFailure, teamSavingRequest, teamSavingSuccess } from "../actions/team";
import { selectTeamFailure, selectTeamRequest, selectTeamSuccess, setPermissionList, setSelectedTeam } from "../actions/teams";
import { getClientInfo, getClientPermissionList, getSelectedTeamData, getSelectedTeamId } from "../reducers/authorizePlate";
import { getTeamMemberList } from "../reducers/teamMembers";
import { convertDataUrlToFile } from "../../helpers/convert";
import { getResponseErrorData } from "../../helpers/errors";
import { getNewTestRoute, getTeamsRoute, NEW_ITEM_ID } from "../../helpers/routes";
import { addTeamIdToLocalStorageSelectedTeamData, generateNewTeamDtoParams, generateTeamDtoParams } from "../../helpers/teams";
import { isEmpty } from "../../helpers/utils";
import { clearTeamTokenApi, createTeam, getTeam, getTeamMemberPermissionsWithToken, setTeamTokenApi, setTokenApi, updateTeam } from "../../services/authSrv";
const { secretTokenSuccess } = authorizeActions;
export function* clearTeamDataFlow() {
    yield put(clearTeamData());
    yield put(hideLoader());
}
function* clearTeamTokenApiFlow() {
    yield call(clearTeamTokenApi);
}
function* getTeamContextDataFromApi(teamId) {
    const { headers, data } = isEmpty(teamId) ? {} : yield call(getTeamMemberPermissionsWithToken, teamId);
    const { authorization } = headers || {};
    return { teamToken: authorization, permissionList: data ? data.permissionList : [] };
}
function* getTeamContextDataFromStore() {
    const permissionList = yield select(getClientPermissionList);
    const { teamToken } = yield select(getSelectedTeamData);
    return { permissionList, teamToken };
}
function* getTeamContextData(teamId) {
    const { id } = yield select(getSelectedTeamData);
    return Number(teamId) === Number(id)
        ? yield* getTeamContextDataFromStore()
        : yield* getTeamContextDataFromApi(teamId);
}
function* setSelectedTeamToLocalStorage(teamId) {
    try {
        const { id } = yield select(getClientInfo);
        return isEmpty(id) ? undefined : addTeamIdToLocalStorageSelectedTeamData(id, teamId);
    }
    catch (e) {
        console.error(e);
    }
}
function* setSelectedTeamData(team) {
    const { id, icon, teamName } = team || {};
    const { teamToken, permissionList } = yield* getTeamContextDataFromApi(id);
    yield call(setTokenApi, teamToken);
    yield put(setPermissionList(permissionList));
    yield put(setSelectedTeam({ id, icon, teamName, teamToken }));
    yield* setSelectedTeamToLocalStorage(id);
}
function* selectTeamFlow(team) {
    const { id } = team || {};
    const selectedTeamId = yield select(getSelectedTeamId);
    return selectedTeamId === id ? undefined : yield* setSelectedTeamData(team);
}
function* checkSelectedTeamByTeamUpdating(params) {
    const { id } = params || {};
    const selectedTeamId = yield select(getSelectedTeamId);
    return Number(id) === selectedTeamId ? yield* setSelectedTeamData(params) : undefined;
}
export function* redirectToTeamsRoute() {
    try {
        yield put(hideLoader(5));
        yield put(push(getTeamsRoute()));
    }
    catch (e) {
        return;
    }
}
export function* teamSelectionFlow(action) {
    try {
        const team = action && action.payload ? action.payload : {};
        yield* selectTeamFlow(team);
        yield put(secretTokenSuccess(""));
        yield put(selectTeamSuccess());
    }
    catch (e) {
        yield put(selectTeamFailure(getResponseErrorData(e)));
    }
}
function* sameTeamSelection() {
    yield call(clearTeamTokenApi);
    yield put(push(getNewTestRoute()));
    yield put(hideLoader());
}
function* otherTeamSelection(action) {
    yield* teamSelectionFlow(action);
    yield* sameTeamSelection();
}
function* teamSelectionWithRedirectFlow(action) {
    const { id } = action && action.payload ? action.payload : {};
    const selectedTeamId = yield select(getSelectedTeamId);
    return Number(id) === selectedTeamId ? yield* sameTeamSelection() : yield* otherTeamSelection(action);
}
function* refreshTeamData(id) {
    try {
        const { data } = yield call(getTeam, id);
        const { teamToken, permissionList } = yield* getTeamContextData(id);
        yield call(setTeamTokenApi, teamToken);
        yield put(teamSuccess(Object.assign(Object.assign({}, data), { id, permissionList })));
    }
    catch (e) {
        yield put(teamFailure(getResponseErrorData(e)));
    }
}
function* teamGettingDataFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        return id === NEW_ITEM_ID ? yield* clearTeamDataFlow() : yield* refreshTeamData(id);
    }
    catch (e) {
        yield put(teamFailure(getResponseErrorData(e)));
    }
}
export function* createNewTeam(teamCreatingFunc, params) {
    const members = yield select(getTeamMemberList, NEW_ITEM_ID);
    yield call(teamCreatingFunc, generateNewTeamDtoParams(Object.assign(Object.assign({}, params), { members })));
    yield put(teamSavingSuccess());
    yield put(push(getTeamsRoute()));
}
function* createNewUserTeam(params) {
    yield* createNewTeam(createTeam, params);
}
export function* updateExistTeam(teamUpdatingFunc, params) {
    const members = yield select(getTeamMemberList, params.id);
    const image = typeof params.image === "string" ? convertDataUrlToFile(params.image) : params.image;
    const { data } = yield call(teamUpdatingFunc, generateTeamDtoParams(Object.assign(Object.assign({}, params), { image, members })));
    yield put(teamSavingSuccess());
    yield* checkSelectedTeamByTeamUpdating(data);
}
function* updateExistUserTeam(params) {
    yield* updateExistTeam(updateTeam, params);
}
function* teamSavingFlow(action) {
    try {
        const _a = action && action.payload ? action.payload : {}, { id } = _a, restParams = __rest(_a, ["id"]);
        return id === NEW_ITEM_ID ? yield* createNewUserTeam(restParams) : yield* updateExistUserTeam(action.payload);
    }
    catch (e) {
        yield put(teamSavingFailure(getResponseErrorData(e)));
    }
}
export function* teamFlow() {
    yield takeEvery(clearTeamData, clearTeamTokenApiFlow);
    yield takeEvery(selectTeamRequest, teamSelectionWithRedirectFlow);
    yield takeEvery(teamRequest, teamGettingDataFlow);
    yield takeEvery(teamSavingRequest, teamSavingFlow);
}
