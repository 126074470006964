var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertHoursToSec, getUnique } from "@boomq/utils";
import { TimeUnit } from "../../../models/date";
import { ReportChartAggregationInterval, ReportChartType } from "../../../models/reportEditor/enums";
const aggregationIntervalByTimeUnit = {
    [ReportChartAggregationInterval.Seconds5]: 5,
    [ReportChartAggregationInterval.Seconds10]: 10
};
const aggregationIntervalTimeUnitByValue = {
    [ReportChartAggregationInterval.Seconds5]: TimeUnit.Second,
    [ReportChartAggregationInterval.Seconds10]: TimeUnit.Second
};
export const getAggregationIntervalByTimeUnit = (aggregationInterval, aggregationIntervalTimeUnit) => aggregationIntervalTimeUnit in aggregationIntervalByTimeUnit &&
    aggregationInterval < aggregationIntervalByTimeUnit[aggregationIntervalTimeUnit]
    ? aggregationIntervalByTimeUnit[aggregationIntervalTimeUnit]
    : aggregationInterval;
const getAggregationIntervalTimeUnitByValue = (timeUnit) => timeUnit in aggregationIntervalTimeUnitByValue ? aggregationIntervalTimeUnitByValue[timeUnit] : timeUnit;
export const reportChartSerieAggregationIntervalDataByTimeUnit = ({ aggregationInterval, aggregationIntervalTimeUnit }) => ({
    aggregationInterval: getAggregationIntervalByTimeUnit(aggregationInterval, aggregationIntervalTimeUnit),
    aggregationIntervalTimeUnit: getAggregationIntervalTimeUnitByValue(aggregationIntervalTimeUnit)
});
const formatPoint = ({ timeAbsolute, timeRelativeHour, value }) => ({
    timeAbsolute,
    timeRelativeSec: convertHoursToSec(timeRelativeHour),
    value
});
const formatPointForSysMetricInBytes = (_a) => {
    var { valueInBytes } = _a, restParams = __rest(_a, ["valueInBytes"]);
    return formatPoint(Object.assign(Object.assign({}, restParams), { value: valueInBytes }));
};
const reportChartSerieCommonDataGenerator = (_a) => {
    var { id, displayProps, formatPointFunc, legend, points, testId, type } = _a, restData = __rest(_a, ["id", "displayProps", "formatPointFunc", "legend", "points", "testId", "type"]);
    return (Object.assign(Object.assign({}, reportChartSerieAggregationIntervalDataByTimeUnit(restData)), { id, datasets: [{ legend, points: (points || []).map(formatPointFunc) }], displayProps,
        testId,
        type }));
};
const reportChartSerieLoadProfileGenerator = (_a) => {
    var { metricType } = _a, restData = __rest(_a, ["metricType"]);
    return (Object.assign(Object.assign({}, reportChartSerieCommonDataGenerator(Object.assign(Object.assign({}, restData), { formatPointFunc: formatPoint }))), { vuserType: metricType }));
};
const reportChartSerieSystemMetricGenerator = (_a) => {
    var { hostName, metricType } = _a, restData = __rest(_a, ["hostName", "metricType"]);
    const formatPointFunc = metricType.includes("_BYTES") ? formatPointForSysMetricInBytes : formatPoint;
    return Object.assign(Object.assign({}, reportChartSerieCommonDataGenerator(Object.assign(Object.assign({}, restData), { formatPointFunc }))), { metricType, systemMetricFilter: {
            hostName
        } });
};
const reportChartSerieTestMetricGenerator = (_a) => {
    var { metricType } = _a, restData = __rest(_a, ["metricType"]);
    return (Object.assign(Object.assign({}, reportChartSerieCommonDataGenerator(Object.assign(Object.assign({}, restData), { formatPointFunc: formatPoint }))), { metricType }));
};
const reportChartSerieTransactionMetricGenerator = (_a) => {
    var { metricType, transactionFilter } = _a, restData = __rest(_a, ["metricType", "transactionFilter"]);
    return (Object.assign(Object.assign({}, reportChartSerieCommonDataGenerator(Object.assign(Object.assign({}, restData), { formatPointFunc: formatPoint }))), { metricType,
        transactionFilter }));
};
const reportChartSerieGeneratorByChartType = {
    [ReportChartType.LoadProfile]: reportChartSerieLoadProfileGenerator,
    [ReportChartType.SystemMetric]: reportChartSerieSystemMetricGenerator,
    [ReportChartType.TestMetric]: reportChartSerieTestMetricGenerator,
    [ReportChartType.TransactionMetric]: reportChartSerieTransactionMetricGenerator
};
export const generateReportChartSerie = (data) => (data === null || data === void 0 ? void 0 : data.type) in reportChartSerieGeneratorByChartType
    ? reportChartSerieGeneratorByChartType[data.type](data)
    : reportChartSerieCommonDataGenerator(data);
const generateReportChartSeriesForSaving = (chartId, series) => (series || []).filter((serieData) => serieData.chartId === chartId).map(generateReportChartSerie);
const generateReportChartsForSaving = (charts, series) => (charts || []).reduce((res, { id, axisLeftY, axisRightY, axisLeftYLabel, axisRightYLabel, axisXLabel, title }) => {
    const generatedSeries = generateReportChartSeriesForSaving(id, series);
    const testIds = getUnique(generatedSeries.map(({ testId }) => testId));
    return {
        reportCharts: [
            ...res.reportCharts,
            {
                id,
                displayProps: { axisLeftY, axisRightY, axisLeftYLabel, axisRightYLabel, axisXLabel },
                name: title,
                testFilters: testIds.map((testId) => ({ testId })),
                series: generatedSeries
            }
        ],
        testIdSet: [...res.testIdSet, ...testIds]
    };
}, {
    reportCharts: [],
    testIdSet: []
});
const generateReportTablesForSaving = (tables) => (tables || []).reduce((res, { id, testId, title, type }) => ({
    reportTables: [...res.reportTables, { id, name: title, type, testFilters: [{ testId }] }],
    testIdSet: [...res.testIdSet, testId]
}), {
    reportTables: [],
    testIdSet: []
});
export const generateReportDataForSaving = ({ blocks, charts, reportName, series, tables }) => {
    const { reportCharts, testIdSet: testIdSetFromCharts } = generateReportChartsForSaving(charts, series);
    const { reportTables, testIdSet: testIdSetFromTables } = generateReportTablesForSaving(tables);
    return {
        labelSet: [],
        name: reportName,
        testIdSet: getUnique([...testIdSetFromCharts, ...testIdSetFromTables]),
        reportContent: {
            charts: reportCharts,
            reportMarkup: JSON.stringify(blocks),
            tables: reportTables
        }
    };
};
