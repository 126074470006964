var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { compareByParams, getUniqueArrayByParam } from "@boomq/utils";
import { DEFAULT_AGGREGATION_INTERVAL_VALUE, PFLB_CHART_SYSTEM_METRIC_TITLE_TEMPLATE, PFLB_CHART_TITLE_TEMPLATE, PFLB_CHART_TRANSACTION_METRIC_TITLE_TEMPLATE, REPORT_CHART_SERIE_PARAM_NAME_LIST } from "./constants";
import { getAggregationIntervalByTimeUnit } from "../generators/report";
import { getThreadGroupElementControllerType } from "../threadGroup";
import { TestType } from "../types";
import { getReportChartMetricTypeItemsByChartType } from "../../models/reportEditor";
import { ControllerType } from "../../models/threadGroupEditor";
const generateReportChartMetricTypeItem = (_a, formatMessage) => {
    var { metricIntlMessage, unitIntlMessage } = _a, restParams = __rest(_a, ["metricIntlMessage", "unitIntlMessage"]);
    const metricLabel = formatMessage(metricIntlMessage);
    const metricTypeItemData = Object.assign(Object.assign({}, restParams), { metricLabel, text: metricLabel });
    try {
        const unitLabel = formatMessage(unitIntlMessage);
        return Object.assign(Object.assign({}, metricTypeItemData), { text: !unitLabel || unitLabel === restParams.value ? metricLabel : `${metricLabel}, ${unitLabel}`, unitLabel });
    }
    catch (e) {
        return metricTypeItemData;
    }
};
export const getFormattedReportChartMetricTypeItemsByChartType = (type, formatMessage) => getReportChartMetricTypeItemsByChartType(type).map((metricTypeItem) => generateReportChartMetricTypeItem(metricTypeItem, formatMessage));
export const generateReportChartTitle = ({ chartTypeItems, metricType, metricTypeItems, testId, type }, formatMessage) => formatMessage === null || formatMessage === void 0 ? void 0 : formatMessage(PFLB_CHART_TITLE_TEMPLATE, {
    metricTypeName: getItemMetricLabelFromListByValue(metricTypeItems, metricType),
    testId,
    typeName: getItemTextFromListByValue(chartTypeItems, type)
});
export const generateReportChartSystemMetricTitle = ({ chartTitle, hostName }, formatMessage) => formatMessage === null || formatMessage === void 0 ? void 0 : formatMessage(PFLB_CHART_SYSTEM_METRIC_TITLE_TEMPLATE, { reportChartCommonTitle: chartTitle, hostName });
export const generateReportChartTransactionMetricTitle = ({ chartTitle, threadGroupName, transactionName }, formatMessage) => formatMessage === null || formatMessage === void 0 ? void 0 : formatMessage(PFLB_CHART_TRANSACTION_METRIC_TITLE_TEMPLATE, {
    reportChartCommonTitle: chartTitle,
    threadGroupName,
    transactionName
});
export const getItemByValue = (itemList, value) => {
    const item = (itemList || []).find((item) => item.value === value);
    return item !== null && item !== void 0 ? item : {};
};
const getItemMetricLabelFromListByValue = (itemList, value) => {
    const item = getItemByValue(itemList, value);
    return item === null || item === void 0 ? void 0 : item.metricLabel;
};
export const getItemTextFromListByValue = (itemList, value) => {
    const item = getItemByValue(itemList, value);
    return item === null || item === void 0 ? void 0 : item.text;
};
export const isExistIdenticalChartSerie = (existedChartSerie, chartSerie) => compareByParams(existedChartSerie, Object.assign(Object.assign({}, chartSerie), { aggregationInterval: getAggregationIntervalByTimeUnit(chartSerie.aggregationInterval || DEFAULT_AGGREGATION_INTERVAL_VALUE, chartSerie.aggregationIntervalTimeUnit) }), REPORT_CHART_SERIE_PARAM_NAME_LIST);
export const prepareReportChartGroupListFromVersionData = (groups) => (groups || []).map(({ id, name }) => ({ id, text: name, value: name }));
const prepareReportChartTransactionListFromStableScalability = ({ elementsData }) => Object.keys(elementsData)
    .filter((elementId) => getThreadGroupElementControllerType(elementsData[elementId]) === ControllerType.Transaction)
    .map((elementId) => {
    var _a, _b, _c;
    return ({
        id: elementId,
        groupId: (_a = elementsData === null || elementsData === void 0 ? void 0 : elementsData[elementId]) === null || _a === void 0 ? void 0 : _a.groupId,
        text: (_b = elementsData === null || elementsData === void 0 ? void 0 : elementsData[elementId]) === null || _b === void 0 ? void 0 : _b.name,
        value: (_c = elementsData === null || elementsData === void 0 ? void 0 : elementsData[elementId]) === null || _c === void 0 ? void 0 : _c.name
    });
});
const prepareReportChartTransactionListFromJMX = ({ transactions }) => (transactions || []).map(({ id, groupId, name }) => ({
    groupId,
    id,
    text: name,
    value: name
}));
const transactionListGetterFuncByTestType = {
    [TestType.JMX]: prepareReportChartTransactionListFromJMX,
    [TestType.Scalability]: prepareReportChartTransactionListFromStableScalability,
    [TestType.Stable]: prepareReportChartTransactionListFromStableScalability
};
export const prepareReportChartTransactionListByTestType = (_a) => {
    var _b;
    var { testType } = _a, restData = __rest(_a, ["testType"]);
    const transactionList = Object.keys(transactionListGetterFuncByTestType).includes(testType)
        ? (_b = transactionListGetterFuncByTestType[testType]) === null || _b === void 0 ? void 0 : _b.call(transactionListGetterFuncByTestType, restData)
        : [];
    return getUniqueArrayByParam(transactionList, "text").map((transactionItem) => (Object.assign(Object.assign({}, transactionItem), { groupIds: transactionList.filter(({ text }) => text === transactionItem.text).map(({ groupId }) => groupId) })));
};
