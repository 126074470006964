var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUniqueId, useIntl } from "@boomq/utils";
import { CHART_SERIE_PARAMS_HEADER, CHART_SETTINGS_HEADER, NEW_CHART_SERIE_BUTTON_LABEL } from "./intl";
import { axisLeftYEditableLabelTheme, axisRightYEditableLabelTheme, axisXEditableLabelTheme, ChartHeader, ChartPreviewWrapper, StyledIconSettings } from "./styled";
import { PFLBChartPreview } from "../../../../components/account/Report/PFLBChartPreview";
import { PFLBChartSettings } from "../../../../components/account/Report/PFLBChartSettings";
import { EditableTitle } from "../../../../components/common/EditableTitle";
import { getTicks, getTicksByUnit } from "../../../../helpers/chart";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getReportChartAggregationTimeIntervalItems, getReportChartTypeItems } from "../../../../models/reportEditor";
import { addReportPflbChart, addReportPflbChartSerie, changeReportPflbChartProperties, deleteReportPflbChartSerie } from "../../../../redux/actions/report";
import { getReportChartDataById, getReportChartLegend, getReportChartSeriesList, getReportTestList } from "../../../../redux/reducers/report";
const connector = connect((state, ownProps) => ({
    chartData: getReportChartDataById(state, ownProps.id),
    chartLegend: getReportChartLegend(state, ownProps.id),
    chartSeries: getReportChartSeriesList(state, ownProps.id),
    reportChartAggregationIntervalItems: getReportChartAggregationTimeIntervalItems(),
    reportChartTypeItems: getReportChartTypeItems(),
    testList: getReportTestList(state)
}), {
    onAddSerie: addReportPflbChartSerie,
    onChangeProperty: changeReportPflbChartProperties,
    onClickDeleteChartSerie: deleteReportPflbChartSerie,
    onMount: addReportPflbChart
});
const getAxisYData = (orientation, key) => ({
    dataKey: key,
    orientation,
    yAxisId: `${orientation}_${key}`
});
const getAxisYTicks = (data) => getTicks({ data, axisDataKey: "value", pointsDataKey: "points" });
const getAxisYTicksByUnit = (data, unit, unitLabel = "") => getTicksByUnit({ data, axisDataKey: `value${unit}`, pointsDataKey: "points", unit: unitLabel });
const PFLBChartContainer = (_a) => {
    var { chartData, chartLegend, chartSeries, chartTitle, id, isShowParamsSidebar, onAddSerie, onChangeProperty, onClickDeleteChartSerie, onMount, onToggleChartParamsSidebar, onUnmount, testList } = _a, restProps = __rest(_a, ["chartData", "chartLegend", "chartSeries", "chartTitle", "id", "isShowParamsSidebar", "onAddSerie", "onChangeProperty", "onClickDeleteChartSerie", "onMount", "onToggleChartParamsSidebar", "onUnmount", "testList"]);
    const [activeIndexes, setActiveIndexes] = useState([]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        onMount(id);
    }, []);
    const chartTypeItems = restProps.reportChartTypeItems.map((_a) => {
        var { intlMessage } = _a, restParams = __rest(_a, ["intlMessage"]);
        return (Object.assign(Object.assign({}, restParams), { text: formatMessage(intlMessage) }));
    });
    const aggregationIntervalItems = restProps.reportChartAggregationIntervalItems.map((_a) => {
        var { intlMessage } = _a, restParams = __rest(_a, ["intlMessage"]);
        return (Object.assign(Object.assign({}, restParams), { text: formatMessage(intlMessage) }));
    });
    const chartSerieParamsHeader = formatIntlMessage(CHART_SERIE_PARAMS_HEADER, formatMessage);
    const chartSettingsHeader = formatIntlMessage(CHART_SETTINGS_HEADER, formatMessage);
    const newChartSerieButtonLabel = formatIntlMessage(NEW_CHART_SERIE_BUTTON_LABEL, formatMessage);
    const addActiveIndex = (serieId) => setActiveIndexes([...activeIndexes, serieId]);
    const deleteActiveIndex = (serieId) => setActiveIndexes(activeIndexes.filter((activeIndex) => activeIndex !== serieId));
    const changeCommonSettingHandler = (settingPropertyData) => onChangeProperty({ id, properties: settingPropertyData });
    const changeAxisLeftYLabelHandler = (value) => changeCommonSettingHandler({ axisLeftYLabel: value });
    const changeAxisRightYLabelHandler = (value) => changeCommonSettingHandler({ axisRightYLabel: value });
    const changeAxisXLabelHandler = (value) => changeCommonSettingHandler({ axisXLabel: value });
    const changeChartTitleHandler = (value) => onChangeProperty({ id, properties: { title: value, isTitleTyped: true } });
    const clickAddChartHandler = () => {
        const serieId = getUniqueId();
        setActiveIndexes([...activeIndexes, serieId]);
        return onAddSerie({ chartId: id, id: serieId });
    };
    const toggleChartSerieHandler = (serieId) => activeIndexes.includes(serieId) ? deleteActiveIndex(serieId) : addActiveIndex(serieId);
    const toggleSidebarHandler = () => typeof onToggleChartParamsSidebar === "function" ? onToggleChartParamsSidebar() : undefined;
    const getAxesYWithUnit = () => {
        const { axisLeftY, axisRightY } = chartData;
        const axisLeftYData = axisLeftY
            ? [
                Object.assign(Object.assign({}, getAxisYData("left", `value${axisLeftY}`)), { ticks: getAxisYTicksByUnit(chartSeries, axisLeftY) })
            ]
            : [];
        return axisRightY
            ? [
                ...axisLeftYData,
                Object.assign(Object.assign({}, getAxisYData("right", `value${axisRightY}`)), { ticks: getAxisYTicksByUnit(chartSeries, axisRightY) })
            ]
            : axisLeftYData;
    };
    const getYAxes = () => {
        const { axisLeftY, axisRightY } = chartData;
        return !axisLeftY && !axisRightY
            ? [Object.assign(Object.assign({}, getAxisYData("left", "", "")), { ticks: getAxisYTicks(chartSeries) })]
            : getAxesYWithUnit();
    };
    const { axisYItemList } = chartSeries
        .filter(({ displayProps }) => !!(displayProps === null || displayProps === void 0 ? void 0 : displayProps.unit))
        .map(({ displayProps, metricType, type }) => ({
        id: `${type}_${metricType}`,
        text: formatMessage({
            defaultMessage: displayProps === null || displayProps === void 0 ? void 0 : displayProps.unit,
            id: `account.report.editor.chart.axis.unit.${displayProps === null || displayProps === void 0 ? void 0 : displayProps.unit}.label`
        }),
        value: displayProps === null || displayProps === void 0 ? void 0 : displayProps.unit
    }))
        .reduce((res, item) => res.units.includes(item.value)
        ? res
        : { axisYItemList: [...res.axisYItemList, item], units: [...res.units, item.value] }, { axisYItemList: [], units: [] });
    const chartSerieAxisItems = axisYItemList.filter(({ value }) => [chartData.axisLeftY, chartData.axisRightY].includes(value));
    const tooltipMetricsLabels = chartSeries.reduce((res, { metricType }) => metricType
        ? Object.assign(Object.assign({}, res), { [metricType]: formatMessage({
                defaultMessage: metricType,
                id: `account.report.editor.chart.unit.${metricType}.tooltip`
            }) }) : res, {});
    return (React.createElement(React.Fragment, null,
        React.createElement(ChartHeader, { className: "chart-header" },
            React.createElement(StyledIconSettings, { className: "settings-button", onClick: toggleSidebarHandler }),
            React.createElement(EditableTitle, { onBlur: changeChartTitleHandler, value: chartData.title })),
        React.createElement(ChartPreviewWrapper, { className: "chart-preview-wrapper" },
            React.createElement(EditableTitle, { className: "axis-left-y-label", editModeTheme: axisLeftYEditableLabelTheme, onBlur: changeAxisLeftYLabelHandler, theme: axisLeftYEditableLabelTheme, value: chartData.axisLeftYLabel }),
            React.createElement(PFLBChartPreview, { data: chartSeries, displayPropsKey: "displayProps", isShowLegend: true, legendPayload: chartLegend, pointsDataKey: "points", tooltipMetricsLabels: tooltipMetricsLabels, xAxisDataKey: "timeRelativeHour", yAxes: getYAxes() }),
            React.createElement(EditableTitle, { className: "axis-x-label", editModeTheme: axisXEditableLabelTheme, onBlur: changeAxisXLabelHandler, theme: axisXEditableLabelTheme, value: chartData.axisXLabel }),
            React.createElement(EditableTitle, { className: "axis-right-y-label", editModeTheme: axisRightYEditableLabelTheme, onBlur: changeAxisRightYLabelHandler, theme: axisRightYEditableLabelTheme, value: chartData.axisRightYLabel })),
        React.createElement(PFLBChartSettings, { activeChartSerieIndexes: activeIndexes, aggregationIntervalItems: aggregationIntervalItems, axisLeftYItemList: axisYItemList, axisRightYItemList: axisYItemList, chartData: chartData, chartSerieAxisItems: chartSerieAxisItems, chartSerieParamsHeader: chartSerieParamsHeader, chartSeries: chartSeries, chartSettingsHeader: chartSettingsHeader, chartTypeItems: chartTypeItems, isShowParamsSidebar: isShowParamsSidebar, newChartSerieButtonLabel: newChartSerieButtonLabel, onChangeCommonSetting: changeCommonSettingHandler, onClickAddChartSerie: clickAddChartHandler, onClickChartSerieHeader: toggleChartSerieHandler, onClickDeleteChartSerie: onClickDeleteChartSerie, onClickSidebarCloseIcon: toggleSidebarHandler, testList: testList })));
};
export default connector(PFLBChartContainer);
