var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty, useIntl } from "@boomq/utils";
import { APPLY_BUTTON_LABEL, PFLB_TABLE_TITLE_TEMPLATE, TABLE_TYPE_SELECT_LABEL, TABLE_TYPE_SELECT_PLACEHOLDER, TEST_ID_SELECT_LABEL, TEST_ID_SELECT_PLACEHOLDER } from "./intl";
import { PFLBTableSettings } from "../../../../components/account/Report/PFLBTableSettings";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getReportTableTypeItems } from "../../../../models/reportEditor";
import { changeReportPflbTableParams, generateReportPflbTableRequest } from "../../../../redux/actions/report";
import { getReportLastSelectedTestId, getReportTableDataById, getReportTestList } from "../../../../redux/reducers/report";
const connector = connect((state, ownProps) => ({
    defaultSelectedTestId: getReportLastSelectedTestId(state),
    reportTableTypeItems: getReportTableTypeItems(),
    reportTable: getReportTableDataById(state, ownProps.id),
    testList: getReportTestList(state)
}), {
    onApply: generateReportPflbTableRequest,
    onChangeTableParams: changeReportPflbTableParams
});
const getTableTypeStateFromData = (tableData) => tableData === null || tableData === void 0 ? void 0 : tableData.type;
const getTableTestIdStateFromData = (tableData, defaultSelectedTestId) => { var _a; return (_a = tableData === null || tableData === void 0 ? void 0 : tableData.testId) !== null && _a !== void 0 ? _a : defaultSelectedTestId; };
const PFLBTableContainer = ({ defaultSelectedTestId, id, isShowParamsSidebar, onApply, onChangeTableParams, onRefresh, onToggleTableParamsSidebar, reportTableTypeItems, reportTable, testList }) => {
    var _a;
    const [testId, setTestId] = useState(getTableTestIdStateFromData(reportTable, defaultSelectedTestId));
    const [type, setType] = useState(getTableTypeStateFromData(reportTable));
    const [typeName, setTypeName] = useState("");
    const { formatMessage } = useIntl();
    useEffect(() => {
        const setTableParams = (reportTable) => {
            setType(getTableTypeStateFromData(reportTable));
            setTestId(getTableTestIdStateFromData(reportTable));
        };
        const checkReportTableChanging = (reportTable) => isEmpty(reportTable) ? undefined : setTableParams(reportTable);
        const checkTableDataChanging = (reportTable) => (isEmpty(reportTable) ? undefined : onRefresh(reportTable));
        checkReportTableChanging(reportTable);
        checkTableDataChanging(reportTable);
    }, [reportTable]);
    const tableTypeItems = (_a = reportTableTypeItems === null || reportTableTypeItems === void 0 ? void 0 : reportTableTypeItems.map((_a) => {
        var { intlMessage } = _a, restParams = __rest(_a, ["intlMessage"]);
        return (Object.assign(Object.assign({}, restParams), { text: formatMessage(intlMessage) }));
    })) !== null && _a !== void 0 ? _a : [];
    const changeTableTitleHandler = (value) => onChangeTableParams({ id, title: value, isTitleTyped: true });
    const changeTableTypeHandler = (data) => {
        setTypeName(data.text);
        setType(data.value);
    };
    const changeTestIdHandler = (data) => setTestId(data.value);
    const clickApplyHandler = () => onApply === null || onApply === void 0 ? void 0 : onApply({
        tableId: id,
        tableTitle: formatMessage(PFLB_TABLE_TITLE_TEMPLATE, { testId, typeName }),
        testId,
        type
    });
    const toggleSidebarHandler = () => typeof onToggleTableParamsSidebar === "function" ? onToggleTableParamsSidebar() : undefined;
    const applyButtonLabel = formatIntlMessage(APPLY_BUTTON_LABEL, formatMessage);
    const tableTypeSelectLabel = formatIntlMessage(TABLE_TYPE_SELECT_LABEL, formatMessage);
    const tableTypeSelectPlaceholder = formatIntlMessage(TABLE_TYPE_SELECT_PLACEHOLDER, formatMessage);
    const testIdSelectLabel = formatIntlMessage(TEST_ID_SELECT_LABEL, formatMessage);
    const testIdSelectPlaceholder = formatIntlMessage(TEST_ID_SELECT_PLACEHOLDER, formatMessage);
    return (React.createElement(PFLBTableSettings, { applyButtonLabel: applyButtonLabel, isShowParamsSidebar: isShowParamsSidebar, onChangeTableTitle: changeTableTitleHandler, onChangeTableType: changeTableTypeHandler, onChangeTestId: changeTestIdHandler, onClickApply: clickApplyHandler, onClickSidebarCloseIcon: toggleSidebarHandler, onToggleSidebar: toggleSidebarHandler, tableTitle: reportTable.title, tableType: type, tableTypeItems: tableTypeItems, tableTypeSelectLabel: tableTypeSelectLabel, tableTypeSelectPlaceholder: tableTypeSelectPlaceholder, testId: testId, testIdSelectLabel: testIdSelectLabel, testIdSelectPlaceholder: testIdSelectPlaceholder, testList: testList }));
};
export default connector(PFLBTableContainer);
