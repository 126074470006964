var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { getMaxInArray, isEmpty, round } from "@boomq/utils";
import { GRAPH_DATA_TIMEOUT_MS } from "./constants";
import { TEST_DETAILS_CHART_ERRORS_LABEL, TEST_DETAILS_CHART_REQUESTS_ERRORS_LABEL, TEST_DETAILS_CHART_REQUESTS_LABEL, TEST_DETAILS_CHART_RESPONSE_LABEL, TEST_DETAILS_CHART_VUSERS_LABEL } from "./intl";
import { TestDetailsBlocks } from "../../../../components/account/TestDetailsBlocks";
import { withResize } from "../../../../components/styled/response";
import { formatIntlMessage } from "../../../../helpers/intl";
import { TIMELINE_ERRORS, TIMELINE_PCT_95_RESP_TIME, TIMELINE_THREADS, TIMELINE_THROUGHPUT } from "../../../../helpers/testDetailsStats";
import { isTestCompleted, isTestDuringExecution } from "../../../../helpers/testStatus";
import { getModal, closeModal } from "../../../../redux/actions/modal";
import { getGraphData, testResultLogFileDownloadLinkRequest, testResultLogFilesRequestStop } from "../../../../redux/actions/testDetails";
import { getTestDetailsData } from "../../../../redux/reducers/testDetails";
const connector = connect((state) => ({
    testDetails: getTestDetailsData(state)
}), {
    closeModal,
    getModal,
    getGraphData,
    testResultLogFileDownloadLinkRequest,
    testResultLogFilesRequestStop
});
let graphDataTimer;
const getLeftAxisMaxLength = (yAxisDataKey, maxRequestsValue, maxErrorsValue) => yAxisDataKey === TIMELINE_THROUGHPUT ? String(maxRequestsValue).length : String(maxErrorsValue).length;
const getResponseTimeMaxLength = (chartData) => (chartData || []).reduce((res, item) => {
    const responseTimeMaxLength = String(round(item[TIMELINE_PCT_95_RESP_TIME], 1000)).length;
    return responseTimeMaxLength > res ? responseTimeMaxLength : res;
}, 0);
const getMaxVUsersMaxLength = (chartData) => (chartData || []).reduce((res, item) => {
    const vUsersMaxLength = String(round(item[TIMELINE_THREADS], 100)).length;
    return vUsersMaxLength > res ? vUsersMaxLength : res;
}, 0);
const TestDetailsBlocksContainer = (_a) => {
    var { getGraphData, testResultLogFileDownloadLinkRequest, testResultLogFilesRequestStop } = _a, restProps = __rest(_a, ["getGraphData", "testResultLogFileDownloadLinkRequest", "testResultLogFilesRequestStop"]);
    const { formatMessage } = useIntl();
    const [chartData, setChartData] = useState([]);
    const [result, setResult] = useState({});
    const deleteGraphDataTimer = () => clearTimeout(graphDataTimer);
    const setGraphDataTimer = ({ id, testProjectId }) => {
        deleteGraphDataTimer();
        testProjectId &&
            id &&
            (graphDataTimer = setTimeout(() => getGraphData({ testId: id, testProjectId }), GRAPH_DATA_TIMEOUT_MS));
    };
    const stopDataRequesting = () => {
        deleteGraphDataTimer();
        return typeof testResultLogFilesRequestStop === "function" ? testResultLogFilesRequestStop() : undefined;
    };
    useEffect(() => {
        const { id, testProjectId } = restProps.testDetails;
        setGraphDataTimer({ id, testProjectId });
        return stopDataRequesting;
    }, []);
    const checkTestCompletion = ({ displayState }) => isTestCompleted(displayState) ? deleteGraphDataTimer() : undefined;
    const checkTestDuringExecution = ({ displayState, id, testProjectId }) => isTestDuringExecution(displayState) ? setGraphDataTimer({ id, testProjectId }) : undefined;
    const setTestDetailsData = ({ result, timeline }) => {
        setResult(result);
        setChartData(timeline);
    };
    const checkTimelineData = (testDetailsData) => isEmpty(testDetailsData.timeline) ? undefined : setTestDetailsData(testDetailsData);
    useEffect(() => {
        checkTimelineData(restProps.testDetails);
        checkTestDuringExecution(restProps.testDetails);
        checkTestCompletion(restProps.testDetails);
    }, [restProps.testDetails]);
    const clickResultLogFileLinkHandler = (params) => testResultLogFileDownloadLinkRequest(params);
    const maxErrorsValue = getMaxInArray(chartData, TIMELINE_ERRORS, 0);
    const maxRequestsValue = getMaxInArray(chartData, TIMELINE_THROUGHPUT, 0);
    const yAxisDataKey = maxRequestsValue > maxErrorsValue ? TIMELINE_THROUGHPUT : TIMELINE_ERRORS;
    const leftYAxisValMaxLength = getLeftAxisMaxLength(yAxisDataKey, maxRequestsValue, maxErrorsValue);
    const leftYAxisResponseTimeMaxLength = getResponseTimeMaxLength(chartData);
    const rightYAxisValMaxLength = getMaxVUsersMaxLength(chartData, TIMELINE_THREADS);
    const chartErrorsLabel = formatIntlMessage(TEST_DETAILS_CHART_ERRORS_LABEL, formatMessage);
    const chartRequestsLabel = formatIntlMessage(TEST_DETAILS_CHART_REQUESTS_LABEL, formatMessage);
    const chartRequestsErrorsLabel = formatIntlMessage(TEST_DETAILS_CHART_REQUESTS_ERRORS_LABEL, formatMessage);
    const chartResponseLabel = formatIntlMessage(TEST_DETAILS_CHART_RESPONSE_LABEL, formatMessage);
    const chartVUsersLabel = formatIntlMessage(TEST_DETAILS_CHART_VUSERS_LABEL, formatMessage);
    return (React.createElement(TestDetailsBlocks, Object.assign({}, restProps, { chartData: chartData, chartErrorsLabel: chartErrorsLabel, chartRequestsLabel: chartRequestsLabel, chartRequestsErrorsLabel: chartRequestsErrorsLabel, chartResponseLabel: chartResponseLabel, chartVUsersLabel: chartVUsersLabel, leftYAxisResponseTimeMaxLength: leftYAxisResponseTimeMaxLength, leftYAxisValMaxLength: leftYAxisValMaxLength, onClickResultLogFileLink: clickResultLogFileLinkHandler, result: result, rightYAxisValMaxLength: rightYAxisValMaxLength, yAxisDataKey: yAxisDataKey })));
};
export default withResize(connector(TestDetailsBlocksContainer));
