var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getValueByPath } from "@boomq/utils";
import { getResponseErrorData } from "../../helpers/errors";
import { DEFAULT_TIMER_TYPE, DEFAULT_TIMER_VALUE, generateNewSettingsSetData, generateSettingsSetDtoParamsData } from "../../helpers/generators";
import { getDefaultTimersData, prepareTimerFromDtoByType } from "../../helpers/parsers";
import { addLabelToCurrentLabelSet, createLabel, getLabelByName, prepareLabels } from "../../helpers/labels";
import { parseConfigurationElements, parseToolConfigurationFromDto } from "../../helpers/parsers";
import { getTimerValueByType } from "../../helpers/requestTimers";
import { NEW_ITEM_ID, TEST_PROJECT_SETTINGS_SET_ROUTE } from "../../helpers/routes";
import { parseMonitoringDataFromDto } from "../../helpers/testProjectSettingsSet";
import { parseUrlOverwriteItem } from "../../helpers/urlOverwrite";
import { defaultCookieItemListValidator, headerItemListValidator, urlOverwriteItemListValidator, webhookItemListValidator, webhookListValidator } from "../../helpers/validators";
import { parseWebhooksDataFromDto } from "../../helpers/webhook";
import { setJmeterConfigPropertyItems } from "../actions/jmeterConfigProperties";
import { hideLoader } from "../actions/loader";
import { setMonitoringSystemMetricsData } from "../actions/monitoring";
import { changeRequestParameters } from "../actions/requestParameters";
import { addLabelFailure, addLabelRequest, addLabelWithCreatingRequest, copyTestProjectSettingsSetFailure, copyTestProjectSettingsSetRequest, deleteLabelFailure, deleteLabelRequest, editTestProjectSettingsSet, saveTestProjectSettingsSetFailure, saveTestProjectSettingsSetRequest, saveTestProjectSettingsSetSuccess, testProjectSettingsSetChangeParams, testProjectSettingsSetChangeTimerType, testProjectSettingsSetFailure, testProjectSettingsSetRequest, testProjectSettingsSetSuccess } from "../actions/testProjectSettingsSet";
import { setCookies, setCookiesData } from "../actions/testProjectSettingsSetCookies";
import { setHeaders } from "../actions/testProjectSettingsSetHeaders";
import { testProjectSettingsSetsChangeParamsField } from "../actions/testProjectSettingsSets";
import { setUrlOverwriteItems } from "../actions/urlOverwrite";
import { setWebhookItems } from "../actions/webhooks";
import { getConfigDefaultTimer, getConfigHttpRequestDefaults } from "../reducers/config";
import { getRequestLiteralParameters, getRequestParametersImportCsv, initialState as requestParametersInitState } from "../reducers/requestParameters";
import { getTestProjectModelSchema } from "../reducers/testProjectModelSchema";
import { getSettingsSetDefaultTimerData, getSettingsSetHttpRequestDefaultsData, getSettingsSetLabelSet, getSettingsSetName } from "../reducers/testProjectSettingsSet";
import { getCurrentPage, getCurrentPageElements, getPrevPage, getTestProjectSettingsSetsData, getTestProjectSettingsSetsFilterLabels, getTestProjectSettingsSetsLabelList } from "../reducers/testProjectSettingsSets";
import { getSystemMetricPartItems, getSystemMetrics, initialState as monitoringInitState } from "../reducers/monitoring";
import { getSettingsSetDefaultCookieItems, getSettingsSetDefaultCookiesData, initialState as defaultCookiesInitState } from "../reducers/testProjectSettingsSetCookies";
import { getSettingsSetDefaultHeadersData, initialState as defaultHeadersInitState } from "../reducers/testProjectSettingsSetHeaders";
import { getJmeterConfigPropertyItems, initialState as jmeterConfigPropertiesInitState } from "../reducers/jmeterConfigProperties";
import { getUrlOverwriteItems, initialState as urlOverwriteInitState } from "../reducers/urlOverwrite";
import { getWebhookItems, initialState as webhooksInitState } from "../reducers/webhooks";
import { refreshTestProjectSettingsSetList, updateTestProjectSettingsSetsFilterLabelsByLabelList, updateTestProjectSettingsSetsLabels } from "../sagas/testProjectSettingsSets";
import { copyTestProjectSettingsSet, createTestProjectSettingsSet, getTestProjectSettingsSet, updateTestProjectSettingsSet } from "../../services/projectSrv";
export function* getTestProjectSettingsSetFromListById(id) {
    const testProjectSettingsSets = yield select(getTestProjectSettingsSetsData);
    const testProjectSettingsSet = testProjectSettingsSets.find((settingsSet) => settingsSet.id === id);
    return testProjectSettingsSet ? testProjectSettingsSet : {};
}
function* labelAddingFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const labelList = yield select(getTestProjectSettingsSetsLabelList);
        const _a = yield* getTestProjectSettingsSetFromListById(id), { searchedLabels } = _a, testProjectSettingsSetParams = __rest(_a, ["searchedLabels"]);
        const testProjectSettingsSetWithLabel = Object.assign(Object.assign({}, testProjectSettingsSetParams), { labelSet: addLabelToCurrentLabelSet(testProjectSettingsSetParams.labelSet, label) });
        yield* saveTestProjectSettingsSet(id, testProjectSettingsSetWithLabel);
        yield put(testProjectSettingsSetsChangeParamsField({
            field: "labelList",
            value: prepareLabels(addLabelToCurrentLabelSet(labelList, label))
        }));
        const currentPage = yield select(getCurrentPage);
        const filterLabels = yield select(getTestProjectSettingsSetsFilterLabels);
        yield* refreshTestProjectSettingsSetList(currentPage, filterLabels);
    }
    catch (e) {
        yield put(addLabelFailure(getResponseErrorData(e)));
    }
}
function* labelAddingWithCreatingFlow(action) {
    const { id, name } = action && action.payload ? action.payload : {};
    const labelList = yield select(getTestProjectSettingsSetsLabelList);
    const existedLabel = getLabelByName(labelList, name);
    const newLabel = existedLabel ? existedLabel : createLabel(name, labelList.length);
    yield* labelAddingFlow({ payload: { id, label: newLabel } });
}
function* getTestProjectSettingsSetData(id) {
    const { data } = yield call(getTestProjectSettingsSet, id);
    const { configurationElements, supportingFiles = [], systemMetricsMonitoring = {}, timers, toolConfiguration, urlOverwrites, webhookSettingsList } = data.content || {};
    const { defaultCookies, defaultHeaders, httpRequestDefaults, requestParameters } = parseConfigurationElements(configurationElements, supportingFiles);
    const { jmeterConfigProperties } = parseToolConfigurationFromDto(toolConfiguration);
    const urlOverwriteItems = (urlOverwrites || []).map(parseUrlOverwriteItem);
    const monitoringSystemMetricsData = parseMonitoringDataFromDto(systemMetricsMonitoring);
    const validatedWebhookItems = webhookListValidator(parseWebhooksDataFromDto(webhookSettingsList));
    yield put(changeRequestParameters(Object.assign({}, requestParameters)));
    yield put(setJmeterConfigPropertyItems(jmeterConfigProperties));
    yield put(setCookiesData(defaultCookies));
    yield put(setHeaders(defaultHeaders));
    yield put(setUrlOverwriteItems(urlOverwriteItems));
    yield put(setMonitoringSystemMetricsData(monitoringSystemMetricsData));
    yield put(setWebhookItems(validatedWebhookItems));
    yield put(testProjectSettingsSetChangeParams({
        defaultTimer: prepareTimerFromDtoByType(getDefaultTimersData(timers)),
        httpRequestDefaults,
        id
    }));
    return { labelSet: data.labelSet, name: data.name };
}
function* redirectToTestProjectSettingsSetPage(id) {
    return id
        ? yield put(push({
            pathname: `/account/${TEST_PROJECT_SETTINGS_SET_ROUTE}/${id}`
        }))
        : undefined;
}
function* copyTestProjectSettingsSetFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        const { name } = yield* getTestProjectSettingsSetFromListById(id);
        const filterLabels = yield select(getTestProjectSettingsSetsFilterLabels);
        yield call(copyTestProjectSettingsSet, {
            newSettingsName: `copy ${name}`,
            testProjectSettingsId: id
        });
        yield* refreshTestProjectSettingsSetList(0, filterLabels);
    }
    catch (e) {
        yield put(copyTestProjectSettingsSetFailure(getResponseErrorData(e)));
    }
}
export function* editTestProjectSettingsSetFlow({ payload }) {
    try {
        const { id } = payload || {};
        return yield* redirectToTestProjectSettingsSetPage(id);
    }
    catch (e) {
        yield put(testProjectSettingsSetFailure(getResponseErrorData(e)));
    }
}
function* createTestProjectSettingsSetFlow(params) {
    const createTestProjectSettingsSetResponse = yield call(createTestProjectSettingsSet, params);
    const data = createTestProjectSettingsSetResponse && createTestProjectSettingsSetResponse.data
        ? createTestProjectSettingsSetResponse.data
        : {};
    yield* redirectToTestProjectSettingsSetPage(data.id);
    return createTestProjectSettingsSetResponse;
}
function* deleteLabelFlow(action) {
    try {
        const { id, label } = action && action.payload ? action.payload : {};
        const testProjectSettingsSet = yield* getTestProjectSettingsSetFromListById(id);
        const currentPageElements = yield select(getCurrentPageElements);
        const pageForSettingsSetListRefreshing = currentPageElements === 1 ? yield select(getPrevPage) : yield select(getCurrentPage);
        const testProjectSettingsSetWithoutLabel = Object.assign(Object.assign({}, testProjectSettingsSet), { labelSet: testProjectSettingsSet.labelSet.filter((labelSetItem) => labelSetItem.label !== label) });
        yield* saveTestProjectSettingsSet(id, testProjectSettingsSetWithoutLabel);
        yield* updateTestProjectSettingsSetsLabels();
        yield* updateTestProjectSettingsSetsFilterLabelsByLabelList();
        const filterLabels = yield select(getTestProjectSettingsSetsFilterLabels);
        yield* refreshTestProjectSettingsSetList(pageForSettingsSetListRefreshing, filterLabels);
    }
    catch (e) {
        yield put(deleteLabelFailure(getResponseErrorData(e)));
    }
}
function* saveTestProjectSettingsSet(id, params) {
    return id === "new"
        ? yield* createTestProjectSettingsSetFlow(params)
        : yield call(updateTestProjectSettingsSet, { id, params });
}
export function* testProjectSettingsSetSavingFlow(action) {
    try {
        const id = action && action.payload ? action.payload : undefined;
        const configHttpRequestDefaults = yield select(getConfigHttpRequestDefaults);
        const csvParameters = yield select(getRequestParametersImportCsv);
        const defaultCookies = yield select(getSettingsSetDefaultCookiesData);
        const defaultHeaders = yield select(getSettingsSetDefaultHeadersData);
        const defaultTimer = yield select(getSettingsSetDefaultTimerData);
        const httpRequestDefaults = yield select(getSettingsSetHttpRequestDefaultsData);
        const jmeterConfigProperties = yield select(getJmeterConfigPropertyItems);
        const labelSet = yield select(getSettingsSetLabelSet);
        const literalParameters = yield select(getRequestLiteralParameters);
        const modelSchema = yield select(getTestProjectModelSchema);
        const monitoringSystemMetrics = yield select(getSystemMetrics);
        const name = yield select(getSettingsSetName);
        const urlOverwriteItems = yield select(getUrlOverwriteItems);
        const webhookItems = yield select(getWebhookItems);
        const response = yield* saveTestProjectSettingsSet(id, generateSettingsSetDtoParamsData({
            configHttpRequestDefaults,
            csvParameters,
            defaultCookies,
            defaultHeaders,
            defaultTimer,
            httpRequestDefaults,
            jmeterConfigProperties,
            labelSet,
            literalParameters,
            modelSchema,
            monitoringSystemMetrics,
            name,
            urlOverwriteItems,
            webhookItems
        }));
        const settingsSetId = getValueByPath(response, "data.id");
        yield put(saveTestProjectSettingsSetSuccess(settingsSetId));
        return response;
    }
    catch (e) {
        yield put(saveTestProjectSettingsSetFailure(getResponseErrorData(e)));
    }
}
function* changeValidatedItems({ validatedDefaultCookieItems, validatedDefaultHeaderItems, validatedUrlOverwriteItems, validatedWebhookItems }) {
    yield put(setCookies(validatedDefaultCookieItems));
    yield put(setHeaders(validatedDefaultHeaderItems));
    yield put(setUrlOverwriteItems(validatedUrlOverwriteItems));
    yield put(setWebhookItems(validatedWebhookItems));
    yield put(hideLoader());
}
function* settingsSetValidationAndSavingFlow(action) {
    try {
        const defaultCookieItems = yield select(getSettingsSetDefaultCookieItems);
        const defaultHeaderItems = yield select(getSettingsSetDefaultHeadersData);
        const urlOverwriteItems = yield select(getUrlOverwriteItems);
        const webhookItems = yield select(getWebhookItems);
        const { isValid: isValidDefaultCookies, validatedItemList: validatedDefaultCookieItems } = defaultCookieItemListValidator(defaultCookieItems);
        const { isValid: isValidDefaultHeaders, validatedItemList: validatedDefaultHeaderItems } = headerItemListValidator(defaultHeaderItems);
        const { isValid: isValidUrlOverwrite, validatedItemList: validatedUrlOverwriteItems } = urlOverwriteItemListValidator(urlOverwriteItems);
        const { isValid: isValidWebhooks, validatedItemList: validatedWebhookItems } = webhookItemListValidator(webhookItems);
        return isValidDefaultCookies && isValidDefaultHeaders && isValidUrlOverwrite && isValidWebhooks
            ? yield* testProjectSettingsSetSavingFlow(action)
            : yield* changeValidatedItems({
                validatedDefaultCookieItems,
                validatedDefaultHeaderItems,
                validatedUrlOverwriteItems,
                validatedWebhookItems
            });
    }
    catch (e) {
        yield put(saveTestProjectSettingsSetFailure(getResponseErrorData(e)));
    }
}
export function* getNewTestProjectSettingsSetData() {
    const configDefaultTimerData = yield select(getConfigDefaultTimer);
    const httpRequestDefaultsData = yield select(getConfigHttpRequestDefaults);
    const systemMetricPartItems = yield select(getSystemMetricPartItems);
    const webhooksData = yield select(getWebhookItems);
    yield put(changeRequestParameters(requestParametersInitState));
    yield put(setCookiesData(defaultCookiesInitState));
    yield put(setHeaders(defaultHeadersInitState.items));
    yield put(setJmeterConfigPropertyItems(jmeterConfigPropertiesInitState.items));
    yield put(setUrlOverwriteItems(urlOverwriteInitState.items));
    yield put(setMonitoringSystemMetricsData(Object.assign(Object.assign({}, monitoringInitState), { systemMetricPartItems })));
    yield put(setWebhookItems(webhooksInitState.items));
    return generateNewSettingsSetData({
        configDefaultTimerData,
        defaultCookiesData: defaultCookiesInitState,
        defaultHeadersData: defaultHeadersInitState.items,
        httpRequestDefaultsData,
        webhooksData
    });
}
function* testProjectSettingsSetDataFlow(action) {
    try {
        const id = action && action.payload ? action.payload : {};
        const data = id === NEW_ITEM_ID ? yield* getNewTestProjectSettingsSetData() : yield* getTestProjectSettingsSetData(id);
        yield put(testProjectSettingsSetSuccess(data));
    }
    catch (e) {
        yield put(testProjectSettingsSetFailure(getResponseErrorData(e)));
    }
}
function* testProjectSettingsSetTimerTypeChangingFlow(action) {
    try {
        const timerType = action && action.payload ? action.payload : DEFAULT_TIMER_TYPE;
        yield put(testProjectSettingsSetChangeParams({
            defaultTimer: Object.assign({ enabled: true, timerType }, getTimerValueByType({ timerType }, DEFAULT_TIMER_VALUE))
        }));
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectSettingsSetFlow() {
    yield takeEvery(addLabelRequest, labelAddingFlow);
    yield takeEvery(addLabelWithCreatingRequest, labelAddingWithCreatingFlow);
    yield takeEvery(copyTestProjectSettingsSetRequest, copyTestProjectSettingsSetFlow);
    yield takeEvery(deleteLabelRequest, deleteLabelFlow);
    yield takeEvery(editTestProjectSettingsSet, editTestProjectSettingsSetFlow);
    yield takeEvery(saveTestProjectSettingsSetRequest, settingsSetValidationAndSavingFlow);
    yield takeEvery(testProjectSettingsSetChangeTimerType, testProjectSettingsSetTimerTypeChangingFlow);
    yield takeEvery(testProjectSettingsSetRequest, testProjectSettingsSetDataFlow);
}
