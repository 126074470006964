import styled from "styled-components";
export const InfoWrapper = styled.div `
    padding: 0 34px 41px;
`;
export const Plate = styled.div `
    width: 100%;
    //margin-top: 34px;
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 64, 140, 0.15);
    border-radius: 5px;
`;
export const PlateWrapper = styled.div `
    padding: 34px 34px 0;
`;
export const Separator = styled.i `
    display: flex;
    width: 100%;
    height: 1px;
    background: #f2f2f2;
`;
export const SubTitle = styled.h4 `
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 16px;
    color: #bdbdbd;
    padding-top: 25px;
`;
export const Title = styled.h3 `
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    color: #396496;
    padding: 25px 32px 19px;
`;
