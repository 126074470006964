import React from "react";
import { useResize } from "@boomq/utils";
import { Pagination } from "@boomq/uikit";
import { PaginationWrapper, StyledPage, TestList } from "./styled";
import { TestRunsItemWithComparingTemplate } from "../../../account/TestRuns/TestRunsItemWithComparingTemplate";
import { Fade } from "../../../animation";
import { TableHeader } from "../../../common/TableHeader";
import { AllTestsBlockTitle, NewTest } from "../../../styled/AllTests";
import { sizes } from "../../../styled/response";
import { AllTestsPanelContainer } from "../../../../containers/account/AllTestsPanel";
import { RunningTestsContainer } from "../../../../containers/account/TestRuns/RunningTests";
import { messages } from "../../../../data/content/endedTestTitle";
import { isAllowedTestProjectActionByPermissions } from "../../../../helpers/testProject";
import { TestProjectAction } from "../../../../helpers/types";
export const AllTests = ({ allTestRunsTitle, clientTests, clientTestLabels, clientTestProjectList, clientTestUserList, clientTestsPagesCount = 0, currentPage, filters, newTestButtonLabel, onChangeFilter, onChangePage, onClickStopTest, onCompareTests, onToggleTest, permissionList, runningTests, runningTestsTitle, selectedTestIds, statuses }) => {
    const { width } = useResize();
    const isSelectedItem = (test) => (selectedTestIds || []).includes(test.id);
    return (React.createElement(Fade, { animation: "inUp" },
        React.createElement(StyledPage, null,
            isAllowedTestProjectActionByPermissions(permissionList, TestProjectAction.Add) && (React.createElement(NewTest, { to: "/account/new-test" }, newTestButtonLabel)),
            React.createElement(RunningTestsContainer, { runningTests: runningTests, title: runningTestsTitle }),
            runningTests && runningTests.length > 0 && React.createElement(AllTestsBlockTitle, null, allTestRunsTitle),
            width > sizes.tablet && (React.createElement(AllTestsPanelContainer, { onChangeFilter: onChangeFilter, onCompareTests: onCompareTests, selectedTestIds: selectedTestIds, statuses: statuses, testLabels: clientTestLabels, testProjects: clientTestProjectList, testUsers: clientTestUserList, filtersData: filters })),
            width > sizes.tablet && React.createElement(TableHeader, { columns: messages }),
            React.createElement(TestList, null, (clientTests || []).map((test) => {
                var _a, _b;
                return (React.createElement(TestRunsItemWithComparingTemplate, Object.assign({}, test, { key: test.id, onClickStop: onClickStopTest, onToggle: onToggleTest, permissionList: permissionList, selected: isSelectedItem(test), projectName: (_a = test === null || test === void 0 ? void 0 : test.testProject) === null || _a === void 0 ? void 0 : _a.projectName, totalDuration: (_b = test === null || test === void 0 ? void 0 : test.testProjectVersion) === null || _b === void 0 ? void 0 : _b.totalDuration })));
            })),
            clientTestsPagesCount > 1 && (React.createElement(PaginationWrapper, null,
                React.createElement(Pagination, { clickHandler: onChangePage, currentPage: currentPage, totalPages: clientTestsPagesCount }))))));
};
