import { combineActions, handleActions } from "redux-actions";
import clientActions from "../actions/client";
import authorizeActions from "../actions/authorize";
import { updateNotify } from "../actions/notifications";
import { setPermissionList, setSelectedTeam } from "../actions/teams";
import { isEmpty } from "../../helpers/utils";
const { authSuccess, logout, saveToken, setActiveAction, setActiveUrl, setAuthProviders, secretTokenSuccess } = authorizeActions;
const { changeParamsSuccess } = clientActions;
export const initialState = {
    accessToken: null,
    activeAction: null,
    activeUrl: null,
    authProviders: [],
    clientInfo: {},
    clientNotifications: [],
    isHeapTracked: false,
    jwt: null,
    permissionList: [],
    secretToken: "",
    selectedTeam: {}
};
export default handleActions({
    [combineActions(authSuccess, changeParamsSuccess)]: (state, action) => (Object.assign(Object.assign({}, state), { clientInfo: action.payload || {} })),
    [logout]: () => (Object.assign({}, initialState)),
    [secretTokenSuccess]: (state, action) => (Object.assign(Object.assign({}, state), { secretToken: action.payload })),
    [setPermissionList]: (state, action) => (Object.assign(Object.assign({}, state), { permissionList: action && Array.isArray(action.payload) ? action.payload : [] })),
    [saveToken]: (state, action) => (Object.assign(Object.assign({}, state), { jwt: action.payload })),
    [setActiveAction]: (state, action) => (Object.assign(Object.assign({}, state), { activeAction: action.payload })),
    [setActiveUrl]: (state, action) => (Object.assign(Object.assign({}, state), { activeUrl: action.payload })),
    [setAuthProviders]: (state, action) => (Object.assign(Object.assign({}, state), { authProviders: action.payload })),
    [setSelectedTeam]: (state, action) => action && action.payload ? Object.assign(Object.assign({}, state), { selectedTeam: action.payload }) : state,
    [updateNotify]: (state, action) => (Object.assign(Object.assign({}, state), { clientNotifications: state.clientNotifications.map((notify) => {
            if (notify.id === action.payload) {
                return Object.assign(Object.assign({}, notify), { read: true });
            }
            return notify;
        }) }))
}, initialState);
const getSelectorValue = (state, paramName) => state && state.authorizePlate ? state.authorizePlate[paramName] : initialState[paramName];
export const getActiveAction = (state) => getSelectorValue(state, "activeAction");
export const getActiveUrl = (state) => getSelectorValue(state, "activeUrl");
export const getClientAuthProviders = (state) => getSelectorValue(state, "authProviders");
export const getClientInfo = (state) => getSelectorValue(state, "clientInfo");
export const getClientAuthType = (state) => getClientInfo(state).type;
export const getClientEmail = (state) => getClientInfo(state).email;
export const getClientRole = (state) => {
    const clientInfo = getClientInfo(state);
    return clientInfo && Array.isArray(clientInfo.authorityList) ? clientInfo.authorityList : [];
};
export const getClientNotifications = (state) => getSelectorValue(state, "clientNotifications");
export const getClientPermissionList = (state) => getSelectorValue(state, "permissionList");
export const getClientToken = (state) => getSelectorValue(state, "jwt");
export const getHeapTrackStatus = (state) => getSelectorValue(state, "isHeapTracked");
export const getIsAuthorize = (state) => !!getClientToken(state);
export const getSecretTokenData = (state) => getSelectorValue(state, "secretToken");
export const getSelectedTeamData = (state) => getSelectorValue(state, "selectedTeam");
export const getSelectedTeamId = (state) => getSelectedTeamData(state).id;
export const isExistSelectedTeam = (state) => !isEmpty(getSelectedTeamData(state));
