const baseUrl = process.env.REACT_APP_BASE_URL;
export const getBaseUrl = (env) => !baseUrl && window && window.document ? window.document.baseURI : baseUrl || "";
const getCurrentLocation = () => (window && window.location ? window.location : {});
export const getCurrentPathName = () => {
    const { pathname } = getCurrentLocation();
    return pathname ? pathname : "";
};
export const getHostUrl = () => {
    const { host, protocol } = getCurrentLocation();
    return `${protocol}//${host}`;
};
