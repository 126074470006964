import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { editReport, reportsFailure, reportsRequest, reportsSuccess } from "../actions/reports";
import { getCurrentPage, getReportsPageSize, getReportsSortData } from "../reducers/reports";
import { getResponseErrorData } from "../../helpers/errors";
import { getReportsRoute } from "../../helpers/routes";
import { apiGetReports } from "../../services/reportSrv";
function* editReportFlow(action) {
    var _a;
    try {
        const id = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.id;
        yield put(push({
            pathname: `${getReportsRoute()}/${id}`
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* getReportsData() {
    const currentPage = yield select(getCurrentPage);
    const sortData = yield select(getReportsSortData);
    const size = yield select(getReportsPageSize);
    const params = {
        pagination: {
            pageNumber: currentPage,
            pageSize: size
        },
        sort: sortData
    };
    return yield call(apiGetReports, params);
}
function* reportListFlow() {
    try {
        const { data } = yield* getReportsData();
        yield put(reportsSuccess(data));
    }
    catch (e) {
        yield put(reportsFailure(getResponseErrorData(e)));
    }
}
export function* reportsFlow() {
    yield takeEvery(reportsRequest, reportListFlow);
    yield takeEvery(editReport, editReportFlow);
}
