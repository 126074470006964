import { requestCommonParamList } from "./common";
import { IconView } from "../../components/styled/Icons";
import { RequestJMXTimerIcon } from "../../components/account/TestProject/RequestJMXTimerIcon";
import { FormType, TestProjectRequestActionType } from "../../helpers/types";
export const requestJMXActions = [
    {
        actionName: "editRequestJMXTimersHandler",
        iconComponent: RequestJMXTimerIcon,
        params: {
            defaultActiveTabName: "timers",
            isDisabledInputs: true,
            tabProps: {
                BodyText: { mode: FormType.View },
                HeaderParameters: { mode: FormType.View },
                RequestJMXTimers: { mode: FormType.Edit },
                QueryParameters: { mode: FormType.View }
            }
        },
        type: TestProjectRequestActionType.Component
    },
    {
        actionName: "showRequestHandler",
        icon: IconView,
        type: TestProjectRequestActionType.Icon
    }
];
export const requestJMXParamList = [
    ...requestCommonParamList,
    {
        id: 3,
        component: {
            elementName: "RequestJMXTimers",
            props: {}
        },
        name: "timers",
        title: { defaultMessage: "Timers", id: "account.request.form.timers.title" }
    }
];
