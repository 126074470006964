import styled from "styled-components";
import { Button, Color } from "@boomq/uikit";

export const StyledButton = styled(Button)`
    background: transparent;
    &:hover {
        color: ${Color.white};
        background: ${Color.blue};
    }
`;
