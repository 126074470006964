import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
    AuthBlock,
    AuthBlockTitle,
    AuthButtonBlock,
    AuthFormLink,
    AuthPlateWrapper,
    ErrorPlate,
    RegisterFormContainer,
    StyledButton,
    authErrors,
    authModalForm,
    getError,
    getSiteModal
} from "@boomq/common";
import { FORM_TITLE, SIGN_IN_LINK_LABEL, SUBMIT_BUTTON_LABEL } from "./intl";
import { AuthModalFormCenterSignUpLink, AuthSectionTitle } from "./styled";

const connector = connect(
    (state) => ({
        apiError: getError(state)
    }),
    { getSiteModal }
);

const RegisterPlate = ({ apiError, authElement, type }): JSX.Element => {
    const clickAuthHandler = (): void => getSiteModal(authModalForm(authElement));

    const authError = authErrors(apiError.exceptionMessage);

    return (
        <AuthPlateWrapper>
            <AuthBlockTitle type={type}>
                <AuthSectionTitle>
                    <FormattedMessage {...FORM_TITLE} />
                </AuthSectionTitle>
            </AuthBlockTitle>

            <AuthBlock type={type}>
                <RegisterFormContainer type={type}>
                    <AuthButtonBlock>
                        <StyledButton type="submit">
                            <FormattedMessage {...SUBMIT_BUTTON_LABEL} />
                        </StyledButton>
                    </AuthButtonBlock>

                    {type === "modal" ? (
                        <AuthModalFormCenterSignUpLink onClick={clickAuthHandler}>
                            <FormattedMessage {...SIGN_IN_LINK_LABEL} />
                        </AuthModalFormCenterSignUpLink>
                    ) : (
                        <AuthFormLink color="#055FFC" weight="600" to="/authorize">
                            <FormattedMessage {...SIGN_IN_LINK_LABEL} />
                        </AuthFormLink>
                    )}
                </RegisterFormContainer>
            </AuthBlock>

            {authError && authError.id && (
                <ErrorPlate>
                    <FormattedMessage {...authError} />
                </ErrorPlate>
            )}
        </AuthPlateWrapper>
    );
};

export default connector(RegisterPlate);
