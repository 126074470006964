export const supportedJMeterModules = [{
        id: 0,
        link: "http://jmeter.apache.org/usermanual/index.html",
        text1: "jmeter - Core (",
        text2: ") - 5.1.1",
    }, {
        id: 1,
        link: "http://jmeter.apache.org/usermanual/component_reference.html#HTTP_Request",
        text1: "jmeter - HTTP Protocol Support (",
        text2: ") - 5.1.1",
    }, {
        id: 2,
        link: "https://jmeter-plugins.org/wiki/ConcurrencyThreadGroup/",
        text1: "Custom Thread Groups (",
        text2: ") - 2.9",
    }];
