import { combineActions, handleActions } from "redux-actions";
import { getValueByPath } from "@boomq/utils";
import authorizeActions from "../../actions/authorize";
import { changeRequestMethod, changeTreeData, resetEditorState, setElement, setElementField, setElements, setExpandedKeys, setExtractFromResponseParameters, setFirstElementIdWithError, setForEachParameters, setGroupName, setTimers } from "../../actions/editor";
import newTestActions from "../../actions/newTest";
const { logout } = authorizeActions;
const { editRequestGroup } = newTestActions;
export const initialState = {
    elements: {},
    expandedKeys: {},
    extractFromResponseParameters: {},
    firstElementIdWithError: null,
    groupId: null,
    timers: {},
    treeData: []
};
export default handleActions({
    [combineActions(logout, resetEditorState)]: () => (Object.assign({}, initialState)),
    [changeRequestMethod]: (state, { payload }) => (Object.assign(Object.assign({}, state), { elements: Object.assign(Object.assign({}, state.elements), { [payload.id]: Object.assign(Object.assign({}, state.elements[payload.id]), { method: payload.method }) }) })),
    [changeTreeData]: (state, action) => (Object.assign(Object.assign({}, state), { treeData: action.payload })),
    [editRequestGroup]: (state, { payload }) => (Object.assign(Object.assign({}, state), { groupId: payload.id, groupName: payload.name })),
    [setElement]: (state, { payload }) => (Object.assign(Object.assign({}, state), { elements: Object.assign(Object.assign({}, state.elements), { [payload.id]: payload }) })),
    [setElementField]: (state, { payload }) => (Object.assign(Object.assign({}, state), { elements: Object.assign(Object.assign({}, state.elements), { [payload.id]: Object.assign(Object.assign({}, state.elements[payload.id]), { [payload.field]: payload.value, error: Object.assign(Object.assign({}, getValueByPath(state.elements, `${payload.id}.error`, {})), { [payload.field]: undefined }) }) }) })),
    [setElements]: (state, action) => (Object.assign(Object.assign({}, state), { elements: action.payload })),
    [setExpandedKeys]: (state, action) => (Object.assign(Object.assign({}, state), { expandedKeys: action.payload })),
    [setExtractFromResponseParameters]: (state, action) => (Object.assign(Object.assign({}, state), { extractFromResponseParameters: action.payload })),
    [setFirstElementIdWithError]: (state, action) => (Object.assign(Object.assign({}, state), { firstElementIdWithError: action.payload })),
    [setForEachParameters]: (state, action) => (Object.assign(Object.assign({}, state), { forEachParameters: action.payload })),
    [setGroupName]: (state, { payload }) => (Object.assign(Object.assign({}, state), { groupName: payload })),
    [setTimers]: (state, action) => (Object.assign(Object.assign({}, state), { timers: action.payload }))
}, initialState);
