var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, useIntl } from "@boomq/utils";
import { CANCEL_BUTTON_LABEL, SAVE_BUTTON_LABEL } from "./intl";
import { Editor } from "../../../../components/account/RequestGroupEditor/Editor";
import { formatIntlMessage } from "../../../../helpers/intl";
import { getThreadGroupElementType } from "../../../../helpers/threadGroup";
import { isNodeSampler, scrollToFirstElementWithError } from "../../../../helpers/threadGroupEditor";
import { ThreadGroupController } from "../../../../models/threadGroupEditor";
import { addElement, cancelEditTreeData, changeTreeData, refreshTreeData, saveTreeData, showImportDataToThreadGroupForm } from "../../../../redux/actions/editor";
import { getIsAuthorize } from "../../../../redux/reducers/authorizePlate";
import { getEditorGroupId, getEditorTreeData, getExpandedKeys, getFirstElementIdWithError } from "../../../../redux/reducers/editor";
const connector = connect((state) => ({
    expandedKeys: getExpandedKeys(state),
    firstElementIdWithError: getFirstElementIdWithError(state),
    groupId: getEditorGroupId(state),
    isAuthorized: getIsAuthorize(state),
    treeData: getEditorTreeData(state)
}), {
    onAdd: addElement,
    onCancel: cancelEditTreeData,
    onChange: changeTreeData,
    onClickImportDataElement: showImportDataToThreadGroupForm,
    onRefresh: refreshTreeData,
    onSave: saveTreeData
});
const nodeClassNameFunc = (node) => {
    const extClass = isEmpty(node.error) ? "" : " error";
    return getThreadGroupElementType(node) === ThreadGroupController.Controller
        ? `node-controller${extClass}`
        : `node-sampler${extClass}`;
};
const isValidDropping = ({ dropNode }) => !isNodeSampler(dropNode);
const EditorContainer = (_a) => {
    var { firstElementIdWithError, groupId, onAdd, onCancel, onClickImportDataElement, onRefresh, onSave } = _a, restProps = __rest(_a, ["firstElementIdWithError", "groupId", "onAdd", "onCancel", "onClickImportDataElement", "onRefresh", "onSave"]);
    const { formatMessage } = useIntl();
    const editorRef = useRef();
    const [expandedKeys, setExpandedKeys] = useState(restProps.expandedKeys);
    useEffect(() => {
        onRefresh({ id: groupId });
    }, []);
    useEffect(() => {
        setExpandedKeys(Object.assign(Object.assign({}, expandedKeys), restProps.expandedKeys));
    }, [restProps.expandedKeys]);
    useEffect(() => {
        scrollToFirstElementWithError(editorRef.current, firstElementIdWithError);
    }, [expandedKeys]);
    const cancelButtonLabel = formatIntlMessage(CANCEL_BUTTON_LABEL, formatMessage);
    const saveButtonLabel = formatIntlMessage(SAVE_BUTTON_LABEL, formatMessage);
    const checkExpandedElement = (node) => node && node.id ? setExpandedKeys(Object.assign(Object.assign({}, expandedKeys), { [node.id]: true })) : undefined;
    const addElementHandler = ({ dragNode, dropNode, value }) => {
        onAdd(dragNode);
        checkExpandedElement(dropNode);
        return restProps.onChange(value);
    };
    const clickCancelHandler = () => onCancel();
    const clickImportDataElementHandler = (data) => onClickImportDataElement(data);
    const clickSaveHandler = () => onSave();
    const reorderElementHandler = (event) => restProps.onChange(event.value);
    const toggleElementHandler = (event) => setExpandedKeys(event.value);
    return (React.createElement(Editor, Object.assign({}, restProps, { cancelButtonLabel: cancelButtonLabel, expandedKeys: expandedKeys, nodeClassNameFunc: nodeClassNameFunc, onAdd: addElementHandler, onCancel: clickCancelHandler, onClickImportDataElement: clickImportDataElementHandler, onDropValidation: isValidDropping, onReorder: reorderElementHandler, onSave: clickSaveHandler, onToggleExpandedElement: toggleElementHandler, ref: editorRef, saveButtonLabel: saveButtonLabel })));
};
export default withRouter(connector(EditorContainer));
