import { getUniqueId, getValueByPath, isEmpty, objectToArray, unEscapeComponent } from "@boomq/utils";
import { parseAssertions } from "./assertions";
import { parseExtractors } from "./extractors";
import { generateRequestUrlFromParams, prepareRequestData } from "./threadGroupSampler";
import { getTimersFromRequest, prepareTimerList } from "./timers";
import { generateBodyPreview, getThreadGroupElementName } from "../threadGroup";
import { SamplerType, ThreadGroupController } from "../../models/threadGroupEditor";
export const prepareQueryParam = ({ name, value }) => ({
    id: getUniqueId(),
    key: unEscapeComponent(name),
    value: unEscapeComponent(value)
});
const prepareQueryParams = (individualProperties) => getValueByPath(individualProperties, "queryParameters", []).map(prepareQueryParam);
const getHttpRequestIndividualProps = ({ individualProperties }) => ({
    body: { TEXT: getValueByPath(individualProperties, "body", "") },
    headers: getValueByPath(individualProperties, "headers", {}),
    method: getValueByPath(individualProperties, "method", ""),
    params: prepareQueryParams(individualProperties),
    requestUrl: generateRequestUrlFromParams(individualProperties)
});
export const formatHttpRequestParams = ({ assertions, body, extractors, groupId, headers, id, method, params, requestName, requestUrl, responsePreview, timerList }) => ({
    id: id || getUniqueId(),
    assertions: parseAssertions(assertions),
    body,
    bodyPreview: generateBodyPreview(body),
    controllerType: SamplerType.Http,
    extractResponse: parseExtractors(extractors),
    groupId,
    headers: headers ? objectToArray(headers, "key", "value") : [],
    isCopyRequestNameFromUrl: requestName === requestUrl || isEmpty(requestName),
    method,
    queryParams: params,
    requestUrl,
    responsePreview,
    timerList: prepareTimerList(timerList),
    type: ThreadGroupController.Sampler
});
export const parseHttpRequestParams = (request, groupId) => {
    const { body, headers, method, params, requestUrl } = getHttpRequestIndividualProps(request);
    const requestName = getThreadGroupElementName(request);
    return formatHttpRequestParams(Object.assign(Object.assign({}, request), { body,
        groupId,
        headers,
        method,
        params,
        requestName,
        requestUrl }));
};
export const parseHttpRequest = ({ res, groupElement, groupId, parentElementId, path }) => {
    const requestTimers = getTimersFromRequest(groupElement, groupId);
    const parsedElementData = parseHttpRequestParams(Object.assign(Object.assign({}, groupElement), { timerList: Object.keys(requestTimers) }), groupId);
    return prepareRequestData({
        res,
        groupElement,
        parentElementId,
        parsedElementData,
        path,
        requestTimers
    });
};
