import { getValueByPath, isEmpty } from "@boomq/utils";
import { getPathByIndex } from "./common";
import { DEFAULT_PARSE_FUNC_NAME_BY_ELEMENT_CONTROLLER_TYPE, DEFAULT_PARSE_FUNC_NAME_BY_ELEMENT_TYPE } from "./constants";
import { parseForEachElement, parseIfElement, parseLoopElement, parseTransaction, parseWhileElement } from "./threadGroupController";
import { parseGrpcRequest } from "./threadGroupSamplerGrpc";
import { parseHttpRequest } from "./threadGroupSamplerHttp";
import { getThreadGroupElementControllerType, getThreadGroupElementId, getThreadGroupElementType } from "../threadGroup";
import { ControllerFlowControlType, ControllerType, SamplerType, ThreadGroupController } from "../../models/threadGroupEditor";
export const getControllerTypeFromThreadGroupElementData = (element) => {
    const controllerTypeFromIndividualProperties = getValueByPath(element, "individualProperties.type");
    return controllerTypeFromIndividualProperties || getThreadGroupElementControllerType(element);
};
const parseFuncByControllerType = {
    [ControllerType.Transaction]: parseTransaction,
    [ControllerFlowControlType.ForEach]: parseForEachElement,
    [ControllerFlowControlType.If]: parseIfElement,
    [ControllerFlowControlType.Loop]: parseLoopElement,
    [ControllerFlowControlType.While]: parseWhileElement,
    [SamplerType.Grpc]: parseGrpcRequest,
    [SamplerType.Http]: parseHttpRequest
};
const getParseFuncKeyByControllerType = (element) => Object.keys(parseFuncByControllerType).includes(getControllerTypeFromThreadGroupElementData(element))
    ? getControllerTypeFromThreadGroupElementData(element)
    : DEFAULT_PARSE_FUNC_NAME_BY_ELEMENT_CONTROLLER_TYPE;
const parseThreadGroupElementByControllerType = (data) => parseFuncByControllerType[getParseFuncKeyByControllerType(data.groupElement)](data);
const parseThreadGroupController = ({ res, groupId, parentElementId, path, groupElement }) => {
    const elementId = getThreadGroupElementId(groupElement);
    const elementData = parseThreadGroupElementByControllerType({ groupElement, groupId, path, res, elementId });
    const maxTransactionIndex = res.maxTransactionIndex < elementData.index ? elementData.index : res.maxTransactionIndex;
    return isEmpty(groupElement.children)
        ? Object.assign(Object.assign({}, res), { maxTransactionIndex, elementsData: Object.assign(Object.assign({}, res.elementsData), { [elementId]: elementData }) }) : Object.assign(Object.assign(Object.assign({}, res), { maxTransactionIndex }), getThreadGroupElements({
        extractorParameters: res.extractorParameters,
        groupElements: groupElement.children,
        groupId,
        maxTransactionIndex,
        requests: res.requests,
        parentElementId: elementId,
        path,
        timers: res.timers,
        transactions: res.transactions,
        elementsData: Object.assign(Object.assign({}, res.elementsData), { [elementId]: elementData })
    }));
};
const parseFuncByElementType = {
    [ThreadGroupController.Controller]: parseThreadGroupController,
    [ThreadGroupController.Sampler]: parseThreadGroupElementByControllerType
};
const getParseFuncKeyByElementType = (groupElementData) => Object.keys(parseFuncByElementType).includes(getThreadGroupElementType(groupElementData))
    ? getThreadGroupElementType(groupElementData)
    : DEFAULT_PARSE_FUNC_NAME_BY_ELEMENT_TYPE;
const parseThreadGroupElementsByType = (data) => parseFuncByElementType[getParseFuncKeyByElementType(data.groupElement)](data || {});
export function getThreadGroupElements({ elementsData, extractorParameters, groupElements, groupId, maxTransactionIndex, requests, parentElementId, path, timers, transactions }) {
    return (groupElements || []).reduce((res, groupElement, index) => {
        const elementPath = getPathByIndex(path, index);
        return parseThreadGroupElementsByType({ res, groupElement, groupId, parentElementId, path: elementPath });
    }, { extractorParameters, maxTransactionIndex, requests, timers, transactions, elementsData });
}
