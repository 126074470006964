import styled from "styled-components";
import { Color, FontBodySmallMedium, IconPeople } from "@boomq/uikit";
import { AddingIcon } from "../../styled/Buttons";
import { HeaderText } from "../../styled/Header";
import { ToolbarLink } from "../../website/ToolbarLink";
import { withTruncatedText } from "../../styled/global";
export const StyledIconPeople = styled(IconPeople) `
    fill: ${Color.darkGrey35};
    height: 16px;
    width: 16px;
`;
export const TeamImage = styled.img `
    height: auto;
    max-width: 32px;
    min-width: 32px;
    width: 32px;
`;
export const TeamName = styled(HeaderText) `
    ${FontBodySmallMedium}
    ${withTruncatedText}
    margin-left: 4px;
    max-width: 90px;
`;
const TeamsInfoWrapperStyle = styled(HeaderText) `
    align-items: center;
    background: transparent;
    cursor: pointer;
    display: flex;
    margin-left: 16px;
    outline: none;
    &:active {
        ${TeamImage} {
            transform: scale(0.9);
        }
    }
    &:hover {
        ${StyledIconPeople} {
            fill: ${Color.blue};
        }
        ${TeamName} {
            color: ${Color.blue};
        }
    }
`;
export const StyledAddingIcon = styled(AddingIcon) `
    margin-right: 6px;
    &:before,
    &:after {
        background: ${Color.darkGrey35};
    }
`;
export const SelectTeamButton = styled.div `
    align-items: center;
    border: 1px dashed ${Color.darkGrey35};
    border-radius: 6px;
    color: ${Color.grey65};
    display: flex;
    padding: 6px 12px;
    &:hover {
        border-color: ${Color.blue};
        color: ${Color.blue};
        ${StyledAddingIcon} {
            &:before,
            &:after {
                background: ${Color.blue};
            }
        }
    }
`;
export const TeamLink = TeamsInfoWrapperStyle.withComponent(ToolbarLink);
export const TeamInfoWrapper = TeamsInfoWrapperStyle.withComponent("div");
