import { mergeMap } from "@boomq/utils";
import { PASS_MAX_LENGTH, PASS_MIN_DIGIT_COUNT, PASS_MIN_LENGTH, PASS_MIN_LOWERCASE_COUNT, PASS_MIN_SYMBOL_COUNT, PASS_MIN_UPPERCASE_COUNT } from "./constants";
export const getPassRulesErrorsMapByParam = ({ isValidDigit, isValidMaxLength, isValidMinLength, isValidLowercase, isValidSymbol, isValidUppercase }) => isValidDigit && isValidMinLength && isValidMaxLength && isValidLowercase && isValidSymbol && isValidUppercase
    ? {}
    : {
        password: {
            defaultMessage: "Password is not valid",
            id: "validation.password.not.valid"
        },
        passwordRules: {
            defaultMessage: "Please make sure to follow below password policy:{minLength}{maxLength}{lowercase}{uppercase}{digit}{symbol}",
            id: "validation.password.requirements",
            values: {
                br: "",
                minLength: isValidMinLength && isValidMaxLength
                    ? ""
                    : {
                        defaultMessage: "{br}• Password should be at least {min} chracters long",
                        id: "validation.password.requirements.length.greater",
                        values: { br: "", min: PASS_MIN_LENGTH }
                    },
                maxLength: isValidMaxLength
                    ? ""
                    : {
                        defaultMessage: " and less {max}",
                        id: "validation.password.requirements.length.less",
                        values: { max: PASS_MAX_LENGTH }
                    },
                lowercase: isValidLowercase
                    ? ""
                    : {
                        defaultMessage: "{br}• Password should contain at least {min} lowercase letter",
                        id: "validation.password.requirements.lower.case",
                        values: {
                            br: "",
                            min: PASS_MIN_LOWERCASE_COUNT
                        }
                    },
                uppercase: isValidUppercase
                    ? ""
                    : {
                        defaultMessage: "{br}• Password should contain at least {min} uppercase letter",
                        id: "validation.password.requirements.upper.case",
                        values: { br: "", min: PASS_MIN_UPPERCASE_COUNT }
                    },
                digit: isValidDigit
                    ? ""
                    : {
                        defaultMessage: "{br}• Password should contain at least {min} digit",
                        id: "validation.password.requirements.digit",
                        values: { br: "", min: PASS_MIN_DIGIT_COUNT }
                    },
                symbol: isValidSymbol
                    ? ""
                    : {
                        defaultMessage: "{br}• Password should contain at least {min} special character ({characters})",
                        id: "validation.password.requirements.symbol",
                        values: {
                            br: "",
                            characters: "!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~ ",
                            min: PASS_MIN_SYMBOL_COUNT
                        }
                    }
            }
        }
    };
const errorIntlMsgByViolationMessage = {
    "Password must be 8 or more characters in length.": { isValidMinLength: false, isValidMaxLength: true },
    "Password must contain 1 or more special characters.": { isValidSymbol: false },
    "Password must contain 1 or more lowercase characters.": { isValidLowercase: false },
    "Password must contain 1 or more digit characters.": { isValidDigit: false },
    "Password must contain 1 or more uppercase characters.": { isValidUppercase: false }
};
const getErrorIntlMsgByViolationMessage = ({ message }) => errorIntlMsgByViolationMessage[message] || {};
export const passwordRulesErrors = (violationList) => {
    const defaultValidationData = {
        isValidMaxLength: true,
        isValidMinLength: true,
        isValidLowercase: true,
        isValidDigit: true,
        isValidSymbol: true,
        isValidUppercase: true
    };
    const validationData = (violationList || []).reduce((res, violation) => (Object.assign(Object.assign({}, res), getErrorIntlMsgByViolationMessage(violation))), {});
    return getPassRulesErrorsMapByParam(mergeMap(defaultValidationData, validationData));
};
