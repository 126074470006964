import { getValueByPath } from "@boomq/utils";
import { initialState } from "./editor";
import { getRequestGlobalParametersList } from "../newTest";
import { getThreadGroupElementControllerType } from "../../../helpers/threadGroup";
import { ControllerType } from "../../../models/threadGroupEditor";
const getStateParam = (state, paramName) => state && state.editor ? state.editor[paramName] : initialState[paramName];
export const getEditorGroupId = (state) => getStateParam(state, "groupId");
export const getEditorGroupName = (state) => getStateParam(state, "groupName");
export const getExtractFromResponseParameters = (state) => getStateParam(state, "extractFromResponseParameters");
export const getFirstElementIdWithError = (state) => getStateParam(state, "firstElementIdWithError");
export const getForEachParameters = (state) => getStateParam(state, "forEachParameters");
export const getForEachElementParametersByPath = (state, path) => {
    const forEachParameters = getForEachParameters(state);
    return Object.keys(forEachParameters).reduce((res, forEachParameterId) => {
        const parameterData = forEachParameters[forEachParameterId];
        const parameterPath = getValueByPath(parameterData, "path");
        return parameterData.name &&
            parameterPath.length < path.length &&
            path.substring(0, parameterPath.length) === parameterPath
            ? [...res, forEachParameters[parameterData.id]]
            : res;
    }, []);
};
export const getEditorParameterListByPath = (state, path) => {
    const forEachElementParameters = getForEachElementParametersByPath(state, path);
    const extractFromResponseParametersData = getExtractFromResponseParameters(state);
    const extractorParameters = Object.keys(extractFromResponseParametersData).reduce((res, elementId) => [...res, ...extractFromResponseParametersData[elementId]], []);
    return [
        ...forEachElementParameters,
        ...extractorParameters.filter((extractorParameter) => extractorParameter.path < path),
        ...getRequestGlobalParametersList(state)
    ];
};
export const getEditorTimers = (state) => getStateParam(state, "timers");
export const getEditorTreeData = (state) => getStateParam(state, "treeData");
export const getElements = (state) => getStateParam(state, "elements");
export const getElementIds = (state) => Object.keys(getElements(state));
export const getElementById = (state, id) => {
    const elements = getElements(state);
    return elements && elements[id] ? elements[id] : {};
};
export const getExpandedKeys = (state) => getStateParam(state, "expandedKeys");
export const getTransactionElements = (state) => {
    const elements = getElements(state);
    return Object.keys(elements).reduce((res, elementId) => getThreadGroupElementControllerType(elements[elementId]) === ControllerType.Transaction
        ? [...res, elements[elementId]]
        : res, []);
};
