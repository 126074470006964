import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { copyElementDataToClipboard } from "@boomq/utils";
import {
    authorizeActions,
    clientActions,
    getClientInfo,
    getCurrentLanguage,
    getSecretTokenData,
    getSiteModal,
    isEmpty,
    media,
    replaceSecretTokenModal,
    ConfirmForm,
    Switcher,
    IconCopy,
    StyledButton
} from "@boomq/common";

const { generateSecretTokenRequest, secretTokenRequest } = authorizeActions;
const { changeParamsRequest } = clientActions;

const connector = connect(
    (state) => ({
        clientInfo: getClientInfo(state),
        currentLanguage: getCurrentLanguage(state),
        secretToken: getSecretTokenData(state)
    }),
    { changeParamsRequest, generateSecretTokenRequest, getSiteModal, secretTokenRequest }
);

const getClientEmail = (props) => (props && props.clientInfo && props.clientInfo.email ? props.clientInfo.email : "");

const ProfileForm = (props) => {
    const pseudoInputRef = useRef(null);
    const [emailNotification, setEmailNotification] = useState(
        !isEmpty(props.clientInfo.notificationEnabled) ? props.clientInfo.notificationEnabled : true
    );

    useEffect(() => {
        !isEmpty(props.clientInfo.notificationEnabled) && setEmailNotification(props.clientInfo.notificationEnabled);
    }, [props.clientInfo.notificationEnabled]);

    useEffect(() => {
        props.secretTokenRequest();
    }, []);

    const submitHandler = (event) => {
        event.preventDefault();

        const { clientInfo, currentLanguage } = props;

        props.changeParamsRequest({
            authorityList: clientInfo.authorityList,
            email: clientInfo.email,
            id: clientInfo.id,
            language: currentLanguage.toUpperCase(),
            notificationEnabled: emailNotification
        });
    };

    const changeEmailNotificationHandler = ({ target: { checked } }) => setEmailNotification(checked);

    const copySecretToken = (e) => {
        e.preventDefault();
        e.stopPropagation();

        copyElementDataToClipboard(pseudoInputRef ? pseudoInputRef.current : null);
    };

    const replaceSecretToken = (e) => {
        e.preventDefault();
        e.stopPropagation();

        props.secretToken && props.secretToken.length > 0
            ? props.getSiteModal(
                  replaceSecretTokenModal({
                      element: ConfirmForm,
                      confirmAction: props.generateSecretTokenRequest
                  })
              )
            : props.generateSecretTokenRequest();
    };

    return (
        <Form onSubmit={submitHandler}>
            <Label>
                <Text>Email</Text>

                {getClientEmail(props)}
            </Label>

            <Label>
                <Text isLimitWidth={true}>
                    <FormattedMessage
                        defaultMessage="Receive notification by email when a test is started"
                        id="account.user.profile.email.notification"
                    />
                </Text>

                <Switcher
                    defaultChecked={emailNotification}
                    name="emailNotification"
                    onClick={changeEmailNotificationHandler}
                />
            </Label>

            <LabelDiv>
                <Text isLimitWidth={true} theme={{ marginBottom: "10px" }}>
                    <FormattedMessage defaultMessage="API token" id="account.user.profile.secret.token" />
                </Text>

                <SecretTokenSection>
                    <InputWithCopy>
                        <SecretTokenInput ref={pseudoInputRef} title={props.secretToken}>
                            {props.secretToken}
                        </SecretTokenInput>

                        <StyledCopyButton onClick={copySecretToken}>
                            <StyledIconCopy />
                        </StyledCopyButton>
                    </InputWithCopy>

                    <StyledButton onClick={replaceSecretToken} theme={customReplaceButtonTheme}>
                        <FormattedMessage
                            defaultMessage="Replace"
                            id="account.user.profile.button.replace.secret.token"
                        />
                    </StyledButton>
                </SecretTokenSection>
            </LabelDiv>

            <ButtonWrapper>
                <StyledButton>
                    <FormattedMessage id="account.user.profile.button" defaultMessage="Save" />
                </StyledButton>
            </ButtonWrapper>
        </Form>
    );
};

const customReplaceButtonTheme = {
    lineHeight: "25px",
    marginBottom: "10px",
    maxHeight: "45px",
    padding: "8px 30px !important"
};

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 50px;
`;

const Form = styled.form`
    padding-top: 38px;
`;

const InputWithCopy = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    margin-right: 6px;
    max-width: 290px;
    min-width: 290px;
    position: relative;
`;

const Label = styled.label`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-top: 22px;
    position: relative;
    width: fit-content;
    &:first-child {
        padding-top: 0;
    }
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
        > label {
            margin-top: 15px;
        }
    }
`;

const LabelDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-top: 22px;
    position: relative;
    width: fit-content;
    &:first-child {
        padding-top: 0;
    }
    ${media.tablet} {
        align-items: flex-start;
        flex-direction: column;
        > label {
            margin-top: 15px;
        }
    }
`;

const SecretTokenInput = styled.div`
    border: 2px solid #e0e0e0;
    border-radius: 30px;
    box-sizing: border-box;
    color: #396496;
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    min-height: 45px;
    overflow: hidden;
    outline: none;
    padding: 12px 25px;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    &:focus {
        border-color: #176ed6;
    }
`;

const SecretTokenSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${media.tablet} {
        padding-top: 5px;
    }
`;

const StyledCopyButton = styled.div`
    height: 15px;
    position: absolute;
    right: 15px;
`;

const StyledIconCopy = styled(IconCopy)`
    cursor: pointer;
`;

const Text = styled.p`
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    color: #396496;
    min-width: 250px;
    ${({ theme }) => (theme ? css(theme) : css``)}
    ${({ isLimitWidth }) =>
        isLimitWidth &&
        css`
            margin-right: 15px;
            max-width: 235px;
            min-width: 235px;
        `}
    ${media.tablet} {
        max-width: 290px;
        min-width: 290px;
    }
`;

export default withRouter(connector(ProfileForm));
