import { call, takeEvery, select, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getFromLocalStorage, removeFromLocalStorage } from "@boomq/utils";
import {
    authorizeActions,
    checkUnsavedTestProjectBeforeLogout,
    clientActions,
    formatEmail,
    getAuthorizeUser,
    getAuthProviders,
    getCookie,
    getCurrentLanguage,
    getIsAuthorize,
    isEmpty,
    loginActions,
    unauthorizedUserLogout
} from "@boomq/common";
import { checkTeams } from "./team";
import store from "../index";

const { authRequest, authSuccess, authFailure, logoutWithCheckingOfUnsavedTestProject, setAuthProviders } =
    authorizeActions;
const { changeParamsRequest } = clientActions;

function* updateUserParams(action) {
    try {
        const userData = action && action.payload ? action.payload : {};
        const currentLanguage = yield select(getCurrentLanguage);
        const { id, email, firstName, lastName, language, notificationEnabled } = userData || {};

        yield* checkTeams(id);

        return userData && language !== currentLanguage.toUpperCase()
            ? yield put(
                  changeParamsRequest({
                      email: formatEmail(email),
                      firstName,
                      id,
                      language: !isEmpty(currentLanguage) ? currentLanguage.toUpperCase() : language,
                      lastName,
                      notificationEnabled: isEmpty(notificationEnabled) ? true : notificationEnabled
                  })
              )
            : undefined;
    } catch (e) {
        console.error(e);
    }
}

function* redirectToActiveUrl(activeUrl) {
    yield put(push(activeUrl));

    removeFromLocalStorage("activeUrl");
}

function* redirectToDefaultPage() {
    const currentLocation = window && window.location ? window.location.pathname : "";

    return ["/account", "/account/new-test"].includes(currentLocation) ? undefined : yield put(push("/account"));
}

function* redirectByAuthSuccess(activeUrlData) {
    const activeUrl = activeUrlData || getFromLocalStorage("activeUrl");

    return activeUrl ? yield* redirectToActiveUrl(activeUrl) : yield* redirectToDefaultPage();
}

function* authorizationSuccess({ token, activeUrl }) {
    yield loginActions(token);

    const authResponse = token ? yield call(getAuthorizeUser) : {};
    const data = authResponse && authResponse.data ? authResponse.data : {};

    const authProvidersResponse = token ? yield call(getAuthProviders) : {};
    const authProviders = authProvidersResponse && authProvidersResponse.data ? authProvidersResponse.data : [];

    authProviders && (yield put(setAuthProviders(authProviders)));

    yield* redirectByAuthSuccess(activeUrl);

    // data && (yield* updateUserParams(data));

    return data
        ? yield put(authSuccess(data))
        : yield put(
              authFailure({
                  defaultMessage: "There were problems, please try again later.",
                  id: "website.authorize.error.server"
              })
          );
}

function* authorizeFlow(payload) {
    const error = {
        defaultMessage: "There were problems, please try again later.",
        id: "website.authorize.error.server"
    };

    try {
        const token = getCookie("boomq_auth");
        let { activeUrl } = payload ? payload : {};

        token ? yield* authorizationSuccess({ activeUrl, token }) : yield put(authFailure(error));
    } catch (e) {
        yield put(authFailure(error));
    }
}

function* authRequestFlow(action) {
    const isAuthorize = yield select(getIsAuthorize);

    !isAuthorize && (yield* authorizeFlow(action ? action.payload : {}));
}

function* logoutWithCheckingOfUnsavedTestProjectFlow() {
    try {
        const isAuthorized = yield select(getIsAuthorize);

        return isAuthorized ? yield* checkUnsavedTestProjectBeforeLogout(store) : yield* unauthorizedUserLogout();
    } catch (e) {
        console.error(e);
    }
}

export function* authFlow() {
    yield takeEvery(authRequest, authRequestFlow);
    yield takeEvery(authSuccess, updateUserParams);
    yield takeEvery(logoutWithCheckingOfUnsavedTestProject, logoutWithCheckingOfUnsavedTestProjectFlow);
}
