var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { EMAIL_INPUT_PLACEHOLDER } from "./intl";
import { AuthForm, AuthErrorMessage, AuthInput, AuthLabel } from "../../../components/styled/Auth";
import { formatIntlMessage } from "../../../helpers/intl";
import { registrationLoginValidator } from "../../../helpers/validators";
import { resetError } from "../../../redux/actions/error";
import { resetRequest } from "../../../redux/actions/reset";
const connector = connect(null, { onSubmit: resetRequest, onUnmount: resetError });
const ResetFormContainer = (_a) => {
    var _b;
    var { children, onSubmit, onUnmount, type } = _a, restProps = __rest(_a, ["children", "onSubmit", "onUnmount", "type"]);
    const { formatMessage } = useIntl();
    const [login, setLogin] = useState("");
    const [errors, setErrors] = useState({});
    useEffect(() => {
        return () => onUnmount === null || onUnmount === void 0 ? void 0 : onUnmount();
    }, []);
    const changeLoginHandler = ({ target: { value } }) => setLogin(value);
    const resetPassRequest = () => {
        setLogin("");
        return onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit({ login });
    };
    const submitHandler = (event) => {
        event.preventDefault();
        const { isValid, errors } = registrationLoginValidator(login);
        setErrors(errors);
        return isValid ? resetPassRequest() : undefined;
    };
    const emailInputPlaceholder = formatIntlMessage(EMAIL_INPUT_PLACEHOLDER, formatMessage);
    return (React.createElement(AuthForm, Object.assign({}, restProps, { onSubmit: submitHandler }),
        React.createElement(AuthLabel, null,
            React.createElement(AuthInput, { isError: !!(errors === null || errors === void 0 ? void 0 : errors.confirmPassword), name: "email", value: login, onChange: changeLoginHandler, type: "text", placeholder: emailInputPlaceholder }),
            ((_b = errors === null || errors === void 0 ? void 0 : errors.login) === null || _b === void 0 ? void 0 : _b.id) && React.createElement(AuthErrorMessage, null, formatMessage(errors.login))),
        children));
};
export default connector(ResetFormContainer);
