var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { render } from "react-dom";
import Table from "@editorjs/table";
import { getUniqueId } from "@boomq/utils";
import { PFLBTableWithIntl } from "../../../../containers/account/Report/PFLBTable";
import "./pflbTable.css";
const pflbTableSettingsIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-width="2" d="M10 5V18.5"></path><path stroke="currentColor" stroke-width="2" d="M5 10H19"></path><rect width="14" height="14" x="5" y="5" stroke="currentColor" stroke-width="2" rx="4"></rect></svg>';
export class PFLBTable extends Table {
    static get toolbox() {
        return {
            title: "PFLB table",
            icon: pflbTableSettingsIcon
        };
    }
    constructor(_a) {
        var { pflbTableId } = _a, restParams = __rest(_a, ["pflbTableId"]);
        const { data, config, api } = restParams;
        super(restParams);
        this._config = config;
        this._data = data;
        this._api = api;
        this.isShowParamsSidebar = false;
        this.pflbTableId = pflbTableId || getUniqueId();
    }
    removed() {
        return this._config && typeof this._config.onDeleteBlock === "function"
            ? this._config.onDeleteBlock(this.pflbTableId)
            : undefined;
    }
    render() {
        const tableElement = super.render();
        tableElement.id = this.getTableId();
        return this.renderPFLBTableElement(tableElement);
    }
    renderPFLBTableElement(tableElement) {
        const tableNode = this._api.ui.nodes.redactor.querySelector(`#${this.getTableId()}`);
        return tableNode ? this.refreshTableElement(tableNode, tableElement) : this.addPFLBTableElement(tableElement);
    }
    addPFLBTableElement(tableElement) {
        const rootNode = document.createElement("div");
        rootNode.classList.add("pflb-table");
        rootNode.id = this.getPFLBTableId();
        return this.renderPFLBTableElementInNode(rootNode, tableElement);
    }
    refreshTableElement(tableNode, tableElement) {
        tableNode.replaceWith(tableElement);
    }
    renderPFLBTableElementInNode(node, tableElement) {
        render(React.createElement(PFLBTableWithIntl, { id: this.pflbTableId, isShowParamsSidebar: this.isShowParamsSidebar, onRefresh: (reportTable) => this.refreshTableData(reportTable), onToggleTableParamsSidebar: () => this.toggleTableParamsSidebar() }), node);
        node.appendChild(tableElement);
        return node;
    }
    getPFLBTableId() {
        return `pflb-table-${this.pflbTableId}`;
    }
    getTableId() {
        return `table-${this.pflbTableId}`;
    }
    refreshTableData(reportTable) {
        super.data = reportTable.tableData;
        this._data = reportTable;
        this.render();
    }
    toggleTableParamsSidebar() {
        return this.isShowParamsSidebar ? this.hideTableParamsSidebar() : this.showTableParamsSidebar();
    }
    hideOtherBlocksParams() {
        const blocksCount = this.api.blocks.getBlocksCount();
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        for (let i = 0; i < blocksCount; i++) {
            const block = this.api.blocks.getBlockByIndex(i);
            if (currentBlockIndex !== i) {
                block.call("hideChartParamsSidebar");
                block.call("hideTableParamsSidebar");
            }
        }
    }
    hideTableParamsSidebar() {
        const tableParamsSidebarDiv = this.getTableParamsSidebarDiv();
        this.isShowParamsSidebar = false;
        return tableParamsSidebarDiv ? tableParamsSidebarDiv.classList.remove("show") : undefined;
    }
    showTableParamsSidebar() {
        const tableParamsSidebarDiv = this.getTableParamsSidebarDiv();
        this.isShowParamsSidebar = true;
        this.hideOtherBlocksParams();
        return tableParamsSidebarDiv ? tableParamsSidebarDiv.classList.add("show") : undefined;
    }
    getTableParamsSidebarDiv() {
        return document.querySelector(`#${this.getPFLBTableId()} .pflb-sidebar-settings`);
    }
    renderSettings() {
        return [
            {
                label: this._api.i18n.t("Table params"),
                icon: pflbTableSettingsIcon,
                isActive: this.isShowParamsSidebar,
                closeOnActivate: true,
                toggle: true,
                onActivate: () => this.toggleTableParamsSidebar()
            },
            ...super.renderSettings()
        ];
    }
    save() {
        return Object.assign(Object.assign({}, this._data), { tableData: super.save() });
    }
}
