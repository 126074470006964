var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { THREAD_GROUP_NAME_SELECT_LABEL, THREAD_GROUP_NAME_SELECT_PLACEHOLDER, TRANSACTION_NAME_SELECT_LABEL, TRANSACTION_NAME_SELECT_PLACEHOLDER } from "./intl";
import { ChartSerieCommonParamsContainer } from "../ChartSerieCommonParams";
import { ChartSelectWithMarginTop } from "../../../../components/account/Report/ChartSerieCommonParams";
import { formatIntlMessage } from "../../../../helpers/intl";
import { generateReportChartTransactionMetricTitle, getItemByValue } from "../../../../helpers/reportEditor";
import { refreshReportChartSerieTransactionList } from "../../../../redux/actions/report";
import { getReportChartSerieThreadGroupListByTestId, getReportChartSerieTransactionListByTestId } from "../../../../redux/reducers/report";
const connector = connect((state, ownProps) => ({
    threadGroupItems: getReportChartSerieThreadGroupListByTestId(state, ownProps === null || ownProps === void 0 ? void 0 : ownProps.testId),
    transactionItems: getReportChartSerieTransactionListByTestId(state, ownProps === null || ownProps === void 0 ? void 0 : ownProps.testId)
}), {
    onChangeTest: refreshReportChartSerieTransactionList,
    onInit: refreshReportChartSerieTransactionList
});
export const ChartSerieTransactionParamsContainer = (_a) => {
    var { onChangeChartSerieTest, onChangeTest, onClickApply, onInit, threadGroupItems, transactionFilter, transactionItems } = _a, restProps = __rest(_a, ["onChangeChartSerieTest", "onChangeTest", "onClickApply", "onInit", "threadGroupItems", "transactionFilter", "transactionItems"]);
    const [filteredThreadGroupItems, setFilteredThreadGroupItems] = useState(threadGroupItems);
    const [threadGroupName, setThreadGroupName] = useState(transactionFilter === null || transactionFilter === void 0 ? void 0 : transactionFilter.threadGroupName);
    const [transactionName, setTransactionName] = useState(transactionFilter === null || transactionFilter === void 0 ? void 0 : transactionFilter.transactionName);
    const { formatMessage } = useIntl();
    useEffect(() => {
        const init = () => onInit === null || onInit === void 0 ? void 0 : onInit(getItemByValue(restProps.testList, restProps.testId));
        init();
    }, [restProps.testId, restProps.testList]);
    useEffect(() => {
        const { groupIds } = getItemByValue(transactionItems, transactionName);
        const filteredThreadGroupItems = threadGroupItems.filter(({ id }) => (groupIds || []).includes(id));
        setFilteredThreadGroupItems(filteredThreadGroupItems);
    }, [threadGroupItems, transactionName, transactionItems]);
    const changeChartSerieTestHandler = (data) => {
        onChangeTest === null || onChangeTest === void 0 ? void 0 : onChangeTest(data);
        return onChangeChartSerieTest === null || onChangeChartSerieTest === void 0 ? void 0 : onChangeChartSerieTest(data);
    };
    const changeThreadGroupHandler = (data) => setThreadGroupName(data.value);
    const changeTransactionNameHandler = (data) => {
        setTransactionName(data.value);
        setThreadGroupName("");
    };
    const clickApplyHandler = (data) => onClickApply(Object.assign(Object.assign({}, data), { threadGroupName,
        transactionName, chartTitle: generateReportChartTransactionMetricTitle(Object.assign(Object.assign({}, data), { chartTypeItems: restProps.chartTypeItems, threadGroupName,
            transactionName, metricTypeItems: restProps.metricTypeItems }), formatMessage) }));
    const threadGroupNameSelectLabel = formatIntlMessage(THREAD_GROUP_NAME_SELECT_LABEL, formatMessage);
    const threadGroupNameSelectPlaceholder = formatIntlMessage(THREAD_GROUP_NAME_SELECT_PLACEHOLDER, formatMessage);
    const transactionNameSelectLabel = formatIntlMessage(TRANSACTION_NAME_SELECT_LABEL, formatMessage);
    const transactionNameSelectPlaceholder = formatIntlMessage(TRANSACTION_NAME_SELECT_PLACEHOLDER, formatMessage);
    return (React.createElement(ChartSerieCommonParamsContainer, Object.assign({}, restProps, { isDisabledApplyButton: !transactionName || !threadGroupName, onChangeChartSerieTest: changeChartSerieTestHandler, onClickApply: clickApplyHandler }),
        React.createElement(ChartSelectWithMarginTop, { defaultValue: transactionName, items: transactionItems, label: transactionNameSelectLabel, onChange: changeTransactionNameHandler, placeholder: transactionNameSelectPlaceholder }),
        React.createElement(ChartSelectWithMarginTop, { defaultValue: threadGroupName, items: filteredThreadGroupItems, label: threadGroupNameSelectLabel, onChange: changeThreadGroupHandler, placeholder: threadGroupNameSelectPlaceholder })));
};
export default connector(ChartSerieTransactionParamsContainer);
