var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getContrastColorByIndex, getUnique, isEmpty } from "@boomq/utils";
import { DEFAULT_REPORT_NAME } from "./constants";
import { getTestProjectVersionData } from "../testProjects";
import { hideLoader, showLoader } from "../../actions/loader";
import { addReportPflbChartSerie, addReportPflbTable, changeReportPflbChartProperties, changeReportPflbChartSerie, changeReportPflbTable, createReportFailure, createReportRequest, createReportSuccess, generateReportPflbChartSeriePreviewFailure, generateReportPflbChartSeriePreviewRequest, generateReportPflbChartSeriePreviewSuccess, generateReportPflbTableFailure, generateReportPflbTableRequest, initReport, refreshReportChartSerieTransactionList, refreshReportDataFailure, refreshReportDataRequest, refreshReportDataSuccess, setReportParams, updateReportFailure, updateReportRequest, updateReportSuccess } from "../../actions/report";
import { getThreadGroupListsByTestId, getReportChartDataById, getReportChartSeriesList, getReportData, getReportTableDataById, getTransactionListsByTestId, isExistTransactionListByTestId } from "../../reducers/report";
import { getResponseErrorData } from "../../../helpers/errors";
import { generateReportChartSerie, generateReportDataForSaving } from "../../../helpers/generators";
import { parseReportChartData, parseReportData } from "../../../helpers/parsers";
import { modifySerieDataByAxisUnit } from "../../../helpers/report";
import { DEFAULT_AGGREGATION_INTERVAL_VALUE, isExistIdenticalChartSerie, prepareReportChartGroupListFromVersionData, prepareReportChartTransactionListByTestType } from "../../../helpers/reportEditor";
import { prepareClientTestList } from "../../../helpers/slaReport";
import { getReportTableDataGeneratorByType } from "../../../models/reportEditor";
import { getReportsRoute } from "../../../helpers/routes";
import { TestDisplayStatus } from "../../../models/testRun";
import { apiCreateReport, apiGenerateReportChartPreview, apiGetReport, apiGetReportContent, apiUpdateReport } from "../../../services/reportSrv";
import { getClientTests } from "../../../services/testSrv";
function* prepareReportDataForSaving(payload) {
    const { blocks } = payload || {};
    const { charts, reportName, series, tables } = yield select(getReportData);
    return generateReportDataForSaving({ blocks, charts, reportName, series, tables });
}
function* createReportFlow({ payload }) {
    try {
        const reportDataForSaving = yield* prepareReportDataForSaving(payload);
        const { data } = yield call(apiCreateReport, reportDataForSaving);
        yield put(push(`${getReportsRoute()}/${data === null || data === void 0 ? void 0 : data.id}`));
        yield put(createReportSuccess());
    }
    catch (e) {
        yield put(createReportFailure(getResponseErrorData(e)));
    }
}
function* refreshReportChartSerieTransactionListByTestData({ testProjectId, testProjectVersionId, value }) {
    try {
        yield put(showLoader());
        const _a = yield* getTestProjectVersionData({
            id: testProjectId,
            version: testProjectVersionId
        }), { groups } = _a, restTestProjectVersionData = __rest(_a, ["groups"]);
        const currentGroupListsByTestId = yield select(getThreadGroupListsByTestId);
        const currentTransactionListsByTestId = yield select(getTransactionListsByTestId);
        yield put(setReportParams({
            threadGroupListsByTestId: Object.assign(Object.assign({}, currentGroupListsByTestId), { [value]: prepareReportChartGroupListFromVersionData(groups) }),
            transactionListsByTestId: Object.assign(Object.assign({}, currentTransactionListsByTestId), { [value]: prepareReportChartTransactionListByTestType(restTestProjectVersionData) })
        }));
        yield put(hideLoader());
    }
    catch (e) {
        yield put(hideLoader());
    }
}
function* refreshReportChartSerieTransactionListsByTest({ payload }) {
    try {
        const isExistTransactionList = yield select(isExistTransactionListByTestId, payload === null || payload === void 0 ? void 0 : payload.value);
        return isExistTransactionList ? undefined : yield* refreshReportChartSerieTransactionListByTestData(payload);
    }
    catch (e) {
        return;
    }
}
function* generateReportPflbChartSeriePreview(_a) {
    var _b;
    var { axisUnit, chartId, chartSeriesList, chartTitle, existedChartSerie, id, testId, threadGroupName, transactionName, unit } = _a, restParams = __rest(_a, ["axisUnit", "chartId", "chartSeriesList", "chartTitle", "existedChartSerie", "id", "testId", "threadGroupName", "transactionName", "unit"]);
    try {
        const existedReportChartData = yield select(getReportChartDataById, chartId);
        const { axisLeftY, axisRightY, isTitleTyped, title } = existedReportChartData || {};
        const { data } = yield call(apiGenerateReportChartPreview, {
            id: chartId,
            name: `report-chart-${chartId}-serie-${id}`,
            series: [
                generateReportChartSerie(Object.assign(Object.assign({}, restParams), { aggregationInterval: DEFAULT_AGGREGATION_INTERVAL_VALUE, displayProps: {
                        axisUnit,
                        color: (_b = existedChartSerie === null || existedChartSerie === void 0 ? void 0 : existedChartSerie.displayProps) === null || _b === void 0 ? void 0 : _b.color,
                        unit
                    }, id,
                    testId, transactionFilter: {
                        threadGroupName,
                        transactionName
                    } }))
            ],
            testFilters: [{ testId }]
        });
        const { series } = parseReportChartData(data);
        yield put(generateReportPflbChartSeriePreviewSuccess(Object.assign(Object.assign({}, series[0]), { displayProps: Object.assign(Object.assign({}, series[0].displayProps), { title: chartTitle }) })));
        const units = getUnique([...chartSeriesList.map((serieData) => { var _a; return (_a = serieData === null || serieData === void 0 ? void 0 : serieData.displayProps) === null || _a === void 0 ? void 0 : _a.unit; }), unit].filter((unit) => !!unit));
        yield put(changeReportPflbChartProperties({
            id: chartId,
            properties: {
                axisLeftY: axisLeftY || units[0],
                axisRightY: axisRightY || units[1],
                title: isTitleTyped ? title : chartTitle
            }
        }));
    }
    catch (e) {
        yield put(generateReportPflbChartSeriePreviewFailure(getResponseErrorData(e)));
    }
}
function* updateExistedReportChartSeriePreview({ axisUnit, chartId, chartTitle, existedChartSerie }) {
    try {
        const existedReportChartData = yield select(getReportChartDataById, chartId);
        const serieData = modifySerieDataByAxisUnit(existedChartSerie, axisUnit);
        yield put(changeReportPflbChartProperties({
            id: chartId,
            properties: {
                title: (existedReportChartData === null || existedReportChartData === void 0 ? void 0 : existedReportChartData.isTitleTyped) ? existedReportChartData.title : chartTitle
            }
        }));
        yield put(generateReportPflbChartSeriePreviewSuccess(Object.assign(Object.assign({}, serieData), { displayProps: Object.assign(Object.assign({}, serieData.displayProps), { title: (existedChartSerie === null || existedChartSerie === void 0 ? void 0 : existedChartSerie.isTitleTyped) ? existedChartSerie.title : chartTitle }) })));
    }
    catch (e) {
        yield put(hideLoader());
    }
}
function* reportChartSeriePreviewGenerationFlow(action) {
    var _a, _b;
    try {
        const chartId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.chartId;
        const serieId = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.id;
        const chartSeriesList = yield select(getReportChartSeriesList, chartId);
        const existedChartSerie = chartSeriesList.find(({ id }) => id === serieId);
        return action.payload && isExistIdenticalChartSerie(existedChartSerie, action.payload)
            ? yield* updateExistedReportChartSeriePreview(Object.assign(Object.assign({}, action.payload), { existedChartSerie }))
            : yield* generateReportPflbChartSeriePreview(Object.assign(Object.assign({}, action.payload), { chartSeriesList, existedChartSerie }));
    }
    catch (e) {
        yield put(generateReportPflbChartSeriePreviewFailure(getResponseErrorData(e)));
    }
}
function* reportChartSerieColorGenerationFlow(action) {
    var _a, _b;
    try {
        const chartId = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.chartId;
        const serieId = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.id;
        const chartSeriesList = yield select(getReportChartSeriesList, chartId);
        const excludedColors = (chartSeriesList || []).map((chartSerie) => { var _a; return (_a = chartSerie === null || chartSerie === void 0 ? void 0 : chartSerie.displayProps) === null || _a === void 0 ? void 0 : _a.color; });
        const color = getContrastColorByIndex(0, excludedColors);
        return yield put(changeReportPflbChartSerie({ id: serieId, displayProps: { color } }));
    }
    catch (e) {
        return;
    }
}
function* reportTestListGenerationFlow() {
    try {
        yield put(showLoader());
        const { data } = yield call(getClientTests, { displayState: TestDisplayStatus.Finished });
        yield put(setReportParams({ testList: prepareClientTestList(data.content) }));
        yield put(hideLoader());
    }
    catch (e) {
        yield put(hideLoader());
    }
}
function* reportInitializationFlow() {
    try {
        yield put(setReportParams({ reportName: DEFAULT_REPORT_NAME }));
        yield* reportTestListGenerationFlow();
    }
    catch (e) {
        return;
    }
}
function* reportTableGenerationFlow(action) {
    try {
        const _a = action.payload, { tableId, tableTitle, type } = _a, restPayload = __rest(_a, ["tableId", "tableTitle", "type"]);
        const existedReportTableData = yield select(getReportTableDataById, tableId);
        const tableData = yield* getReportTableDataGeneratorByType(type)(action.payload);
        const reportTableData = Object.assign({ id: tableId, tableData, title: tableTitle, type }, restPayload);
        yield put(hideLoader());
        return isEmpty(existedReportTableData)
            ? yield put(addReportPflbTable(reportTableData))
            : yield put(changeReportPflbTable(Object.assign(Object.assign(Object.assign({}, existedReportTableData), reportTableData), { title: existedReportTableData.isTitleTyped ? existedReportTableData.title : reportTableData.title })));
    }
    catch (e) {
        yield put(generateReportPflbTableFailure(getResponseErrorData(e)));
    }
}
function* reportDataRefreshingFlow({ payload }) {
    var _a;
    try {
        const { data: reportData } = yield call(apiGetReport, payload);
        const { data: reportContentData } = yield call(apiGetReportContent, payload);
        yield put(refreshReportDataSuccess(Object.assign({ id: payload, reportName: (_a = reportData === null || reportData === void 0 ? void 0 : reportData.name) !== null && _a !== void 0 ? _a : "" }, parseReportData(reportContentData))));
        yield* reportTestListGenerationFlow();
    }
    catch (e) {
        yield put(refreshReportDataFailure(getResponseErrorData(e)));
    }
}
function* updateReportFlow({ payload }) {
    try {
        const reportDataForSaving = yield* prepareReportDataForSaving(payload);
        yield call(apiUpdateReport, payload.id, reportDataForSaving);
        yield put(updateReportSuccess());
    }
    catch (e) {
        yield put(updateReportFailure(getResponseErrorData(e)));
    }
}
export function* reportFlow() {
    yield takeEvery(addReportPflbChartSerie, reportChartSerieColorGenerationFlow);
    yield takeEvery(refreshReportChartSerieTransactionList, refreshReportChartSerieTransactionListsByTest);
    yield takeEvery(createReportRequest, createReportFlow);
    yield takeEvery(generateReportPflbChartSeriePreviewRequest, reportChartSeriePreviewGenerationFlow);
    yield takeEvery(generateReportPflbTableRequest, reportTableGenerationFlow);
    yield takeEvery(initReport, reportInitializationFlow);
    yield takeEvery(refreshReportDataRequest, reportDataRefreshingFlow);
    yield takeEvery(updateReportRequest, updateReportFlow);
}
