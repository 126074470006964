import React, { Component } from "react";
import { getValueByPath, isEmpty } from "@boomq/utils";
import { PlateWithSocketPadding } from "./styled";
import Title from "../Title";
import { Padding } from "../../styled/Wrappers";
import Fade from "../../animation/Fade";
import { TestDetailsBlocksContainer } from "../../../containers/account/TestDetails/TestDetailsBlocks";
import { TestDetailsButtonsContainer } from "../../../containers/account/TestDetails/TestDetailsButtons";
import { TestDetailsPlateWithSocket } from "../../../containers/account/TestDetails/TestDetailsPlateWithSocket";
import { generateTestTitle } from "../../../helpers/testDetails";
import { isTestDuringPreparing } from "../../../helpers/testStatus";
import { getSiteTitle } from "../../../helpers/title";
export class CommonTestDetails extends Component {
    constructor() {
        super(...arguments);
        this.renderTestDetailsBodyByStatus = (testDetails) => testDetails && isTestDuringPreparing(testDetails.displayState) ? (React.createElement(PlateWithSocketPadding, null,
            React.createElement(TestDetailsPlateWithSocket, { estimatedStartTime: this.props.estimatedStartTime, isShowEstimatedTime: this.props.isShowEstimatedTime, task: this.props.testDetails }))) : (React.createElement(React.Fragment, null,
            React.createElement(TestDetailsButtonsContainer, null, this.props.children),
            React.createElement(Padding, null,
                React.createElement(TestDetailsBlocksContainer, null))));
        this.checkAuthorization = (nextProps) => (nextProps.isAuthorized !== this.props.isAuthorized ||
            nextProps.selectedTeamId !== this.props.selectedTeamId) &&
            this.props.isAuthorized &&
            (!isEmpty(nextProps.selectedTeamId) || !isEmpty(this.props.selectedTeamId))
            ? this.getTestDetailsData(nextProps)
            : undefined;
        this.checkProjectData = (nextProps) => {
            const { testDetails: { startDate }, projectName } = nextProps;
            return startDate !== this.props.testDetails.startDate || projectName !== this.props.projectName
                ? this.props.setTitle(React.createElement(Title, { text: generateTestTitle(projectName, startDate) }))
                : undefined;
        };
        this.checkRoute = (nextProps) => {
            const { test_project_id: nextTestProjectId, test_id: nextTestId } = getValueByPath(nextProps, "match.params", {});
            const { test_project_id, test_id } = getValueByPath(this.props, "match.params", {});
            return nextTestProjectId !== test_project_id || nextTestId !== test_id
                ? this.getTestDetailsData(nextProps)
                : undefined;
        };
        this.getTestDetailsData = (props) => {
            const { test_project_id, test_id } = getValueByPath(props, "match.params", {});
            this.refreshTestProjectData({ id: test_project_id });
            this.refreshTestDetailsData({ testProjectId: test_project_id, testId: test_id });
        };
        this.onUnmount = () => (typeof this.props.onUnmount === "function" ? this.props.onUnmount() : undefined);
        this.refreshTestDetailsData = (params) => typeof this.props.onRefreshTestDetailsData === "function"
            ? this.props.onRefreshTestDetailsData(params)
            : undefined;
        this.refreshTestProjectData = (params) => typeof this.props.onRefreshTestProjectData === "function"
            ? this.props.onRefreshTestProjectData(params)
            : undefined;
    }
    componentDidMount() {
        return this.props.isAuthorized && !isEmpty(this.props.selectedTeamId)
            ? this.getTestDetailsData(this.props)
            : undefined;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.checkAuthorization(nextProps);
        this.checkProjectData(nextProps);
        this.checkRoute(nextProps);
    }
    componentWillUnmount() {
        this.onUnmount();
        return typeof this.props.clearNewTestSettings === "function" ? this.props.clearNewTestSettings() : undefined;
    }
    render() {
        const { testDetails } = this.props;
        document.title = getSiteTitle(testDetails && testDetails.siteUrl);
        return React.createElement(Fade, { animation: "inRight" }, this.renderTestDetailsBodyByStatus(testDetails));
    }
}
