import { handleActions } from "redux-actions";
import { LOCATION_CHANGE } from 'react-router-redux';
export const initialState = {
    currentLocation: null,
    location: null,
    previousLocation: null,
};
export default handleActions({
    [LOCATION_CHANGE]: (state, action) => (Object.assign(Object.assign({}, state), { currentLocation: action.payload.pathname, location: action.payload, previousLocation: state.currentLocation })),
}, initialState);
export const getPreviousRouterLocation = (state) => state.router.previousLocation;
