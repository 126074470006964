var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { HEADER_TITLE_ID, PAGE_TITLE, SUBTITLE } from "./intl";
import { InfoWrapper, Plate, PlateWrapper, Separator, SubTitle } from "./styled";
import { Fade } from "../../../animation";
import { getSiteTitle } from "../../../../helpers/title";
import { setTitle } from "../../../../redux/actions/accountTitle";
import { getClientInfo } from "../../../../redux/reducers/authorizePlate";
const connector = connect((state) => ({
    clientInfo: getClientInfo(state)
}), { setTitle });
const Profile = (_a) => {
    var { children, setTitle } = _a, restProps = __rest(_a, ["children", "setTitle"]);
    const { formatMessage } = useIntl();
    useEffect(() => {
        document.title = getSiteTitle(formatMessage(PAGE_TITLE));
        setTitle(HEADER_TITLE_ID);
    }, []);
    return (React.createElement(Fade, { animation: "inUp" },
        React.createElement(PlateWrapper, null,
            React.createElement(Plate, null,
                React.createElement(Separator, null),
                React.createElement(InfoWrapper, null,
                    React.createElement(SubTitle, null, formatMessage(SUBTITLE)),
                    children && React.cloneElement(children, restProps))))));
};
export default connector(Profile);
