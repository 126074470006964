import React from "react";
import { FormattedMessage } from "react-intl";
import { passwordTooltipTheme, PassInput, StyledHelp } from "./styled";
import { AuthErrorMessage, AuthInput, AuthLabel } from "../../styled/Auth";
export const AuthorizeFormInputs = ({ errors, login, loginInputPlaceholder, onBlurLogin, onChangeLogin, onChangePassword, password, passwordInputPlaceholder, passwordTooltip }) => (React.createElement(React.Fragment, null,
    React.createElement(AuthLabel, null,
        React.createElement(AuthInput, { isError: errors.login, name: "login", type: "text", onBlur: onBlurLogin, onChange: onChangeLogin, placeholder: loginInputPlaceholder, value: login }),
        errors.login && (React.createElement(AuthErrorMessage, null,
            React.createElement(FormattedMessage, Object.assign({}, errors.login))))),
    React.createElement(AuthLabel, null,
        React.createElement(PassInput, { isError: !!errors.password, name: "password", type: "password", onChange: onChangePassword, passwordTooltip: passwordTooltip, placeholder: passwordInputPlaceholder, value: password }),
        passwordTooltip && React.createElement(StyledHelp, { theme: passwordTooltipTheme, tooltipText: passwordTooltip }),
        errors.password && (React.createElement(AuthErrorMessage, null,
            React.createElement(FormattedMessage, Object.assign({}, errors.password)))))));
