export const FORM_TITLE = {
    defaultMessage: "Registration",
    id: "website.registration.title"
};

export const SIGN_IN_LINK_LABEL = {
    defaultMessage: "Sign in",
    id: "website.authorize.button"
};

export const SUBMIT_BUTTON_LABEL = {
    defaultMessage: "Register",
    id: "website.authorize.registration"
};
