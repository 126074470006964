var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { load as convertYamlToObj } from "js-yaml";
import { getUniqueId, isEmpty, isEmptyArray, objectToArray } from "@boomq/utils";
import { getTestProjectParamValue } from "./common";
import { parseHttpRequestDefaults } from "./configurationElements";
import { TEST_PROJECT_REQUEST_GROUP_DEFAULT_NAME } from "./constants";
import { prepareTestProjectGroupRequests, prepareTestProjectRequestGroup, prepareThreadGroupData } from "./threadGroup";
import { formatHttpRequestParams, prepareQueryParam } from "./threadGroupSamplerHttp";
import { prepareSlaGroupList } from "./sla";
import { parseTestProjectLoadProfile } from "./ultimateThreadGroup2";
import { parseTestProjectParameters } from "../requestParameters";
import { RequestGroupType } from "../types";
import { ThreadGroupController } from "../../models/threadGroupEditor";
const prepareTestProjectRequestGroupRunnerLocations = (resourceConfiguration) => {
    const { cloudTestRunLocationRequirements } = resourceConfiguration || {};
    return cloudTestRunLocationRequirements && Array.isArray(cloudTestRunLocationRequirements.testRunLocationIds)
        ? {
            runnerLocations: cloudTestRunLocationRequirements.testRunLocationIds
        }
        : {};
};
const prepareCommonTestProjectData = (testProjectData, testProjectYamlData) => (Object.assign(Object.assign({ comment: "", contentModelVersion: getTestProjectParamValue(testProjectData, "contentModelVersion"), currentVersion: testProjectData.currentVersion, httpRequestDefaults: parseHttpRequestDefaults(testProjectYamlData.httpRequestDefaults), projectName: testProjectData.projectName, projectId: testProjectData.projectId, settingsId: testProjectData.settingsId }, prepareTestProjectRequestGroupRunnerLocations(testProjectYamlData.resourceConfiguration)), { sla: prepareSlaGroupList(testProjectYamlData.slaGroupList), testType: testProjectData.testType, versions: testProjectData.versions }));
const prepareJMXTestProjectData = (testProjectData) => {
    const _a = (testProjectData.threadGroups || []).reduce((res, threadGroup, index) => prepareJMXTestProjectDataByThreadGroupType(res, index, threadGroup), {
        extractorParameters: [],
        groups: [],
        JMXProfiles: {},
        requests: [],
        timers: {},
        transactions: [],
        sourceJmxThreadGroups: {},
        elementsData: {}
    }), { requests } = _a, rest = __rest(_a, ["requests"]);
    return Object.assign(Object.assign({}, rest), { requests, JMXTimersSettings: {
            isTimersDisabled: testProjectData.isTimersDisabled || false,
            multiplicationFactor: testProjectData.multiplicationFactor
        } });
};
export const parseJMXTestProjectFromYaml = (testProjectData) => {
    const testProjectYamlData = convertYamlToObj(testProjectData.yaml);
    return Object.assign(Object.assign(Object.assign({ baseJmxFileLocation: testProjectYamlData.baseJmxFileLocation, isRunThreadGroupsConsecutively: testProjectYamlData.isRunThreadGroupsConsecutively }, prepareCommonTestProjectData(testProjectData, testProjectYamlData)), { requestParameters: parseTestProjectParameters(testProjectYamlData.parameters) }), prepareJMXTestProjectData(testProjectYamlData));
};
const prepareStepParams = (step) => ({
    initialDelay: step.initDelaySec / 60,
    rampUp: step.startupTimeSec / 60,
    stepLength: step.holdLoadTimeSec / 60,
    rampDown: step.shutdownTimeSec / 60,
    usersPerStep: step.startThreadsCount
});
const prepareSteps = (steps) => steps && !isEmptyArray(steps)
    ? steps.map((step, index) => (Object.assign(Object.assign({}, prepareStepParams(step)), { changed: step.changed ? true : false, id: getUniqueId(), index: index + 1, name: `step ${index + 1}`, stepName: step.name })))
    : [];
const prepareTransaction = (groupElement, groupId, transactionId) => ({
    id: transactionId,
    groupId,
    name: groupElement.name
});
const getTimersFromRequest = (request) => (request.timerList || []).reduce((res, timer) => (Object.assign(Object.assign({}, res), { [timer.id]: timer })), {});
const parseQueryParamByValue = ({ name, value }) => Array.isArray(value)
    ? value.map((val) => prepareQueryParam({ name, value: val }))
    : [prepareQueryParam({ name, value })];
const prepareTestProjectGroupRequestCommonParams = (request, groupId) => formatHttpRequestParams(Object.assign(Object.assign({}, request), { body: { TEXT: request.body }, groupId, params: objectToArray(request.params, "name", "value").reduce((res, queryParam) => [...res, ...parseQueryParamByValue(queryParam)], []), requestName: request.name, requestUrl: request.url }));
const prepareRequestGroupTransactionsWithTimerList = ({ res, groupElement, groupId, parentTransactionId }) => {
    const requestTimers = getTimersFromRequest(groupElement);
    return Object.assign(Object.assign({}, res), { requests: [
            ...res.requests,
            Object.assign(Object.assign({}, prepareTestProjectGroupRequestCommonParams(Object.assign(Object.assign({}, groupElement), { timerList: Object.keys(requestTimers) }), groupId)), { transactionId: parentTransactionId, name: groupElement.name })
        ], timers: Object.assign(Object.assign({}, res.timers), requestTimers) });
};
const getTestProjectRequestGroupTransactionsByElementType = (res, groupId, parentTransactionId, groupElement) => {
    const transactionId = getUniqueId();
    switch (groupElement.groupElementType) {
        case ThreadGroupController.Controller: {
            return isEmpty(groupElement.children)
                ? Object.assign(Object.assign({}, res), { transactions: [...res.transactions, prepareTransaction(groupElement, groupId, transactionId)] }) : Object.assign(Object.assign({}, res), getTestProjectRequestGroupTransactions({
                groupElements: groupElement.children,
                groupId,
                requests: res.requests,
                parentTransactionId: transactionId,
                timers: res.timers,
                transactions: [...res.transactions, prepareTransaction(groupElement, groupId, transactionId)]
            }));
        }
        default: {
            return prepareRequestGroupTransactionsWithTimerList({ res, groupElement, groupId, parentTransactionId });
        }
    }
};
function getTestProjectRequestGroupTransactions({ groupElements, groupId, requests, parentTransactionId, timers, transactions }) {
    return (groupElements || []).reduce((res, groupElement) => getTestProjectRequestGroupTransactionsByElementType(res, groupId, parentTransactionId, groupElement), { requests, timers, transactions });
}
const getTestProjectRequestGroupName = (index, name) => isEmpty(name) ? `${TEST_PROJECT_REQUEST_GROUP_DEFAULT_NAME} ${index + 1}` : name;
const getJMXTestProjectRequestGroupCommonParams = ({ res, index, group, groupId }) => {
    const { requests, timers, transactions } = getTestProjectRequestGroupTransactions({
        groupElements: group.threadGroupElements,
        groupId,
        requests: [],
        parentTransactionId: getUniqueId(),
        timers: {},
        transactions: []
    });
    return {
        groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareTestProjectRequestGroup(groupId, index, group.resourceConfiguration)), { displayTypeName: group.displayTypeName || "", enabled: isEmpty(group.enabled) ? true : group.enabled, name: getTestProjectRequestGroupName(index, group.name), type: group.type })
        ],
        requests: [...res.requests, ...requests],
        timers: Object.assign(Object.assign({}, res.timers), timers),
        transactions: [...res.transactions, ...transactions],
        sourceJmxThreadGroups: Object.assign(Object.assign({}, res.sourceJmxThreadGroups), { [groupId]: group })
    };
};
const getJMXUltimateThreadGroupDataVer1 = ({ res, index, group }) => {
    const groupId = isEmpty(group.id) ? getUniqueId() : String(group.id);
    const steps = prepareSteps(group.steps);
    const { requests, timers } = prepareTestProjectGroupRequests(groupId, group.requests);
    return {
        groups: [
            ...res.groups,
            Object.assign(Object.assign({}, prepareThreadGroupData(groupId, index, group)), { enabled: group.enabled })
        ],
        JMXProfiles: Object.assign(Object.assign({}, res.JMXProfiles), parseTestProjectLoadProfile(steps, groupId)),
        requests: [...res.requests, ...requests],
        timers: Object.assign(Object.assign({}, res.timers), timers)
    };
};
const getJMXUltimateThreadGroupDataVer2 = ({ res, index, group }) => {
    const groupId = isEmpty(group.id) ? getUniqueId() : String(group.id);
    const commonParams = getJMXTestProjectRequestGroupCommonParams({ res, index, group, groupId });
    const steps = prepareSteps(group.steps);
    return Object.assign(Object.assign({}, commonParams), { JMXProfiles: Object.assign(Object.assign({}, res.JMXProfiles), parseTestProjectLoadProfile(steps, groupId)) });
};
const getJMXOtherThreadGroupData = ({ res, index, group }) => {
    const groupId = isEmpty(group.id) ? getUniqueId() : String(group.id);
    return Object.assign(Object.assign({}, res), getJMXTestProjectRequestGroupCommonParams({ res, index, group, groupId }));
};
export const prepareJMXTestProjectDataByThreadGroupType = (res, index, groupData) => {
    const group = Object.assign(Object.assign({}, groupData), { label: groupData.name });
    switch (group.type) {
        case RequestGroupType.Ultimate: {
            return getJMXUltimateThreadGroupDataVer1({
                res,
                index,
                group
            });
        }
        case RequestGroupType.Ultimate2: {
            return getJMXUltimateThreadGroupDataVer2({
                res,
                index,
                group
            });
        }
        default: {
            return getJMXOtherThreadGroupData({
                res,
                index,
                group
            });
        }
    }
};
