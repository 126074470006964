var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "@boomq/utils";
import { CONFIRM_PASSWORD_INPUT_PLACEHOLDER } from "./intl";
import { LOGIN_INPUT_PLACEHOLDER, PASSWORD_INPUT_PLACEHOLDER } from "../AuthorizeForm";
import { AuthForm, AuthErrorMessage, AuthInput, AuthLabel, PassRulesErrorMessage } from "../../../components/styled/Auth";
import { AuthorizeFormInputs } from "../../../components/website/AuthorizeFormInputs";
import { formatPassRulesInErrors, generatePasswordTooltip } from "../../../helpers/auth";
import { formatIntlMessage } from "../../../helpers/intl";
import { formatEmail } from "../../../helpers/utils";
import { registrationValidator } from "../../../helpers/validators";
import { resetError } from "../../../redux/actions/error";
import { registrationRequest } from "../../../redux/actions/registration";
const connector = connect(null, { onSubmit: registrationRequest, onUnmount: resetError });
const getErrorsFromProps = ({ errors }, formatMessage) => formatPassRulesInErrors(errors || {}, formatMessage, React.createElement("br", null));
const RegisterFormContainer = (_a) => {
    var _b, _c, _d;
    var { children, onSubmit, onUnmount, type } = _a, restProps = __rest(_a, ["children", "onSubmit", "onUnmount", "type"]);
    const { formatMessage } = useIntl();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState(getErrorsFromProps(restProps, formatMessage));
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    useEffect(() => {
        return () => onUnmount === null || onUnmount === void 0 ? void 0 : onUnmount();
    }, []);
    useEffect(() => {
        const checkErrors = (restProps) => {
            const errorsFromProps = getErrorsFromProps(restProps, formatMessage);
            return errorsFromProps ? setErrors(errorsFromProps) : undefined;
        };
        checkErrors(restProps);
    }, [restProps.errors]);
    const blurLoginHandler = () => setLogin(formatEmail(login));
    const changeConfirmPasswordHandler = ({ target: { value } }) => setConfirmPassword(value);
    const changeLoginHandler = ({ target: { value } }) => setLogin(value);
    const changePasswordHandler = ({ target: { value } }) => setPassword(value);
    const submitHandler = (event) => {
        event.preventDefault();
        const { isValid, errors } = registrationValidator({ login, password, confirmPassword });
        setErrors(formatPassRulesInErrors(errors, formatMessage, React.createElement("br", null)));
        return isValid ? onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit({ login, password, formType: type }) : undefined;
    };
    const confirmPasswordInputPlaceholder = formatIntlMessage(CONFIRM_PASSWORD_INPUT_PLACEHOLDER, formatMessage);
    const loginInputPlaceholder = formatIntlMessage(LOGIN_INPUT_PLACEHOLDER, formatMessage);
    const passwordInputPlaceholder = formatIntlMessage(PASSWORD_INPUT_PLACEHOLDER, formatMessage);
    const passwordTooltip = generatePasswordTooltip(formatMessage, React.createElement("br", null));
    return (React.createElement(AuthForm, Object.assign({}, restProps, { onSubmit: submitHandler }),
        React.createElement(AuthorizeFormInputs, { errors: errors, login: login, loginInputPlaceholder: loginInputPlaceholder, onBlurLogin: blurLoginHandler, onChangeLogin: changeLoginHandler, onChangePassword: changePasswordHandler, password: password, passwordInputPlaceholder: passwordInputPlaceholder, passwordTooltip: passwordTooltip }),
        React.createElement(AuthLabel, null,
            React.createElement(AuthInput, { isError: !!(errors === null || errors === void 0 ? void 0 : errors.confirmPassword), name: "confirmPassword", value: confirmPassword, onChange: changeConfirmPasswordHandler, type: "password", placeholder: confirmPasswordInputPlaceholder }),
            ((_b = errors === null || errors === void 0 ? void 0 : errors.confirmPassword) === null || _b === void 0 ? void 0 : _b.id) && (React.createElement(AuthErrorMessage, null, formatMessage(errors.confirmPassword)))),
        children,
        ((_c = errors === null || errors === void 0 ? void 0 : errors.passwordRules) === null || _c === void 0 ? void 0 : _c.id) && (React.createElement(PassRulesErrorMessage, null, formatMessage(errors.passwordRules, (_d = errors.passwordRules) === null || _d === void 0 ? void 0 : _d.values)))));
};
export default connector(RegisterFormContainer);
