import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import { uploadByFile } from "./uploadByFile";
export const createEditorInstance = (holderId, data, extendedTools) => {
    const editorInstance = new EditorJS({
        autofocus: true,
        holder: holderId,
        tools: Object.assign({ header: {
                class: Header,
                config: {
                    placeholder: "Enter a header",
                    levels: [1, 2, 3, 4],
                    defaultLevel: 1
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+H"
            }, image: {
                class: ImageTool,
                config: {
                    uploader: { uploadByFile }
                }
            }, list: {
                class: List,
                config: {
                    defaultStyle: "unordered"
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+L"
            }, underline: {
                class: Underline,
                shortcut: "CMD+SHIFT+U"
            }, marker: {
                class: Marker,
                shortcut: "CMD+SHIFT+M"
            }, table: {
                class: Table,
                config: {
                    rows: 2,
                    cols: 3
                },
                inlineToolbar: true,
                shortcut: "CMD+SHIFT+T"
            } }, (extendedTools !== null && extendedTools !== void 0 ? extendedTools : {})),
        data
    });
    return editorInstance;
};
