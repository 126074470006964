import { ComponentType } from "react";
import {
    commonRoutes,
    NEW_ITEM_ID,
    REPORTS_ROUTE,
    REPORT_ID_PARAM_NAME,
    TEAM_ID_PARAM_NAME,
    TEAMS_ROUTE
} from "@boomq/common";
import { NewTest } from "../../components/pages/account/NewTest";
import { Profile } from "../../components/pages/account/Profile";
import { AllSlaReportProfilesContainer } from "../../containers/account/AllSlaReportProfiles";
import { NewReportContainer } from "../../containers/pages/account/NewReport";
import { ReportContainer } from "../../containers/pages/account/Report";
import { SlaReportProfile } from "../../containers/pages/account/SlaReportProfile";
import { TeamContainer } from "../../containers/pages/account/Team";
import { TeamsContainer } from "../../containers/pages/account/Teams";
import { TestDetailsContainer } from "../../containers/account/TestDetails";
import { TestProjectContainer } from "../../containers/pages/account/TestProject";
import { AdminTeamPageContainer } from "../../containers/pages/admin/Team";
import { AdminTeamsPageContainer } from "../../containers/pages/admin/Teams";

// ToDo: DELETE
export type NavigationRoute = {
    exact: boolean;
    path: string;
    component: ComponentType<any>;
};

const getCommonRoutesByName = (commonRoutes, routeNames): NavigationRoute[] =>
    commonRoutes ? commonRoutes[routeNames] : {};

const getAccountCommonRoutes = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "accountCommonRoutes");

const getAccountRoutesByPermissions = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "accountRoutesByPermissions");

const getAdminCommonRoutes = (commonRoutes): NavigationRoute[] => getCommonRoutesByName(commonRoutes, "adminRoutes");

const getNotFoundCommonRoutes = (commonRoutes): NavigationRoute[] =>
    getCommonRoutesByName(commonRoutes, "notFoundRoutes");

export const accountRoutesByPermissions: NavigationRoute[] = [
    { exact: false, path: "new-test", component: NewTest },
    { exact: true, path: `${REPORTS_ROUTE}/${NEW_ITEM_ID}`, component: NewReportContainer },
    { exact: false, path: `${REPORTS_ROUTE}/:${REPORT_ID_PARAM_NAME}`, component: ReportContainer },
    { exact: false, path: "tests/:test_project_id/:test_project_version", component: TestProjectContainer },
    { exact: false, path: "tests/:test_project_id", component: TestProjectContainer },
    ...getAccountRoutesByPermissions(commonRoutes),
    { exact: false, path: "test-runs/:test_project_id/:test_id", component: TestDetailsContainer },
    { exact: true, path: "sla-report-profiles", component: AllSlaReportProfilesContainer },
    { exact: false, path: "sla-report-profiles/:sla_profile_id", component: SlaReportProfile }
];

export const accountCommonRoutes: NavigationRoute[] = [
    { exact: false, path: "profile", component: Profile },
    { exact: true, path: TEAMS_ROUTE, component: TeamsContainer },
    { exact: true, path: `${TEAMS_ROUTE}/:${TEAM_ID_PARAM_NAME}`, component: TeamContainer },
    ...getAccountCommonRoutes(commonRoutes),
    ...getNotFoundCommonRoutes(commonRoutes)
];

export const adminRoutes: NavigationRoute[] = [
    { exact: true, path: TEAMS_ROUTE, component: AdminTeamsPageContainer },
    { exact: true, path: `${TEAMS_ROUTE}/:${TEAM_ID_PARAM_NAME}`, component: AdminTeamPageContainer },
    ...getAdminCommonRoutes(commonRoutes)
];
