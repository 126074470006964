import { call, put, take, } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getResponseErrorData } from "../../helpers/errors";
import { activationRequest, activationSuccess, activationFailure } from "../actions/activation";
import { activateAccount } from '../../services/authSrv';
import { loginActions } from "./auth";
import authorizeActions from "../actions/authorize";
const { authSuccess, authFailure } = authorizeActions;
export function* activationFlow() {
    while (true) {
        const action = yield take(activationRequest);
        try {
            if (action.type === activationRequest.toString()) {
                const { data, headers: { authorization: token } } = yield call(activateAccount, action.payload.code);
                yield put(activationSuccess(data));
                yield put(authSuccess(data));
                yield call(loginActions, token);
            }
        }
        catch (e) {
            yield put(activationFailure(getResponseErrorData(e)));
            yield put(push('/authorize'));
            yield put(authFailure(getResponseErrorData(e)));
        }
    }
}
