import React from 'react';
import styled from 'styled-components';
const EnvPlate = ({ env }) => React.createElement(Plate, null, env);
const Plate = styled.div `
  position: fixed;
  top: 19px;
  left: -20px;
  background: #eb0000;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  transform: rotate(-30deg);
  width: 130px;
  text-align: center;
  z-index: 999;
`;
export default EnvPlate;
