var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getUniqueId, getValueByPath, isEmpty } from "@boomq/utils";
import { getThreadGroupElementType } from "../threadGroup";
import { getElements, getControllerFlowControlElements, getImportDataElements, ThreadGroupController, ControllerType, nodeDefaultParamsByType } from "../../models/threadGroupEditor";
const addNode = (res, isPrevNodes, node) => isPrevNodes ? Object.assign(Object.assign({}, res), { prevNodes: [...res.prevNodes, node] }) : Object.assign(Object.assign({}, res), { nextNodes: [...res.nextNodes, node] });
const checkFilterTreeNodesByPathParts = (treeNodes = [], pathParts, pathPartsIndex) => pathParts.length === pathPartsIndex + 2
    ? treeNodes.filter((treeNode, index) => index !== Number(pathParts[pathPartsIndex + 1]))
    : filterTreeNodesByPathParts(treeNodes, pathParts, pathPartsIndex + 1);
const filterTreeNodesByPathParts = (treeNodes, pathParts, pathPartsIndex) => treeNodes.map((treeNode, index) => index === Number(pathParts[pathPartsIndex])
    ? Object.assign(Object.assign({}, treeNode), { children: checkFilterTreeNodesByPathParts(treeNode.children, pathParts, pathPartsIndex) }) : treeNode);
export const deleteTreeBranchByPath = (treeData, path) => {
    const pathParts = path.split("-");
    const pathPartsIndex = 0;
    return pathParts.length > 1
        ? filterTreeNodesByPathParts(treeData, pathParts, pathPartsIndex)
        : treeData.filter((treeNode, index) => index !== Number(pathParts[0]));
};
const formatThreadGroupEditorElement = (_a) => {
    var { controller, controllerType } = _a, rest = __rest(_a, ["controller", "controllerType"]);
    return (Object.assign(Object.assign({}, rest), { id: `${controller}_${controllerType}`, intlName: {
            defaultMessage: controllerType,
            id: `account.request.group.editor.element.controllerType.${controllerType}`
        }, type: controller, value: controllerType }));
};
const formatThreadGroupEditorElements = (elements) => (elements || []).map(formatThreadGroupEditorElement);
export const formatDragStartNode = ({ type, value }) => ({
    id: getUniqueId(),
    controllerType: value,
    type
});
const getFlatTreeNodes = (nodes, treeData) => {
    return Array.isArray(treeData)
        ? treeData.reduce((res, node) => Array.isArray(node === null || node === void 0 ? void 0 : node.children)
            ? getFlatTreeNodes([...res, node], node.children)
            : [...res, node], nodes)
        : [];
};
const modifyTreeElement = (treeEl, paths, pathIndex, elementData) => paths.length === pathIndex + 1
    ? elementData
    : Object.assign(Object.assign({}, treeEl), { children: modifyTreeElementByPath(treeEl.children, paths, pathIndex + 1, elementData) });
const modifyTreeElementByPathIndex = (treeEl, treeElIndex, paths, pathIndex, elementData) => Number(treeElIndex) === Number(paths[pathIndex])
    ? modifyTreeElement(treeEl, paths, pathIndex, elementData)
    : treeEl;
const modifyTreeElementByPath = (tree, paths, pathIndex, elementData) => tree.map((treeEl, index) => modifyTreeElementByPathIndex(treeEl, index, paths, pathIndex, elementData));
const getPrevNextElementsById = (treeData, elementId) => {
    let isPrevNodes = true;
    const flatTreeNodes = getFlatTreeNodes([], treeData);
    const toggleIsPrevNodes = (res) => {
        isPrevNodes = false;
        return res;
    };
    return flatTreeNodes.reduce((res, node) => ((node === null || node === void 0 ? void 0 : node.id) === elementId ? toggleIsPrevNodes(res) : addNode(res, isPrevNodes, node)), { prevNodes: [], nextNodes: [] });
};
const filterElementsByType = (elements, elementType) => elements.filter((element) => element && element.type === elementType);
const getNodeFormatterNameByType = (type) => Object.keys(nodeDefaultParamsByType).includes(type) ? type : ThreadGroupController.Controller;
const getNodeDefaultParamsFuncByControllerType = (defaultParamsByType, controllerType) => Object.keys(defaultParamsByType).includes(controllerType) ? controllerType : ControllerType.Transaction;
export const formatThreadGroupElementParamsByType = (_a) => {
    var { type } = _a, rest = __rest(_a, ["type"]);
    const nodeFormatterByType = nodeDefaultParamsByType[getNodeFormatterNameByType(type)];
    return nodeFormatterByType[getNodeDefaultParamsFuncByControllerType(nodeFormatterByType, rest.controllerType)](rest);
};
export const getPrevNextElementsByIdAndType = (treeData, elementId, elementType) => {
    const { prevNodes, nextNodes } = getPrevNextElementsById(treeData, elementId);
    return {
        prevNodes: filterElementsByType(prevNodes, elementType),
        nextNodes: filterElementsByType(nextNodes, elementType)
    };
};
export const getThreadGroupEditorElementsWithIntlName = () => formatThreadGroupEditorElements(getElements());
export const getThreadGroupEditorFlowControlElementsWithIntlName = () => formatThreadGroupEditorElements(getControllerFlowControlElements());
export const getThreadGroupEditorImportDataElementsWithIntlName = () => formatThreadGroupEditorElements(getImportDataElements());
export const getNodeDataError = (nodeData) => getValueByPath(nodeData, "error", {});
const isNodeDataErrorByParamName = (nodeData, paramName) => !isEmpty(getNodeDataError(nodeData)[paramName]);
export const isExistNodeDataErrorByParams = (nodeData, paramNames) => paramNames.some((paramName) => isNodeDataErrorByParamName(nodeData, paramName));
export const isNodeSampler = (node) => getThreadGroupElementType(node) === ThreadGroupController.Sampler;
export const scrollToFirstElementWithError = (editorNode, firstElementIdWithError) => {
    var _a, _b;
    const firstNodeWithError = editorNode === null || editorNode === void 0 ? void 0 : editorNode.querySelector(`[data-id="${firstElementIdWithError}"]`);
    return (_b = (_a = firstNodeWithError === null || firstNodeWithError === void 0 ? void 0 : firstNodeWithError.closest("li")) === null || _a === void 0 ? void 0 : _a.previousElementSibling) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: "smooth" });
};
