var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import { excludeArrayFromArrayByParam, getContrastColorByIndex, getUniqueArrayByParam, getValueByPath, isEmpty } from "@boomq/utils";
import { generateSecretToken, getCurrentSecretToken } from "./secretToken";
import { clientTestProjectListRequest } from "../actions/clientTestProjectList";
import { hideLoader, showLoader } from "../actions/loader";
import { applySlaReportSettingsFailure, applySlaReportSettingsRequest, applySlaReportSettingsSuccess, changeExcludedTestIds, copyEmbedCodeFailure, copyEmbedCodeRequest, copyEmbedCodeSuccess, deleteSlaReportProfileFailure, deleteSlaReportProfileRequest, deleteSlaReportProfileSuccess, deleteSlaReportProfileChartSlaType, deleteSlaReportProfileTableSlaType, editSlaReportProfile, excludeSlaReportProfileTest, getTestListFailure, getTestListRequest, getTestListSuccess, saveSlaReportProfileFailure, saveSlaReportProfileRequest, saveSlaReportProfileSuccess, slaReportProfileAddSlaTypeByViewTypeFailure, slaReportProfileAddSlaTypeByViewTypeRequest, slaReportProfileAddSlaTypeByViewTypeSuccess, slaReportProfileByTestFailure, slaReportProfileByTestRequest, slaReportProfileByTestSuccess, slaReportProfileChangeFieldByViewType, slaReportProfileChangeParamsField, slaReportProfileFailure, slaReportProfileRequest, slaReportProfileSuccess, slaReportProfileChangeBaselineTestFailure, slaReportProfileChangeBaselineTestRequest, slaReportProfileChangeBaselineTestSuccess, slaReportProfileTableChangeFieldFailure, slaReportProfileTableChangeFieldRequest, slaReportProfileTableChangeFieldSuccess } from "../actions/slaReportProfile";
import { getSlaReportCharts, getSlaReportChartById, getSlaReportFilters, getSlaReportProfileName, getSlaReportProfileCurrentSettingsFilters, getSlaReportProfileSettingsFilters, getSlaReportSlaTypeList, getSlaReportTables, getSlaReportTableById, getSearchClientTestList, getSlaReportExcludedTestIdsFilter } from "../reducers/slaReportProfile";
import { getResponseErrorData } from "../../helpers/errors";
import { copyEmbedCodeToClipboard, formatChartDatasetList, formatTableData, generateSlaReportItemEmbedCode, getOrderIndex, getOrderIndexListFromList, getSlaReportItemIds, getSlaReportSettingsFiltersBySelectionType, getSlaReportSettingsFiltersSelectionType, getSlaTypeColor, generateSlaTypeIdFromData, generateSlaTypeItemText, isExistSettingsFiltersBySelectionType, prepareProfileParams, prepareTableValueTypeParams, prepareChartsParams, prepareClientTestList, prepareDtoFilterParams, prepareFromDtoFilterParams, prepareLabelListFromTests, prepareSlaTypesFromChartList, prepareTablesParams, SELECTION_TYPE, SPOILER_NAME, TIME_UNIT } from "../../helpers/slaReport";
import { getTestCompletedStatesString } from "../../helpers/testStatus";
import { SlaReportTableDiffType, SlaReportTableValueType, SlaReportTestFilterType, SlaReportType } from "../../helpers/types";
import { createSlaReportProfile, deleteSlaReportProfile, getAvailableSlaTypeList, getClientTests, getSlaReportProfile, previewSlaReport, updateSlaReportProfile } from "../../services/testSrv";
import { slaReportProfilesRequest } from "../actions/slaReportProfiles";
import { getSecretTokenData } from "../reducers/authorizePlate";
import { getCurrentLanguage } from "../reducers/languagePlate";
const CLIENT_TEST_LIST_SIZE = 999999;
function* getFormattedAvailableSlaTypeList(params) {
    const { projectId, testFilterExpressionList } = params;
    const { data } = yield call(getAvailableSlaTypeList, { projectId, testFilterExpressionList });
    return (data || []).map((slaTypeItemData) => (Object.assign(Object.assign({}, slaTypeItemData), { id: generateSlaTypeIdFromData(slaTypeItemData), text: generateSlaTypeItemText(slaTypeItemData), value: generateSlaTypeIdFromData(slaTypeItemData) })));
}
function* getPreviewSlaReportData(params) {
    const _a = params || {}, { slaTypeList, excludedTestIdsFilter } = _a, rest = __rest(_a, ["slaTypeList", "excludedTestIdsFilter"]);
    const previewSlaReportResponse = yield call(previewSlaReport, rest);
    const { data: previewSlaReportData } = previewSlaReportResponse || {};
    const { chartList, tableList, testList } = previewSlaReportData || {};
    const reportOrderIndexList = [...getOrderIndexListFromList(chartList), ...getOrderIndexListFromList(tableList)];
    return {
        charts: Array.isArray(chartList)
            ? chartList.reduce((res, chart) => (Object.assign(Object.assign({}, res), { [getOrderIndex(reportOrderIndexList, chart.orderInReport)]: {
                    chartList: formatChartDatasetList(chart.chartDatasetList),
                    chartId: chart.chartId,
                    orderInReport: chart.orderInReport,
                    slaTypes: prepareSlaTypesFromChartList(chart.chartDatasetList, slaTypeList)
                } })), {})
            : {},
        tables: Array.isArray(tableList)
            ? tableList.reduce((res, table) => (Object.assign(Object.assign({}, res), { [getOrderIndex(reportOrderIndexList, table.orderInReport, chartList.length)]: formatTableData(table) })), {})
            : {},
        testList
    };
}
function* getSlaReportItemsData(params) {
    const _a = params || {}, { chartProfileList, tableProfileList, slaTypes } = _a, rest = __rest(_a, ["chartProfileList", "tableProfileList", "slaTypes"]);
    const chartsParams = yield select(getSlaReportCharts);
    const tablesParams = yield select(getSlaReportTables);
    const slaTypeList = slaTypes ? slaTypes : yield* getFormattedAvailableSlaTypeList(rest);
    const chartProfileListData = isEmpty(chartProfileList)
        ? prepareChartsParams(slaTypeList, chartsParams)
        : { chartProfileList };
    const tableProfileListData = isEmpty(tableProfileList)
        ? prepareTablesParams(slaTypeList, tablesParams)
        : { tableProfileList };
    const previewSlaReportData = yield* getPreviewSlaReportData(Object.assign(Object.assign(Object.assign(Object.assign({}, rest), chartProfileListData), tableProfileListData), { slaTypeList }));
    return Object.assign(Object.assign({}, previewSlaReportData), { slaTypeList });
}
function* getSlaReportProfilePreviewData(slaReportProfileData) {
    const { data } = yield call(previewSlaReport, Object.assign(Object.assign({}, slaReportProfileData), { testFilterExpressionList: slaReportProfileData.testFilterExpressionList.filter((testFilter) => testFilter.type !== SlaReportTestFilterType.NotExpression) }));
    const { charts, slaTypeList, tables } = yield* getSlaReportItemsData(slaReportProfileData);
    return {
        charts,
        tables,
        openedSpoilerName: isEmpty(charts) && isEmpty(tables) ? SPOILER_NAME[0] : SPOILER_NAME[1],
        searchClientTestList: prepareClientTestList(Array.isArray(data === null || data === void 0 ? void 0 : data.testList) ? data.testList : []),
        slaTypeList
    };
}
function* getSlaReportProfileData(id) {
    const slaReportResponse = yield call(getSlaReportProfile, id);
    const { name, projectId, testFilterExpressionList, testLimit } = slaReportResponse && slaReportResponse.data ? slaReportResponse.data : {};
    const _a = prepareFromDtoFilterParams(testFilterExpressionList, testLimit), { excludedTestIdsFilter = [] } = _a, rest = __rest(_a, ["excludedTestIdsFilter"]);
    const selectionTypeFilter = getSlaReportSettingsFiltersSelectionType(rest);
    const filters = Object.assign(Object.assign({ timeUnitFilter: TIME_UNIT[0] }, rest), { selectionTypeFilter, testProjectFilter: { id: projectId, value: projectId } });
    yield put(clientTestProjectListRequest());
    const slaReportPreviewData = isExistSettingsFiltersBySelectionType(Object.assign({ selectionTypeFilter }, rest))
        ? yield* getSlaReportProfilePreviewData(slaReportResponse.data)
        : {};
    yield* testListGettingFlow({ payload: { projectId, testLabelFilter: rest.testLabelFilter } });
    return Object.assign(Object.assign({}, slaReportPreviewData), { excludedTestIdsFilter,
        name, currentSettingsFilters: filters, settingsFilters: filters });
}
function* deleteSlaReportProfileFlow({ payload: { id } }) {
    try {
        yield call(deleteSlaReportProfile, id);
        yield put(deleteSlaReportProfileSuccess());
        yield put(slaReportProfilesRequest());
    }
    catch (e) {
        yield put(deleteSlaReportProfileFailure(getResponseErrorData(e)));
    }
}
function* editSlaReportProfileFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        const slaReportProfileId = isEmpty(id) ? "new" : id;
        yield put(push({
            pathname: `/account/sla-report-profiles/${slaReportProfileId}`
        }));
    }
    catch (e) {
        yield put(slaReportProfileFailure(getResponseErrorData(e)));
    }
}
function* createSlaReportProfileFlow(params) {
    const createSlaReportResponse = yield call(createSlaReportProfile, params);
    const data = createSlaReportResponse && createSlaReportResponse.data ? createSlaReportResponse.data : {};
    data.id &&
        (yield put(push({
            pathname: `/account/sla-report-profiles/${data.id}`
        })));
    return createSlaReportResponse;
}
function* excludeSlaReportProfileTestFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        const searchClientTestList = yield select(getSearchClientTestList) || [];
        const excludedTestIdsFilter = yield select(getSlaReportExcludedTestIdsFilter) || {};
        const excludedTestList = Array.isArray(excludedTestIdsFilter)
            ? [...excludedTestIdsFilter, { id, value: id }]
            : [{ id, value: id }];
        yield put(changeExcludedTestIds(excludeArrayFromArrayByParam(searchClientTestList, excludedTestList, "value")));
    }
    catch (e) {
        console.error(e);
    }
}
function* newSlaReportProfileData() {
    yield put(clientTestProjectListRequest());
    return {};
}
export function* slaReportProfileSavingFlow(action) {
    try {
        const id = action && action.payload ? action.payload : undefined;
        const name = yield select(getSlaReportProfileName);
        const _a = yield select(getSlaReportFilters), { excludedTestIdsFilter } = _a, otherSlaReportFilters = __rest(_a, ["excludedTestIdsFilter"]);
        const _b = yield select(getSlaReportProfileCurrentSettingsFilters), { testProjectFilter } = _b, otherSlaReportCurrentFilters = __rest(_b, ["testProjectFilter"]);
        const slaTypeList = yield select(getSlaReportSlaTypeList);
        const chartsParams = yield select(getSlaReportCharts);
        const tablesParams = yield select(getSlaReportTables);
        const slaReportProfileFilters = isEmpty(chartsParams) && isEmpty(tablesParams)
            ? Object.assign(Object.assign({}, getSlaReportSettingsFiltersBySelectionType(otherSlaReportCurrentFilters)), { excludedTestIdsFilter,
                testProjectFilter }) : Object.assign({ excludedTestIdsFilter }, otherSlaReportFilters);
        const slaReportProfileParams = Object.assign(Object.assign(Object.assign({ name, projectId: getValueByPath(slaReportProfileFilters, "testProjectFilter.value") }, prepareChartsParams(slaTypeList, chartsParams)), prepareTablesParams(slaTypeList, tablesParams)), prepareDtoFilterParams(slaReportProfileFilters));
        const { data } = id === "new"
            ? yield* createSlaReportProfileFlow(slaReportProfileParams)
            : yield call(updateSlaReportProfile, { slaReportId: id, params: slaReportProfileParams });
        yield put(saveSlaReportProfileSuccess(getSlaReportItemIds(data)));
    }
    catch (e) {
        yield put(saveSlaReportProfileFailure(getResponseErrorData(e)));
    }
}
function* slaReportProfileDataFlow(action) {
    try {
        const id = action && action.payload ? action.payload : {};
        const data = id === "new" ? yield* newSlaReportProfileData() : yield* getSlaReportProfileData(id);
        yield put(slaReportProfileSuccess(data));
    }
    catch (e) {
        yield put(slaReportProfileFailure(getResponseErrorData(e)));
    }
}
function* slaReportSettingsApplyingFlow(action) {
    try {
        const _a = action ? action.payload : {}, { excludedTestIdsFilter } = _a, rest = __rest(_a, ["excludedTestIdsFilter"]);
        const { charts, slaTypeList, tables, testList } = yield* getSlaReportItemsData(Object.assign({ projectId: rest.testProjectFilter ? rest.testProjectFilter.value : undefined }, prepareDtoFilterParams(rest)));
        yield put(applySlaReportSettingsSuccess({
            charts,
            excludedTestIdsFilter: [],
            openedSpoilerName: SPOILER_NAME[1],
            searchClientTestList: prepareClientTestList(testList),
            settingsFilters: Object.assign({ excludedTestIdsFilter }, rest),
            slaTypeList,
            tables
        }));
    }
    catch (e) {
        yield put(applySlaReportSettingsFailure(getResponseErrorData(e)));
    }
}
function* slaReportChartSlaTypeAdding(params) {
    const { chartIndex, slaReportFilters, slaTypeList, value } = params || {};
    const _a = slaReportFilters || {}, { excludedTestIdsFilter } = _a, rest = __rest(_a, ["excludedTestIdsFilter"]);
    const chartParams = yield select(getSlaReportChartById, chartIndex);
    const newSlaTypes = [
        {
            id: value,
            color: getContrastColorByIndex(0, (chartParams.slaTypes || []).map((slaType) => getSlaTypeColor(slaType)))
        },
        ...(chartParams.slaTypes || [])
    ];
    const { data: previewSlaReportData } = yield call(previewSlaReport, Object.assign({ projectId: getValueByPath(slaReportFilters, "testProjectFilter.value"), chartProfileList: [prepareProfileParams(slaTypeList, newSlaTypes)] }, prepareDtoFilterParams(rest)));
    yield put(slaReportProfileChangeFieldByViewType({
        field: chartIndex,
        type: "charts",
        value: {
            slaTypes: newSlaTypes,
            chartList: previewSlaReportData
                ? formatChartDatasetList(getValueByPath(previewSlaReportData, "chartList.0.chartDatasetList", []))
                : []
        }
    }));
}
function* slaReportTableSlaTypeAdding(params) {
    const { tableIndex, slaReportFilters, slaTypeList, value } = params || {};
    const _a = slaReportFilters || {}, { excludedTestIdsFilter } = _a, rest = __rest(_a, ["excludedTestIdsFilter"]);
    const tableParams = yield select(getSlaReportTableById, tableIndex);
    const selectedSlaTypes = getValueByPath(tableParams, "rowList", []).map((row) => row ? { id: generateSlaTypeIdFromData(row.slaType) } : {});
    const previewParams = Object.assign({ projectId: getValueByPath(slaReportFilters, "testProjectFilter.value"), chartProfileList: [], tableProfileList: [
            Object.assign(Object.assign({}, prepareProfileParams(slaTypeList, [...selectedSlaTypes, value])), prepareTableValueTypeParams(tableParams))
        ] }, prepareDtoFilterParams(rest));
    const { data: previewSlaReportData } = yield call(previewSlaReport, previewParams);
    yield put(slaReportProfileChangeFieldByViewType({
        field: tableIndex,
        type: "tables",
        value: formatTableData(getValueByPath(previewSlaReportData, "tableList.0", {}))
    }));
}
function* slaTypeAddingFlow(action) {
    try {
        const _a = action && action.payload ? action.payload : {}, { type } = _a, rest = __rest(_a, ["type"]);
        const slaReportFilters = yield select(getSlaReportFilters);
        const slaTypeList = yield select(getSlaReportSlaTypeList);
        type === SlaReportType.Chart
            ? yield* slaReportChartSlaTypeAdding(Object.assign(Object.assign({}, rest), { slaReportFilters, slaTypeList }))
            : yield* slaReportTableSlaTypeAdding(Object.assign(Object.assign({}, rest), { slaReportFilters, slaTypeList }));
        yield put(slaReportProfileAddSlaTypeByViewTypeSuccess());
    }
    catch (e) {
        yield put(slaReportProfileAddSlaTypeByViewTypeFailure(getResponseErrorData(e)));
    }
}
function* testListGettingFlow(action) {
    try {
        const { projectId, testLabelFilter = [] } = action && action.payload ? action.payload : {};
        const clientTestResponse = projectId
            ? yield call(getClientTests, {
                size: CLIENT_TEST_LIST_SIZE,
                projectId,
                state: getTestCompletedStatesString()
            })
            : { data: {} };
        const { data } = clientTestResponse || { data: {} };
        yield put(getTestListSuccess({
            clientTestList: prepareClientTestList(data.content),
            testsLabelList: getUniqueArrayByParam([...prepareLabelListFromTests(data.content), ...testLabelFilter], "label")
        }));
    }
    catch (e) {
        yield put(getTestListFailure(getResponseErrorData(e)));
    }
}
function* refreshSlaReportTablesDataByExcludedTestChanging(selectedTests) {
    yield put(showLoader());
    const slaReportFilters = yield select(getSlaReportProfileSettingsFilters);
    const slaTypeList = yield select(getSlaReportSlaTypeList);
    const { tables } = yield* getSlaReportItemsData(Object.assign({ chartProfileList: [], projectId: getValueByPath(slaReportFilters, "testProjectFilter.value"), slaTypes: slaTypeList }, prepareDtoFilterParams({
        testIdsFilter: selectedTests
    })));
    yield put(slaReportProfileChangeParamsField({
        field: "tables",
        value: tables
    }));
    yield put(hideLoader());
}
function* isNeedRefreshSlaReportTablesData(selectedTests) {
    const tables = yield select(getSlaReportTables);
    return selectedTests.length > 0 &&
        Object.keys(tables).find((tableIndex) => getValueByPath(tables[tableIndex], "valueType.value") === SlaReportTableValueType.PreviousTestDiff)
        ? yield* refreshSlaReportTablesDataByExcludedTestChanging(selectedTests)
        : undefined;
}
function* changeExcludedTestIdsFlow(action) {
    try {
        const selectedTests = action.payload || [];
        const tables = yield select(getSlaReportTables);
        const searchClientTestList = yield select(getSearchClientTestList);
        const selectedTestIds = selectedTests.map((selectedTest) => selectedTest.value);
        const excludedTestIdsFilter = excludeArrayFromArrayByParam(searchClientTestList, selectedTests, "value");
        yield put(slaReportProfileChangeParamsField({
            field: "tables",
            value: Object.keys(tables).reduce((res, tableIndex) => {
                const currentBaselineTestId = getValueByPath(tables[tableIndex], "baselineTestId");
                return Object.assign(Object.assign({}, res), { [tableIndex]: Object.assign(Object.assign({}, tables[tableIndex]), { baselineTestId: !selectedTestIds.includes(currentBaselineTestId)
                            ? undefined
                            : currentBaselineTestId }) });
            }, {})
        }));
        yield* isNeedRefreshSlaReportTablesData(selectedTests);
        yield put(slaReportProfileChangeParamsField({
            field: "excludedTestIdsFilter",
            value: excludedTestIdsFilter
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* deleteSlaReportProfileChartSlaTypeFlow(action) {
    try {
        const { chartIndex, slaTypeId } = action && action.payload ? action.payload : {};
        const slaReportChartData = yield select(getSlaReportChartById, chartIndex);
        yield put(slaReportProfileChangeFieldByViewType({
            field: chartIndex,
            subfield: "slaTypes",
            type: "charts",
            value: (slaReportChartData.slaTypes || []).filter((slaReportChartSlaType) => slaReportChartSlaType.id !== slaTypeId)
        }));
        yield put(slaReportProfileChangeFieldByViewType({
            field: chartIndex,
            subfield: "chartList",
            type: "charts",
            value: (slaReportChartData.chartList || []).filter((chartData) => generateSlaTypeIdFromData(chartData.slaType) !== slaTypeId)
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* deleteSlaReportProfileTableSlaTypeFlow(action) {
    try {
        const { tableIndex, slaTypeId } = action && action.payload ? action.payload : {};
        const slaReportTableData = yield select(getSlaReportTableById, tableIndex);
        yield put(slaReportProfileChangeFieldByViewType({
            field: tableIndex,
            subfield: "rowList",
            type: "tables",
            value: (slaReportTableData.rowList || []).filter((rowData) => generateSlaTypeIdFromData(rowData.slaType) !== slaTypeId)
        }));
    }
    catch (e) {
        console.error(e);
    }
}
function* slaReportProfileByTestFlow(action) {
    try {
        const { testProjectId, testId } = action && action.payload ? action.payload : {};
        yield* testListGettingFlow({ payload: { projectId: testProjectId } });
        yield put(slaReportProfileByTestSuccess({
            currentSettingsFilters: {
                selectionTypeFilter: SELECTION_TYPE[0],
                testIdsFilter: [{ id: testId, value: testId }],
                testProjectFilter: { id: testProjectId, value: testProjectId },
                timeUnitFilter: TIME_UNIT[0]
            }
        }));
        yield put(push("/account/sla-report-profiles/new"));
    }
    catch (e) {
        yield put(slaReportProfileByTestFailure(getResponseErrorData(e)));
    }
}
function* refreshSlaReportProfileTable(params) {
    const { tableIndex } = params || {};
    const slaReportFilters = yield select(getSlaReportFilters);
    const slaTypeList = yield select(getSlaReportSlaTypeList);
    const _a = slaReportFilters || {}, { excludedTestIdsFilter } = _a, rest = __rest(_a, ["excludedTestIdsFilter"]);
    const { tables } = yield* getSlaReportItemsData(Object.assign({ chartProfileList: [], projectId: getValueByPath(slaReportFilters, "testProjectFilter.value"), slaTypes: slaTypeList }, prepareDtoFilterParams(rest)));
    yield put(slaReportProfileChangeFieldByViewType({
        field: tableIndex,
        subfield: "rowList",
        type: "tables",
        value: getValueByPath(tables, `${tableIndex}.rowList`, [])
    }));
}
function* slaReportProfileChangeBaselineTestFlow(action) {
    try {
        const { tableIndex } = action && action.payload ? action.payload : {};
        yield* refreshSlaReportProfileTable({ tableIndex });
        yield put(slaReportProfileChangeBaselineTestSuccess());
    }
    catch (e) {
        yield put(slaReportProfileChangeBaselineTestFailure(getResponseErrorData(e)));
    }
}
function* resetBaselineTest() {
    const tables = yield select(getSlaReportTables);
    const tablesWithBaselineDiff = Object.keys(tables).filter((tableIndex) => getValueByPath(tables[tableIndex], "valueType.value") === SlaReportTableValueType.BaselineDiff);
    tablesWithBaselineDiff.length === 0 &&
        (yield put(slaReportProfileChangeParamsField({ field: "baselineTest", value: undefined })));
}
function* resetDiffTypes({ tableIndex, value }) {
    yield put(slaReportProfileChangeFieldByViewType({
        type: "tables",
        field: tableIndex,
        subfield: "diffType",
        value: SlaReportTableDiffType.Absolute
    }));
    value && value.value !== SlaReportTableValueType.BaselineDiff && (yield* resetBaselineTest());
}
function* slaReportProfileTableChangeFieldFlow(action) {
    try {
        const { field: tableIndex, subfield, value } = action && action.payload ? action.payload : {};
        subfield === "valueType" && (yield* resetDiffTypes({ tableIndex, value }));
        subfield !== "diffType" && (yield* refreshSlaReportProfileTable({ tableIndex }));
        yield put(slaReportProfileTableChangeFieldSuccess());
    }
    catch (e) {
        yield put(slaReportProfileTableChangeFieldFailure());
    }
}
function* getSecretTokenFlow() {
    const secretToken = yield* getCurrentSecretToken();
    return isEmpty(secretToken) ? yield* generateSecretToken() : secretToken;
}
function* copyEmbedCodeFlow(action) {
    try {
        const { inputEl, slaReportProfileId, slaReportItemId, slaReportItemType } = action && action.payload ? action.payload : {};
        const currentLanguage = yield select(getCurrentLanguage);
        const secretTokenFromStore = yield select(getSecretTokenData);
        const secretToken = isEmpty(secretTokenFromStore) ? yield* getSecretTokenFlow() : secretTokenFromStore;
        const embedCode = generateSlaReportItemEmbedCode(slaReportProfileId, slaReportItemType, slaReportItemId, currentLanguage, secretToken);
        copyEmbedCodeToClipboard(inputEl, embedCode);
        yield put(copyEmbedCodeSuccess(secretToken));
    }
    catch (e) {
        yield put(copyEmbedCodeFailure(getResponseErrorData(e)));
    }
}
export function* slaReportProfileFlow() {
    yield takeEvery(applySlaReportSettingsRequest, slaReportSettingsApplyingFlow);
    yield takeEvery(changeExcludedTestIds, changeExcludedTestIdsFlow);
    yield takeEvery(copyEmbedCodeRequest, copyEmbedCodeFlow);
    yield takeEvery(deleteSlaReportProfileRequest, deleteSlaReportProfileFlow);
    yield takeEvery(deleteSlaReportProfileChartSlaType, deleteSlaReportProfileChartSlaTypeFlow);
    yield takeEvery(deleteSlaReportProfileTableSlaType, deleteSlaReportProfileTableSlaTypeFlow);
    yield takeEvery(excludeSlaReportProfileTest, excludeSlaReportProfileTestFlow);
    yield takeEvery(editSlaReportProfile, editSlaReportProfileFlow);
    yield takeEvery(getTestListRequest, testListGettingFlow);
    yield takeEvery(saveSlaReportProfileRequest, slaReportProfileSavingFlow);
    yield takeEvery(slaReportProfileAddSlaTypeByViewTypeRequest, slaTypeAddingFlow);
    yield takeEvery(slaReportProfileByTestRequest, slaReportProfileByTestFlow);
    yield takeEvery(slaReportProfileChangeBaselineTestRequest, slaReportProfileChangeBaselineTestFlow);
    yield takeEvery(slaReportProfileRequest, slaReportProfileDataFlow);
    yield takeEvery(slaReportProfileTableChangeFieldRequest, slaReportProfileTableChangeFieldFlow);
}
