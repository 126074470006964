export const DEFAULT_AGGREGATION_INTERVAL_VALUE = 1;
export const PFLB_CHART_TITLE_TEMPLATE = {
    defaultMessage: "{typeName}, {metricTypeName}, Test id: {testId}",
    id: "account.report.editor.chart.settings.title.template"
};
export const PFLB_CHART_SYSTEM_METRIC_TITLE_TEMPLATE = {
    defaultMessage: "{reportChartCommonTitle}, Hostname: {hostName}",
    id: "account.report.editor.chart.system.metric.settings.title.template"
};
export const PFLB_CHART_TRANSACTION_METRIC_TITLE_TEMPLATE = {
    defaultMessage: "{reportChartCommonTitle}, Transaction: {transactionName}, Thread group name: {threadGroupName}",
    id: "account.report.editor.chart.transaction.metric.settings.title.template"
};
export const REPORT_CHART_SERIE_PARAM_NAME_LIST = [
    "aggregationInterval",
    "aggregationIntervalTimeUnit",
    "hostName",
    "metricType",
    "threadGroupId",
    "testId",
    "transactionId",
    "type"
];
