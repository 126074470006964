import React from "react";
import { Profile as CommonProfile } from "@boomq/common";
import { ProfileForm } from "../../../account/ProfileForm";

const Profile = (props) => (
    <CommonProfile>
        <ProfileForm {...props} />
    </CommonProfile>
)
    
export default Profile;
