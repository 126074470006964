import React from "react";
import { connect } from "react-redux";
import { getValueByPath, isEmpty, stopEvent, useIntl } from "@boomq/utils";
import { IconEdit, Tooltip } from "@boomq/uikit";
import { ACTION_EDIT_TOOLTIP, REQUEST_BODY_LABEL, REQUEST_URL_LABEL } from "./intl";
import { Actions, HttpMethod, RequestBodySection, RequestBodyTitle, RequestData, RequestElBody, RequestElBodyColumn, RequestInfo, RequestSection, RequestSettings, RequestUrlTitle } from "./styled";
import { RequestBody } from "./RequestBody";
import { Action, ButtonDelete } from "../ButtonDelete";
import { RequestExtractorIcon } from "../RequestExtractorIcon";
import { RequestName } from "./RequestName";
import { RequestTimerIcon } from "../RequestTimerIcon";
import { DragAndDropIcon, DragAndDropIconBlock } from "../TransactionElement/styled";
import { getHttpMethodItemWithParamsByValue, getRequestName } from "../../../../helpers/request";
import { changeRequestMethod, deleteEditorElementByPath, editRequestHttpElement } from "../../../../redux/actions/editor";
import { newRequestChangeParams } from "../../../../redux/actions/newRequest";
import { getEditorTimers, getElementById } from "../../../../redux/reducers/editor";
const connector = connect((state, ownProps) => ({
    nodeData: getElementById(state, getValueByPath(ownProps, "node.id", "")),
    timers: getEditorTimers(state)
}), {
    onChangeMethod: changeRequestMethod,
    onDelete: deleteEditorElementByPath,
    onEdit: editRequestHttpElement,
    newRequestChangeParams
});
const getMethodTextByValue = (method) => {
    const methodItem = getHttpMethodItemWithParamsByValue(method);
    return methodItem ? methodItem.text : method;
};
const prepareTimerList = (timerList, timers) => timerList.map((timerId) => timers[timerId]);
const RequestHttpElement = ({ onDelete, onEdit, nodeData, path, timers }) => {
    const { formatMessage } = useIntl();
    const { body, bodyPreview, error, extractResponse, id, method, requestUrl, timerList } = nodeData || {};
    const bodyText = getValueByPath(body, "TEXT", "");
    const methodText = getMethodTextByValue(method);
    const clickElementHandler = () => onEdit({ id, path });
    const deleteElementHandler = (e) => {
        stopEvent(e);
        return onDelete(path);
    };
    const editElementHandler = (e) => {
        stopEvent(e);
        return onEdit({ id, path });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DragAndDropIconBlock, null,
            React.createElement(DragAndDropIcon, null)),
        React.createElement(RequestElBody, { "data-id": id, isExistError: !isEmpty(error), onClick: clickElementHandler },
            React.createElement(RequestElBodyColumn, null,
                React.createElement(HttpMethod, { method: methodText }, methodText),
                React.createElement(RequestSettings, null,
                    React.createElement(RequestTimerIcon, { requestId: id, timerList: prepareTimerList(timerList, timers), multiplicationFactor: 1 }),
                    React.createElement(RequestExtractorIcon, { extractResponse: extractResponse }))),
            React.createElement(RequestInfo, null,
                React.createElement(RequestSection, null,
                    React.createElement(RequestData, { isExistError: !isEmpty(error) },
                        React.createElement(RequestUrlTitle, null, formatMessage(REQUEST_URL_LABEL)),
                        requestUrl),
                    React.createElement(Actions, null,
                        React.createElement(Tooltip, { title: ACTION_EDIT_TOOLTIP },
                            React.createElement(Action, { onClick: editElementHandler },
                                React.createElement(IconEdit, null))),
                        React.createElement(ButtonDelete, { onClick: deleteElementHandler }))),
                React.createElement(RequestName, { name: getRequestName(nodeData), url: requestUrl }),
                React.createElement(RequestBodySection, null,
                    React.createElement(RequestBodyTitle, null, formatMessage(REQUEST_BODY_LABEL)),
                    React.createElement(RequestBody, { bodyPreview: bodyPreview, bodyText: bodyText }))))));
};
export default connector(RequestHttpElement);
