import { all, select, takeEvery, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
    authorizeActions,
    changeUserParams,
    clientActions,
    getCookie,
    newTestActions,
    getAnalyticsData,
    getNewTestRoute,
    getOAuth2AuthorizationCodeUrl,
    getResponseErrorData,
    getRequests,
    getRequestGroups,
    getSiteModal,
    getTestType,
    getThreadGroupsElements,
    getThreadGroupsTransactions,
    loginActions,
    parseTestProjectComposedByUser
} from "@boomq/common";
import { checkTeams } from "./team";
import { oAuth2VerificationCodeFormModal } from "../../data/content/modals";
import OAuth2VerificationCodeForm from "../../components/account/OAuth2VerificationCodeForm";
import { TestType } from "../../helpers/types";

const { linkAccountFailure, linkAccountRequest, linkAccountSuccess } = authorizeActions;
const {
    analyticsFailure,
    analyticsRequest,
    analyticsSuccess,
    changeParamsFailure,
    changeParamsRequest,
    changeParamsSuccess,
    changePasswordFailure,
    changePasswordRequest
} = clientActions;

const { changeRequestGroups, changeRequests, setTestProjectElementsData, testProjectChangeParamsField } = newTestActions;

export function* analyticsFlow(action) {
    try {
        const [
            currentGroups,
            currentRequests,
            currentThreadGroupsTree,
            currentElements,
            currentTransactions,
            testType
        ] = yield all([
            select(getRequestGroups),
            select(getRequests),
            select(getThreadGroupsElements),
            select(getThreadGroupsTransactions),
            select(getTestType)
        ]);
        const { analytics } = yield* getAnalyticsData(action);

        const { elementsData, groups, requests, threadGroupsTree, transactions } = parseTestProjectComposedByUser(
            {},
            analytics
        );

        yield all([
            put(changeRequestGroups([...currentGroups, ...groups])),
            put(changeRequests([...currentRequests, ...requests])),
            put(
                testProjectChangeParamsField({
                    field: "threadGroupsTree",
                    value: {
                        ...currentThreadGroupsTree,
                        ...threadGroupsTree
                    }
                })
            ),
            put(
                testProjectChangeParamsField({
                    field: "transactions",
                    value: [...currentTransactions, ...transactions]
                })
            ),
            put(
                setTestProjectElementsData({
                    ...currentElements,
                    ...elementsData
                })
            )
        ]);

        testType === TestType.JMX &&
            (yield put(testProjectChangeParamsField({ field: "testType", value: TestType.Stable })));

        yield put(analyticsSuccess());
    } catch (e) {
        yield put(analyticsFailure(getResponseErrorData(e)));
    }
}

export function* linkAccountFlow(action) {
    try {
        const payload = action ? action.payload : {};
        const serviceType = payload && payload.serviceType ? payload.serviceType : null;

        const response = serviceType ? yield call(getOAuth2AuthorizationCodeUrl, serviceType.toUpperCase()) : {};
        const { userCode, verificationUrl } = response && response.data ? response.data : {};

        userCode &&
            verificationUrl &&
            (yield put(
                getSiteModal(
                    oAuth2VerificationCodeFormModal({
                        code: userCode,
                        element: OAuth2VerificationCodeForm,
                        url: verificationUrl
                    })
                )
            ));

        yield put(linkAccountSuccess());
    } catch (e) {
        yield put(linkAccountFailure(getResponseErrorData(e)));
    }
}

function* userParamsChangingSuccess(userData) {
    yield put(changeParamsSuccess(userData));
    yield* checkTeams(userData.id);
}

export function* userParamsChanging(action) {
    const response = action && action.payload ? yield call(changeUserParams, action.payload) : {};

    return response.data ? yield* userParamsChangingSuccess(response.data) : undefined;
}

function* paramsFlow(action) {
    try {
        yield* userParamsChanging(action);
    } catch (e) {
        yield put(changeParamsFailure(getResponseErrorData(e)));
    }
}

function* passwordChangingFlow(action) {
    try {
        const token = getCookie("boomq_auth");

        yield* loginActions(token);
        yield* userParamsChanging(action);

        yield put(push(getNewTestRoute()));
    } catch (e) {
        yield put(changePasswordFailure(getResponseErrorData(e)));
    }
}

export function* clientDataFlow() {
    yield takeEvery(analyticsRequest, analyticsFlow);
    yield takeEvery(changeParamsRequest, paramsFlow);
    yield takeEvery(changePasswordRequest, passwordChangingFlow);
    yield takeEvery(linkAccountRequest, linkAccountFlow);
}
