import { CONFIRMID } from "../../helpers/applicationParams";
export const authModalForm = (element) => ({
    component: {
        element,
        props: {
            type: "modal"
        },
        height: "90%",
        theme: { maxHeight: "610px" },
        width: "550px"
    },
    title: null
});
export const isConfirmedModalForm = (e) => e.target.closest("button[id]") && e.target.closest("button[id]").id === CONFIRMID;
export const stopTestModal = (action) => ({
    buttons: [
        {
            action,
            text: { id: "account.modal.stop.button.stop", defaultMessage: "Stop" }
        }
    ],
    description: {
        defaultMessage: "This action will stop the current test. Are you sure?",
        id: "account.modal.stop.description"
    },
    title: { id: "account.modal.stop.title", defaultMessage: "Attention!" }
});
export const deleteUserModal = (action) => ({
    buttons: [
        {
            action,
            text: { id: "admin.modal.delete.button", defaultMessage: "Delete" }
        }
    ],
    description: {
        defaultMessage: "The given actions will lead to removal of the user, can you will think again?",
        id: "admin.modal.delete.description"
    },
    title: { id: "admin.modal.delete.title", defaultMessage: "Attention!" }
});
export const deleteRequestGroupAttentionModal = ({ element, closeAction }) => ({
    component: {
        element,
        props: {
            confirmButton: { theme: { width: "40%!important" } },
            cancelButton: { theme: { width: "40%!important" } },
            text: {
                description: [
                    {
                        defaultMessage: "By deleting the transaction you also delete all the requests in it",
                        id: "account.modal.delete.request.group.description"
                    }
                ]
            }
        },
        width: "600px"
    },
    closeCallBack: closeAction,
    title: { id: "account.modal.delete.request.group.title", defaultMessage: "Attention" }
});
export const deleteRequestGroupsAttentionModal = ({ element, closeAction }) => ({
    component: {
        element,
        props: {
            confirmButton: { theme: { width: "40%!important" } },
            cancelButton: { theme: { width: "40%!important" } },
            text: {
                description: [
                    {
                        defaultMessage: "All groups will be deleted. Are you sure?",
                        id: "account.modal.delete.request.groups.description"
                    }
                ]
            }
        },
        width: "600px"
    },
    closeCallBack: closeAction,
    title: { id: "account.modal.delete.request.group.title", defaultMessage: "Attention" }
});
export const deleteRequestWithParamatersInGroupModal = ({ element, closeAction }) => ({
    component: {
        element,
        props: {
            confirmButton: { theme: { width: "40%!important" } },
            cancelButton: { theme: { width: "40%!important" } },
            text: {
                description: [
                    {
                        defaultMessage: "Request contain extracted from response parameters. By deleting the request results of test may be incorrect",
                        id: "account.modal.delete.request.with.parameters.description"
                    }
                ]
            }
        },
        width: "600px"
    },
    closeCallBack: closeAction,
    title: { id: "account.modal.delete.request.group.title", defaultMessage: "Attention" }
});
export const excludeSlaReportChartTestConfirmFormModal = ({ element, id }) => ({
    component: {
        element,
        props: { id }
    },
    title: {
        defaultMessage: "Confirm test excluding",
        id: "account.modal.sla.report.profile.exclude.test.confirm.title"
    }
});
export const requestFormModal = ({ closeAction, element, props }) => ({
    component: {
        element,
        height: "80%",
        props: Object.assign({}, props),
        width: "100%"
    },
    closeCallBack: closeAction,
    title: null
});
export const requestParametersFormModal = ({ activeTabName, element }) => ({
    component: {
        element,
        height: "80%",
        props: { activeTabName },
        width: "100%"
    },
    title: { id: "account.modal.request.parameters.title", defaultMessage: "Parameters" }
});
export const requestTimersFormModal = ({ element }) => ({
    component: {
        element,
        height: "410px",
        props: {},
        width: "600px"
    },
    title: { defaultMessage: "Timer's settings", id: "account.modal.timers.settings.title" }
});
export const requestDefaultCookiesFormModal = ({ element }) => ({
    component: {
        element,
        height: "90%",
        props: {},
        width: "90%"
    },
    title: { defaultMessage: "Default cookies", id: "account.modal.default.cookies.title" }
});
export const requestDefaultHeadersFormModal = ({ element }) => ({
    component: {
        element,
        height: "80%",
        props: {},
        width: "800px"
    },
    title: { defaultMessage: "Default headers", id: "account.modal.default.headers.title" }
});
export const requestDefaultTimerFormModal = ({ element }) => ({
    component: {
        element,
        height: "570px",
        props: {},
        width: "600px"
    },
    title: { defaultMessage: "Default timer's settings", id: "account.modal.default.timer.settings.title" }
});
export const httpRequestDefaultsFormModal = ({ element }) => ({
    component: {
        element,
        height: "520px",
        props: {},
        width: "600px"
    },
    title: { defaultMessage: "Request default settings", id: "account.modal.request.default.settings.title" }
});
export const replaceSecretTokenModal = ({ element, confirmAction }) => ({
    component: {
        element,
        props: {
            confirmButton: {
                action: confirmAction,
                theme: { width: "44%!important" },
                title: {
                    defaultMessage: "Yes, replace API token",
                    id: "account.modal.replace.secret.token.button.confirm.title"
                }
            },
            cancelButton: {
                theme: { width: "44%!important" },
                title: {
                    defaultMessage: "No, keep existing value",
                    id: "account.modal.replace.secret.token.button.cancel.title"
                }
            },
            text: {
                description: [
                    {
                        defaultMessage: "Are you sure you want to continue?",
                        id: "account.modal.replace.secret.token.text1"
                    },
                    {
                        defaultMessage: "Replacing the token will revoke the existing one immediately.",
                        id: "account.modal.replace.secret.token.text2"
                    }
                ]
            }
        },
        width: "820px"
    },
    title: {
        defaultMessage: "Confirmation",
        id: "account.modal.replace.secret.token.title"
    }
});
export const settingsSetLinkingJmxFormModal = ({ element }) => ({
    component: {
        element,
        width: "550px"
    },
    title: {
        defaultMessage: "Attention",
        id: "account.settings.set.sidebar.JMX.attention.modal.title"
    }
});
export const slaFormModal = ({ element, mode, onSave, testType }) => ({
    component: {
        element,
        height: "80%",
        props: { mode, onSave, testType },
        theme: { paddingLeft: "75px", paddingRight: "75px" },
        width: "100%"
    },
    title: {
        defaultMessage: "SLA",
        id: "account.sla.form.title"
    }
});
export const unsavedTestProjectAttentionModal = ({ cancelAction, confirmAction, element }) => ({
    component: {
        element,
        props: {
            confirmButton: { action: confirmAction, theme: { width: "40%!important" } },
            cancelButton: { action: cancelAction, theme: { width: "40%!important" } },
            text: {
                description: [
                    {
                        defaultMessage: "You have unsaved changes in the test. If you logout now, all the unsaved changes will be discarded. Do you want to continue?",
                        id: "account.modal.logout.unsaved.test.project.attention.text"
                    }
                ]
            }
        },
        width: "830px"
    },
    title: { id: "account.modal.delete.request.group.title", defaultMessage: "Attention" }
});
export const webhookAdditionalSettingsModal = ({ element, headers, id, url }) => ({
    component: {
        element,
        height: "80%",
        props: { headers, id, url },
        width: "800px"
    },
    title: {
        defaultMessage: "Webhook",
        id: "account.test.project.settings.set.webhook.settings.modal.title"
    }
});
