import { put, select, takeEvery } from "redux-saga/effects";
import { deepCompare } from "@boomq/utils";
import { addElement, setElement, showImportDataToThreadGroupForm } from "../actions/editor";
import newTestActions from "../actions/newTest";
import { closeSiteModal, getSiteModal } from "../actions/siteModal";
import { getEditorGroupId, getEditorTreeData, getElements } from "../reducers/editor";
import { getMaxTransactionIndex, getThreadGroupElementsByGroupId, getThreadGroupTreeById } from "../reducers/newTest";
import { ConfirmForm } from "../../components/common/ConfirmForm";
import { importDataFromFileToThreadGroupModalForm } from "../../helpers/importData";
import { getThreadGroupElementControllerType } from "../../helpers/threadGroup";
import { formatThreadGroupElementParamsByType } from "../../helpers/threadGroupEditor";
import { ProfileSettingsMode } from "../../models/testProject";
import { dataLossConfirmForm, ControllerType } from "../../models/threadGroupEditor";
const { testProjectChangeParamsData, testProjectChangeParamsField } = newTestActions;
function* editorElementAddingFlow({ payload }) {
    try {
        const groupId = yield select(getEditorGroupId);
        const maxTransactionIndex = yield select(getMaxTransactionIndex);
        yield put(setElement(Object.assign(Object.assign({}, formatThreadGroupElementParamsByType(Object.assign(Object.assign({}, payload), { index: maxTransactionIndex + 1 }))), { groupId })));
        return getThreadGroupElementControllerType(payload) === ControllerType.Transaction
            ? yield put(testProjectChangeParamsData({
                maxTransactionIndex: maxTransactionIndex + 1
            }))
            : undefined;
    }
    catch (e) {
        return;
    }
}
function* isThreadGroupEditorTreeDataChanging(editorGroupId) {
    try {
        const editorTreeData = yield select(getEditorTreeData);
        const threadGroupTreeData = yield select(getThreadGroupTreeById, editorGroupId);
        return !deepCompare(editorTreeData, threadGroupTreeData);
    }
    catch (e) {
        return false;
    }
}
function* isThreadGroupEditorElementsChanging(editorGroupId) {
    try {
        const editorTreeElements = yield select(getElements);
        const threadGroupElements = yield select(getThreadGroupElementsByGroupId, editorGroupId);
        return !deepCompare(editorTreeElements, threadGroupElements);
    }
    catch (e) {
        return false;
    }
}
function* checkThreadGroupEditorDataChanging() {
    try {
        const editorGroupId = yield select(getEditorGroupId);
        const isEditorTreeDataChanging = yield* isThreadGroupEditorTreeDataChanging(editorGroupId);
        return isEditorTreeDataChanging ? true : yield* isThreadGroupEditorElementsChanging(editorGroupId);
    }
    catch (e) {
        return false;
    }
}
export function* cancelEditThreadGroupTreeDataFlow(dispatch) {
    try {
        const isThreadGroupEditorDataChanging = yield* checkThreadGroupEditorDataChanging();
        return isThreadGroupEditorDataChanging
            ? yield put(getSiteModal(dataLossConfirmForm({
                element: ConfirmForm,
                cancelAction: () => dispatch(closeSiteModal()),
                confirmAction: () => dispatch(testProjectChangeParamsField({
                    field: "profileSettingsMode",
                    value: ProfileSettingsMode.ThreadGroups
                }))
            })))
            : yield put(testProjectChangeParamsField({
                field: "profileSettingsMode",
                value: ProfileSettingsMode.ThreadGroups
            }));
    }
    catch (e) {
        console.error(e);
    }
}
function* showImportDataToThreadGroupFormFlow({ payload }) {
    try {
        const threadGroupEditorGroupId = yield select(getEditorGroupId);
        yield put(getSiteModal(importDataFromFileToThreadGroupModalForm({
            groupId: threadGroupEditorGroupId,
            sourceType: payload.value
        })));
    }
    catch (e) {
        return;
    }
}
export function* threadGroupEditorFlow() {
    yield takeEvery(addElement, editorElementAddingFlow);
    yield takeEvery(showImportDataToThreadGroupForm, showImportDataToThreadGroupFormFlow);
}
