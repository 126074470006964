import { IconGrpc } from "@boomq/uikit";
import { DEFAULT_PROJECT_NAME_PREFIX } from "./constants";
import { ProfileDistribution, ProfileSettingsMode, TestProjectDataSourceType } from "./enums";
import { loadTypesMessages } from "../../data/content/testTypeMessages";
import { getMinutes, getTimeZoneHours } from "../../helpers/date";
import { TestType } from "../../helpers/types";
import { SlaTargetType } from "../../models/sla/enums";
export const testProjectSidebarElementIconByType = {
    [TestProjectDataSourceType.GrpcProtoBuffer]: IconGrpc
};
export const initSettings = {
    rampDown: 1,
    rampUp: 1,
    stepCount: 1,
    stepLength: 10,
    usersPerStep: 10
};
export const initScalabilitySettings = {
    durationAddedOnLastStep: 15,
    rampDown: 5,
    rampUp: 5,
    stepCount: 2,
    stepLength: 15
};
export const initUsersDistributionSettings = {
    durationAddedOnLastStep: 5,
    rampDown: 0,
    rampUp: 0,
    stepCount: 2,
    stepLength: 5,
    usersPerStep: 5
};
export const generateDefaultTestProjectName = () => `${DEFAULT_PROJECT_NAME_PREFIX}_${getTimeZoneHours()}:${getMinutes()}`;
export const initialTestProjectSettings = {
    autoPercentages: true,
    chartLocation: null,
    contentModelVersion: "",
    currentVersion: "",
    defaultCookies: {
        clearEachIteration: false,
        id: "",
        items: [],
        label: ""
    },
    defaultHeaders: [{ id: "User-Agent", key: "User-Agent", value: "Mozilla/5.0" }],
    defaultTimer: {},
    files: [],
    httpRequestDefaults: {},
    isModified: false,
    isSaved: false,
    isSavedAs: false,
    JMXProfiles: {},
    JMXTestDurationSec: 0,
    JMXTestVUsers: 0,
    JMXTestVUserMins: 0,
    JMXTimersSettings: {
        isTimersDisabled: false,
        multiplicationFactor: 1
    },
    labelSet: [],
    loadProfileSettingsErrorIntlMessage: null,
    loadType: loadTypesMessages[0],
    maxTransactionIndex: 0,
    previewJMXChart: {
        isMergedJMXChartSteps: true,
        isMergedJMXChartGroups: false,
        timeLine: {
            min: 0,
            max: 0,
            value: [0, 0]
        }
    },
    previewThreadGroupsChart: {
        isMergedChartGroups: false,
        timeLine: {
            min: 0,
            max: 0,
            value: [0, 0]
        }
    },
    SCALABILITY: Object.assign(Object.assign({}, initSettings), initScalabilitySettings),
    STABLE: Object.assign({}, initSettings),
    chartData: [{ coords: [] }],
    groups: [],
    grpcBuffer: [],
    isDebugMode: false,
    isLabelsFromProjectCopying: false,
    profileDistribution: ProfileDistribution.Percent,
    elementsData: {},
    profileSettingsMode: ProfileSettingsMode.ThreadGroups,
    projectId: null,
    projectLabels: [],
    projectName: generateDefaultTestProjectName(),
    requests: [],
    requestParameters: {
        literalParameters: [],
        csvParameters: [],
        others: []
    },
    runnerLocations: [],
    runnerResources: [],
    savedProjectId: null,
    serializedData: "",
    sla: {
        [SlaTargetType.Test]: [],
        [SlaTargetType.ThreadGroup]: [],
        [SlaTargetType.Transaction]: [],
        [SlaTargetType.Request]: [],
        [SlaTargetType.SystemMetricsHost]: []
    },
    sourceJmxThreadGroups: {},
    step: 1,
    testComment: "",
    testLabels: [],
    testType: TestType.Stable,
    threadGroupsTree: {},
    timers: {},
    transactions: [],
    versions: []
};
export const requestGroupsActions = [
    {
        actionName: "toggleActiveGroups",
        actionPayload: { enabled: true },
        id: 0,
        text: {
            id: "account.test.profile.button.enable.all.groups",
            defaultMessage: "Enable all groups"
        }
    },
    {
        actionName: "toggleActiveGroups",
        actionPayload: { enabled: false },
        id: 1,
        text: {
            id: "account.test.profile.button.disable.all.groups",
            defaultMessage: "Disable all groups"
        }
    },
    {
        actionName: "toggleGroups",
        actionPayload: { isOpened: false },
        id: 2,
        testTypes: [TestType.JMX],
        text: {
            defaultMessage: "Collapse all groups",
            id: "account.test.profile.jmx.button.collapse.all.groups"
        }
    },
    {
        actionName: "toggleGroups",
        actionPayload: { isOpened: true },
        id: 3,
        testTypes: [TestType.JMX],
        text: {
            defaultMessage: "Expand all groups",
            id: "account.test.profile.jmx.button.expand.all.groups"
        }
    },
    {
        actionName: "deleteGroups",
        id: 4,
        testTypes: [TestType.JMX],
        text: {
            defaultMessage: "Delete all groups",
            id: "account.test.profile.jmx.button.delete.all.groups"
        }
    },
    {
        actionName: "deleteGroups",
        id: 7,
        testTypes: [TestType.Scalability, TestType.Stable],
        text: {
            defaultMessage: "Delete all thread groups",
            id: "account.test.profile.button.delete.all.groups"
        }
    },
    {
        actionName: "showTestProjectSettingsSetSidebar",
        id: 9,
        text: {
            defaultMessage: "Link settings set",
            id: "account.test.profile.button.edit.link.settings.set"
        }
    }
];
