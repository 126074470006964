export const CONTRAST_COLORS_PALETTE = [
    "#1d1d1d",
    "#ebce2b",
    "#702c8c",
    "#db6917",
    "#96cde6",
    "#ba1c30",
    "#c0bd7f",
    "#7f7e80",
    "#5fa641",
    "#d485b2",
    "#4277b6",
    "#df8461",
    "#463397",
    "#e1a11a",
    "#91218c",
    "#e8e948",
    "#7e1510",
    "#92ae31",
    "#6f340d",
    "#d32b1e",
    "#2b3514"
];
