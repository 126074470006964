import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import { excludeArrayFromArray, getMaxInArray, isEmpty } from "@boomq/utils";
import { showLoader } from "../actions/loader";
import newTestActions from "../actions/newTest";
import { getSiteModal } from "../actions/siteModal";
import { changeTestProjectVersion, changeTestProjectVersionName, copyTestProjectRequest, copyTestProjectSuccess, copyTestProjectFailure, deleteTestProjectRequest, deleteTestProjectSuccess, deleteTestProjectFailure, editTestProject, saveAsTestProjectFailure, saveAsTestProjectRequest, saveAsTestProjectSuccess, searchTestProjectsLabelsByName, setFilterLabels, setLabelList, setTestProjectVersionName, setTestProjectsSearchedLabels, testProjectDetailsRequest, testProjectDetailsSuccess, testProjectDetailsFailure, testProjectRequest, testProjectSuccess, testProjectFailure, testProjectsChangeFilterLabelsRequest, testProjectsRequest, testProjectsSuccess, testProjectsFailure } from "../actions/testProjects";
import { getConfigDefaultTimer } from "../reducers/config";
import { getIsModified, getProjectId, getProjectVersion, getProjectName, getTestProjectSettingsField } from "../reducers/newTest";
import { defaultTestProjectsPageSize, getCurrentPage, getTestProjectsFilterLabels, getTestProjectsLabelList } from "../reducers/testProjects";
import { selectProfileFlow, serializeTestProjectData } from "../sagas/newTest";
import { getTestProjectFromListById } from "../sagas/testProject";
import ChangeTestProjectVersionConfirmForm from "../../components/account/TestProject/ChangeTestProjectVersionConfirmForm";
import { getTestProjectYamlFile } from "../../helpers/api";
import { getResponseErrorData } from "../../helpers/errors";
import { prepareLabels } from "../../helpers/labels";
import { calculateLoadProfileParamsByTestType } from "../../helpers/loadProfile";
import { parseJMXTestProjectFromYaml, parseTestProjectComposedByUserFromYaml } from "../../helpers/parsers";
import { TestType } from "../../helpers/types";
import { copyTestProject, deleteTestProject, getTestProjectDetails, getTestProjects, getTestProjectsLabelsByQuery, getTestProjectVersion, getTestProjectVersionDownloadLink, updateTestProjectVersionComment } from "../../services/projectSrv";
const { testProjectChangeParamsField } = newTestActions;
function* changeTestProjectVersionFlow(action) {
    try {
        const { isAccepted, value } = action && action.payload ? action.payload : {};
        const [isModified, testProjectId, projectName, comment, versions] = yield all([
            select(getIsModified),
            select(getProjectId),
            select(getProjectName),
            select(getTestProjectSettingsField, "comment"),
            select(getTestProjectSettingsField, "versions")
        ]);
        (isAccepted || !isModified) && (yield put(showLoader()));
        const preparedTestProjectVersionData = (isAccepted || !isModified) && testProjectId && value
            ? yield* getTestProjectVersionData({ id: testProjectId, version: value })
            : {};
        value &&
            !isAccepted &&
            isModified &&
            (yield put(getSiteModal({
                component: {
                    element: ChangeTestProjectVersionConfirmForm,
                    props: { value }
                },
                title: {
                    defaultMessage: "Confirm test's version changing",
                    id: "account.modal.new.test.project.version.change.confirm.title"
                }
            })));
        (isAccepted || !isModified) &&
            (yield put(testProjectDetailsSuccess(Object.assign(Object.assign({}, preparedTestProjectVersionData), { comment,
                projectName,
                versions, step: 1 }))));
        const serializedData = yield* serializeTestProjectData();
        yield put(testProjectChangeParamsField({ field: "serializedData", value: serializedData }));
    }
    catch (e) {
        yield put(testProjectDetailsFailure(getResponseErrorData(e)));
    }
}
function* changeTestProjectVersionNameFlow({ payload: { id, value } }) {
    try {
        yield call(updateTestProjectVersionComment, { version: id, comment: value });
        yield put(setTestProjectVersionName({ id, value }));
    }
    catch (e) {
        console.error(e);
    }
}
function* copyTestProjectFlow({ payload: { id } }) {
    try {
        const currentPage = yield select(getCurrentPage);
        const testProjectDetailsResponse = id ? yield call(getTestProjectDetails, id) : null;
        const { projectName, version } = testProjectDetailsResponse && testProjectDetailsResponse.data ? testProjectDetailsResponse.data : {};
        const lastVersionId = getMaxInArray(version, "id");
        yield call(copyTestProject, {
            projectId: Number(id),
            projectVersionId: Number(lastVersionId),
            newProjectName: `copy ${projectName}`
        });
        yield put(copyTestProjectSuccess());
        yield put(testProjectsRequest({ page: currentPage }));
    }
    catch (e) {
        yield put(copyTestProjectFailure(getResponseErrorData(e)));
    }
}
function* deleteTestProjectFlow({ payload: { id } }) {
    try {
        const currentPage = yield select(getCurrentPage);
        yield call(deleteTestProject, id);
        yield put(deleteTestProjectSuccess());
        yield put(testProjectsRequest({ page: currentPage }));
    }
    catch (e) {
        yield put(deleteTestProjectFailure(getResponseErrorData(e)));
    }
}
export function* editTestProjectFlow({ payload }) {
    try {
        const { id, step } = payload || {};
        return id
            ? yield put(push({
                pathname: `/account/tests/${id}`,
                state: { step }
            }))
            : undefined;
    }
    catch (e) {
        yield put(testProjectFailure(getResponseErrorData(e)));
    }
}
export function* downloadTestProjectVersionData(params) {
    const { id, testType, version } = params || {};
    const downloadLinkResponse = version ? yield call(getTestProjectVersionDownloadLink, { id, version }) : null;
    const { downloadLink } = downloadLinkResponse && downloadLinkResponse.data ? downloadLinkResponse.data : {};
    const yamlFileResponse = downloadLink ? yield call(getTestProjectYamlFile, downloadLink) : {};
    const data = { yaml: yamlFileResponse ? yamlFileResponse.data : "" };
    const configDefaultTimer = yield select(getConfigDefaultTimer);
    return testType === TestType.JMX
        ? parseJMXTestProjectFromYaml(data)
        : parseTestProjectComposedByUserFromYaml(Object.assign(Object.assign({}, data), { configDefaultTimer }));
}
export function* getTestProjectVersionDataByParams(params) {
    const { comment, contentModelVersion, id, settingsId, testType, totalDuration, version } = params ? params : {};
    const projectVersionData = yield* downloadTestProjectVersionData({ id, testType, version });
    return Object.assign(Object.assign(Object.assign({}, calculateLoadProfileParamsByTestType(testType, projectVersionData.JMXProfiles, totalDuration)), projectVersionData), { comment,
        contentModelVersion, projectId: id, currentVersion: version, settingsId,
        testType,
        totalDuration });
}
export function* getTestProjectVersionData(params) {
    const { id, version } = params ? params : {};
    const testProjectVersionResponse = version ? yield call(getTestProjectVersion, { id, version }) : null;
    const { comment, contentModelVersion, settingsId, testType, totalDuration } = testProjectVersionResponse && testProjectVersionResponse.data ? testProjectVersionResponse.data : null;
    return yield* getTestProjectVersionDataByParams(Object.assign(Object.assign({}, params), { comment,
        contentModelVersion,
        settingsId,
        testType,
        totalDuration }));
}
function* saveAsTestProjectFlow(action) {
    try {
        const { postAction, projectName } = action && action.payload ? action.payload : {};
        const { actionType, payload } = postAction && postAction.success ? postAction.success : {};
        const testProjectId = yield select(getProjectId);
        const projectVersionId = yield select(getProjectVersion);
        const response = yield call(copyTestProject, {
            projectId: Number(testProjectId),
            projectVersionId: Number(projectVersionId),
            newProjectName: projectName
        });
        const { id } = response && response.data ? response.data : {};
        yield put(saveAsTestProjectSuccess({ id }));
        actionType && (yield put({ type: actionType, payload: Object.assign(Object.assign({}, payload), { projectId: id }) }));
    }
    catch (e) {
        yield put(saveAsTestProjectFailure(getResponseErrorData(e)));
    }
}
const prepareVersionName = (comment, versionNumber) => (comment ? comment : `ver.${versionNumber || ""}`);
export const prepareVersionList = (versions) => Array.isArray(versions)
    ? versions.map(({ comment, id, versionNumber }) => ({
        id,
        name: prepareVersionName(comment, versionNumber),
        title: prepareVersionName(comment, versionNumber)
    }))
    : [];
const getProjectVersionIdById = (versions, id) => {
    const version = versions.find((version) => version.id === id);
    return version ? version.id : null;
};
export function* getTestProjectDetailsData(data) {
    const { id, version } = data ? data : {};
    const testProjectDetailsResponse = id ? yield call(getTestProjectDetails, id) : null;
    const { labelSet, projectName, version: projectVersions, settingsId } = testProjectDetailsResponse && testProjectDetailsResponse.data ? testProjectDetailsResponse.data : {};
    const versionId = getProjectVersionIdById(projectVersions, version) || getMaxInArray(projectVersions, "id");
    const preparedTestProjectVersionData = yield* getTestProjectVersionData({ id, version: versionId });
    return {
        labelSet,
        preparedTestProjectVersionData,
        projectName,
        projectVersions,
        settingsId
    };
}
export function* saveTestProjectDetails(params) {
    const { labelSet, preparedTestProjectVersionData, projectName, projectVersions, settingsId, step } = params || {};
    yield put(testProjectDetailsSuccess(Object.assign(Object.assign({}, preparedTestProjectVersionData), { labelSet,
        projectName,
        settingsId, versions: prepareVersionList(projectVersions), step: step ? step : 1 })));
    const serializedData = yield* serializeTestProjectData();
    yield put(testProjectChangeParamsField({ field: "serializedData", value: serializedData }));
}
export function* testProjectDetailsFlow(action) {
    try {
        const { step } = action && action.payload ? action.payload : {};
        const data = yield* getTestProjectDetailsData(action.payload);
        yield* saveTestProjectDetails(Object.assign(Object.assign({}, data), { step }));
        return Number(step) === 2 ? yield* selectProfileFlow() : undefined;
    }
    catch (e) {
        yield put(testProjectDetailsFailure(getResponseErrorData(e)));
    }
}
function* testProjectFlow(action) {
    try {
        const { id } = action && action.payload ? action.payload : {};
        const { data } = id ? yield call(getTestProjectDetails, id) : null;
        const projectName = data ? data.projectName : "";
        const settingsId = data ? data.settingsId : "";
        yield put(testProjectSuccess({ projectName, settingsId }));
    }
    catch (e) {
        yield put(testProjectFailure(getResponseErrorData(e)));
    }
}
function* labelsSearchingFlow(action) {
    const { id, query } = action && action.payload ? action.payload : {};
    const { labelSet = [] } = yield* getTestProjectFromListById(id);
    try {
        const { data } = yield call(getTestProjectsLabelsByQuery, query);
        const searchedLabels = prepareLabels(excludeArrayFromArray(data, labelSet));
        yield put(setTestProjectsSearchedLabels({ id, searchedLabels }));
    }
    catch (e) {
        yield put(setTestProjectsSearchedLabels({ id, searchedLabels: [] }));
    }
}
export function* updateTestProjectsLabels() {
    const { data: labelList } = yield call(getTestProjectsLabelsByQuery, "");
    yield put(setLabelList(prepareLabels(labelList)));
}
export function* updateTestProjectsFilterLabelsByLabelList() {
    const filterLabels = yield select(getTestProjectsFilterLabels);
    const labelList = yield select(getTestProjectsLabelList);
    const labels = labelList.map((labelItem) => labelItem.label);
    const filterLabelsByLabelList = filterLabels.filter((filterLabelItem) => labels.includes(filterLabelItem.label));
    yield put(setFilterLabels(filterLabelsByLabelList));
}
export function* refreshTestProjectsLabels() {
    try {
        const labelList = yield select(getTestProjectsLabelList);
        return isEmpty(labelList) ? yield* updateTestProjectsLabels() : undefined;
    }
    catch (e) {
        console.error(e);
    }
}
export function* refreshTestProjectList(page, filterLabels = []) {
    try {
        const { data } = yield call(getTestProjects, {
            label: filterLabels.map((label) => label.name),
            page,
            size: defaultTestProjectsPageSize
        });
        yield put(testProjectsSuccess(data));
    }
    catch (e) {
        yield put(testProjectsFailure(getResponseErrorData(e)));
    }
}
function* testProjectListFlow(action) {
    const { page } = action && action.payload ? action.payload : {};
    const filterLabels = yield select(getTestProjectsFilterLabels);
    yield* refreshTestProjectsLabels();
    yield* refreshTestProjectList(page, filterLabels);
}
function* testProjectsFilterLabelChangingFlow(action) {
    try {
        const filterLabels = action && action.payload ? action.payload : [];
        yield put(setFilterLabels(filterLabels));
        yield* refreshTestProjectList(0, filterLabels);
    }
    catch (e) {
        console.error(e);
    }
}
export function* testProjectsFlow() {
    yield takeEvery(changeTestProjectVersion, changeTestProjectVersionFlow);
    yield takeEvery(changeTestProjectVersionName, changeTestProjectVersionNameFlow);
    yield takeEvery(copyTestProjectRequest, copyTestProjectFlow);
    yield takeEvery(deleteTestProjectRequest, deleteTestProjectFlow);
    yield takeEvery(editTestProject, editTestProjectFlow);
    yield takeEvery(saveAsTestProjectRequest, saveAsTestProjectFlow);
    yield takeEvery(searchTestProjectsLabelsByName, labelsSearchingFlow);
    yield takeEvery(testProjectDetailsRequest, testProjectDetailsFlow);
    yield takeEvery(testProjectRequest, testProjectFlow);
    yield takeEvery(testProjectsChangeFilterLabelsRequest, testProjectsFilterLabelChangingFlow);
    yield takeEvery(testProjectsRequest, testProjectListFlow);
}
