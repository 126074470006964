import { media } from "@boomq/common";

export const customSlaTheme = {
    marginRight: "25px",
    marginTop: "10px",
    [media.giant]: {
        marginRight: "5px"
    },
    [media.desktop]: {
        margin: "5px 0",
        width: "100%"
    }
};
